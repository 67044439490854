import { CardContentBottomSlotWrapper } from './CardContent.styled';
import { CardContentRootProps } from './CardContent.types';

export function CardContentBottomSlot({
  cardContentLayout,
  layout,
}: CardContentRootProps) {
  return (
    <CardContentBottomSlotWrapper layout={layout}>
      {cardContentLayout.bottomSlotContent}
    </CardContentBottomSlotWrapper>
  );
}
