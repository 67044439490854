import styled from 'styled-components';

export const CarouselContainer = styled.div`
  height: ${({ theme }) => theme.toolkit.spacing.XL64};
  position: relative;
  overflow: hidden;
`;

export const CarouselScroll = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  gap: ${({ theme }) => theme.toolkit.spacing.S4};
  padding: ${({ theme }) => theme.toolkit.spacing.S4};
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
  transform: translateX(0);

  ::-webkit-scrollbar {
    display: none;
  }

  .photo-item.active {
    outline: ${({ theme }) => theme.toolkit.spacing.S4} solid
      ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    z-index: 10;
  }

  &.bounce-left {
    animation: bounceLeft 0.5s;
  }

  @keyframes bounceLeft {
    50% {
      transform: translateX(1.875rem);
    }
    100% {
      transform: translateX(0);
    }
  }

  &.bounce-right {
    animation: bounceRight 0.5s;
  }

  @keyframes bounceRight {
    50% {
      transform: translateX(-1.875rem);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const CarouselBlurLeft = styled.div`
  position: absolute;
  top: 0;
  width: 3.125rem;
  height: ${({ theme }) => theme.toolkit.spacing.XL64};
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  z-index: 1;
  pointer-events: none;
`;

export const CarouselBlurRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 3.125rem;
  height: ${({ theme }) => theme.toolkit.spacing.XL64};
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  z-index: 1;
  pointer-events: none;
`;

export const PhotoItem = styled.div`
  flex: 0 0 auto;
  height: ${({ theme }) => theme.toolkit.spacing.L56};
  width: 5.25rem;
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  cursor: pointer;

  img {
    border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
