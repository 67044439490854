import { ImageGridImagesProps, ImageGridProps } from './ImageGrid.types';
import * as Styled from './ImageGrid.styled';
import Empty from '../../../../assets/empty.svg';
import ImageWrapper from '../imageWrapper/ImageWrapper';

/**
 * The ImageGrid component was designed mainly to be used with the Gallery component.
 * It takes in the GalleryImages and displays them in a grid format.
 * It controls the aspect ratio and dimensions of the images depending on the
 * viewport size and the number of images to be displayed (2 or 3).
 *
 * calculateVisibleImages function is responsible for determining which images should be visible
 * based on the provided parameters.
 * @param firstVisibleThumbnailIndex The index of the first visible thumbnail in the grid.
 * @param imageGridImages An array of image objects representing the grid images.
 * @param skipImageIndex An optional index of an image to be skipped in the calculation. Used so the
 * image shown in the Gallery does not appear in the grid.
 * @param visibleGridImagesCount The number of visible images in the grid.
 * @param forceMobileView A boolean to force the grid to be displayed underneath the Gallery, regardless the viewport
 * @returns An array of image objects representing the visible images in the grid.
 */

export const calculateVisibleImages = ({
  firstVisibleThumbnailIndex,
  imageGridImages,
  skipImageIndex,
  visibleGridImagesCount,
}: {
  firstVisibleThumbnailIndex: number;
  imageGridImages: ImageGridImagesProps[] | [];
  skipImageIndex?: number;
  visibleGridImagesCount: number;
}) => {
  const startIndex = firstVisibleThumbnailIndex % imageGridImages.length;
  const visibleImages = [];
  let isFirstImageAdded = false;

  for (let i = 0; i < imageGridImages.length; i++) {
    const index = (startIndex + i) % imageGridImages.length;
    if (
      (index === startIndex && isFirstImageAdded) ||
      visibleImages.length === visibleGridImagesCount
    )
      break;
    if (index !== skipImageIndex) {
      visibleImages.push(imageGridImages[index]);
      isFirstImageAdded = true;
    }
  }

  const notVisibleImagesCount = visibleGridImagesCount - visibleImages.length;
  if (notVisibleImagesCount > 0) {
    const placeholders = Array(notVisibleImagesCount).fill({
      src: Empty,
      alt: 'No Image',
    });
    visibleImages.push(...placeholders);
  }
  return visibleImages;
};

export const ImageGrid = (props: ImageGridProps) => {
  const {
    firstVisibleThumbnailIndex = 0,
    aspectRatioHorizontal,
    aspectRatioVertical,
    imageGridImages = [],
    imageLoading,
    skipImageIndex,
    visibleGridImagesCount = 0,
    dataTracking,
    forceMobileView,
  } = props;
  const visibleImages = calculateVisibleImages({
    firstVisibleThumbnailIndex,
    imageGridImages,
    skipImageIndex,
    visibleGridImagesCount,
  });

  return (
    <Styled.ImageGridWrapper
      data-testid="image-grid-container"
      {...(dataTracking && {
        'data-tracking': dataTracking,
      })}
    >
      <Styled.ImageGrid
        visibleGridImagesCount={visibleGridImagesCount}
        forceMobileView={forceMobileView}
      >
        {visibleImages.map((image, index) => (
          <Styled.ImageGridImageWrapper
            key={'imageGridImage_' + index}
            aspectRatioHorizontal={aspectRatioHorizontal}
            aspectRatioVertical={aspectRatioVertical}
            visibleGridImagesCount={visibleGridImagesCount}
            forceMobileView={forceMobileView}
          >
            {image?.src && (
              <ImageWrapper
                src={image.src}
                alt={image.alt || 'thumbnail'}
                width={200}
                height={200}
                loading={imageLoading}
              />
            )}
          </Styled.ImageGridImageWrapper>
        ))}
      </Styled.ImageGrid>
    </Styled.ImageGridWrapper>
  );
};

export default ImageGrid;
