import { Button, ButtonSizes, ButtonTypes } from '../../atoms';
import { DaftPaginationProps } from './Pagination.types';
import { StyledBreak, StyledPagination } from './Pagination.styled';

/**
 *  @description
 * Legacy Component moved from Daft to Toolkit and not part of the Design System
 *
 */
export const Pagination = (props: DaftPaginationProps) => {
  return (
    <StyledPagination
      renderBreakView={(props) => <StyledBreak {...props}>...</StyledBreak>}
      renderPageButton={(props) => {
        const { highlight, onClick, text } = props;
        return (
          <Button
            classNames={!highlight ? 'hide' : ''}
            buttonType={highlight ? ButtonTypes.PRIMARY : ButtonTypes.TERTIARY}
            buttonSize={ButtonSizes.X_SMALL}
            {...props}
            onClick={onClick as any}
          >{`${text}`}</Button>
        );
      }}
      renderPrevButton={(props) => (
        <Button
          buttonType={ButtonTypes.TERTIARY}
          buttonSize={ButtonSizes.X_SMALL}
          data-testid="go-to-prev-page"
          {...props}
        >{`< Prev`}</Button>
      )}
      renderNextButton={(props) => (
        <Button
          buttonType={ButtonTypes.TERTIARY}
          buttonSize={ButtonSizes.X_SMALL}
          data-testid="go-to-next-page"
          {...props}
        >
          {`Next >`}
        </Button>
      )}
      {...props}
    />
  );
};

export default Pagination;
