export enum Spacing {
  HUG = 'HUG',
  SPACED = 'SPACED',
}
export type TInfoItem = {
  children: JSX.Element | JSX.Element[];
  spacing?: Spacing;
};

export type TInfoItemStyle = {
  spacing?: Spacing;
};
