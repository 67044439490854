export enum MaskedContainerSize {
  S32 = 32,
  S40 = 40,
  M48 = 48,
  M56 = 56,
  M64 = 64,
  L72 = 72,
  L80 = 80,
  L112 = 112,
}

export enum MaskedContainerShape {
  CIRCLE = 'CIRCLE',
  SQUARE = 'SQUARE',
}

export type MaskedContainerCommonProps = {
  mobileSize?: MaskedContainerSize | undefined;
  outline?: boolean;
  shape?: MaskedContainerShape;
  size?: MaskedContainerSize;
  dataTracking?: string;
};

export type MaskedContainerProps = MaskedContainerCommonProps & {
  image: string;
  imageAlt: string;
};

export type MaskedContainerWrapperProps = MaskedContainerCommonProps;
