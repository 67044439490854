import styled, { css } from 'styled-components';
import {
  ImageGridAspectRatio,
  VisibleThumbnailsCount,
} from './ImageGrid.types';
import { GalleryAspectRatio } from '../../molecules';

export const ImageGridWrapper = styled.div`
  display: block;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  padding-left: 0;
  width: 100%;
`;

export const ImageGrid = styled.div<{
  visibleGridImagesCount: VisibleThumbnailsCount;
  forceMobileView?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ visibleGridImagesCount }) =>
    visibleGridImagesCount
      ? `repeat(${visibleGridImagesCount}, 1fr)`
      : `repeat(2, 1fr)`};
  max-height: 100%;

  ${({ visibleGridImagesCount, forceMobileView, theme }) => {
    const isTwoImagesOrForceMobile =
      visibleGridImagesCount === 2 || forceMobileView;
    const gridTemplateColumns =
      visibleGridImagesCount === 3 && !forceMobileView
        ? 'none'
        : `repeat(${visibleGridImagesCount}, 1fr)`;
    const gridTemplateRows =
      visibleGridImagesCount === 2 ||
      (visibleGridImagesCount === 3 && forceMobileView)
        ? 'none'
        : `repeat(3, 1fr)`;

    return css`
      > div:first-child {
        padding-right: 1px;
      }

      > div:not(:first-child):not(:last-child) {
        padding: 0 1px;
      }

      > div:last-child {
        padding-left: 1px;
      }

      @media only screen and (${theme.toolkit.mediaQuery.medium}) {
        > div:first-child {
          padding-bottom: ${isTwoImagesOrForceMobile ? '0px' : '1px'};
          padding-right: ${isTwoImagesOrForceMobile ? '1px' : '0px'};
        }

        > div:not(:first-child):not(:last-child) {
          padding: ${forceMobileView ? '0px 1px' : '1px 0px'};
        }

        > div:last-child {
          padding-left: ${isTwoImagesOrForceMobile ? '1px' : '0px'};
          padding-top: ${isTwoImagesOrForceMobile ? '0px' : '1px'};
        }

        display: grid;
        grid-template-columns: ${gridTemplateColumns};
        grid-template-rows: ${gridTemplateRows};
      }
    `;
  }}
`;

/**
 * If on mobile media query:
 * - If aspectRatioHorizontal is provided, use it
 *   Otherwise decide on the aspect-ratio based on how many images are visible
 *
 * if on desktop:
 * - If when we have two images we show horizontal, so use aspectRatioHorizontal or the default aspect-ratio for horizontal
 * - If when we have three images and !forceMobileView we show vertical, so use aspectRatioVertical or the default aspect-ratio for vertical. Note in this case the aspect ratio must match the Gallery aspect ratio to fit correctly
 * - If when we have three images and forceMobileView we show horizontal, so use aspect-ratio ImageGridAspectRatio.RATIO_4_3;
 */
export const ImageGridImageWrapper = styled.div<{
  aspectRatioHorizontal?: ImageGridAspectRatio;
  aspectRatioVertical?: GalleryAspectRatio;
  forceMobileView?: boolean;
  visibleGridImagesCount: VisibleThumbnailsCount;
}>`
  aspect-ratio: ${(props) => {
    if (props.aspectRatioHorizontal) {
      return props.aspectRatioHorizontal;
    }
    switch (props.visibleGridImagesCount) {
      case 2:
        return ImageGridAspectRatio.RATIO_2_1;
      case 3:
      default:
        return ImageGridAspectRatio.RATIO_4_3;
    }
  }};
  max-height: 100%;
  overflow: hidden;
  position: relative;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    aspect-ratio: ${({
      visibleGridImagesCount,
      aspectRatioHorizontal,
      aspectRatioVertical = ImageGridAspectRatio.RATIO_3_2,
      forceMobileView,
    }) => {
      if (visibleGridImagesCount === 2) {
        return aspectRatioHorizontal || ImageGridAspectRatio.RATIO_2_1;
      }
      if (visibleGridImagesCount === 3) {
        return forceMobileView
          ? ImageGridAspectRatio.RATIO_4_3
          : aspectRatioVertical;
      }
      return aspectRatioVertical || ImageGridAspectRatio.RATIO_3_2;
    }};
  }

  img {
    display: block;
    height: 100%;
    margin: 0;
    object-fit: cover;
    padding: 0;
    width: 100%;
  }
`;
