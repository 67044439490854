import { forwardRef } from 'react';
import * as style from './Stepper.styled';
import { StepperProps } from './Stepper.types';
import * as Atom from '../../atoms';

export const Stepper = forwardRef<HTMLInputElement, StepperProps>(
  (props: StepperProps, ref) => {
    const {
      name,
      displayName,
      min = 0,
      max = 10,
      onDecrement,
      onIncrement,
      currentValue,
      hasError = false,
      ...rest
    } = props;

    return (
      <style.StyledWrapper data-testid={name}>
        <style.StyledP> {displayName}</style.StyledP>
        <style.StyledDiv>
          <Atom.RoundButton
            type="button"
            data-testid={`${name}-decrement-button`}
            aria-label={`${name}-decrement`}
            iconType={'MINUS'}
            buttonType={Atom.RoundButtonTypes.SECONDARY}
            buttonSize={Atom.RoundButtonSizes.X_SMALL}
            isDisabled={min >= currentValue}
            hasError={hasError}
            onClick={onDecrement}
            {...rest}
          />
          <style.StyledSpan data-testid={`${name}-current-value`} ref={ref}>
            {currentValue}
          </style.StyledSpan>
          <Atom.RoundButton
            type="button"
            data-testid={`${name}-increment-button`}
            aria-label={`${name}-increment`}
            iconType={'PLUS'}
            buttonType={Atom.RoundButtonTypes.SECONDARY}
            buttonSize={Atom.RoundButtonSizes.X_SMALL}
            isDisabled={currentValue >= max}
            hasError={hasError}
            onClick={onIncrement}
            {...rest}
          />
        </style.StyledDiv>
      </style.StyledWrapper>
    );
  }
);
