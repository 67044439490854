import * as Styled from './RadioButtonCard.styled';

// The colorful bar at the middle of the card showing the ad views level based on the chosen ad plan
const AdViewBar = ({ adViewBar }: { adViewBar: (string[] | string)[] }) => {
  return (
    <Styled.AdViewBarContainer>
      {adViewBar.map((bar, index) => (
        <Styled.AdViewBar key={index} color={bar as string} />
      ))}
    </Styled.AdViewBarContainer>
  );
};

export default AdViewBar;
