import styled from 'styled-components';

export const Footer = styled.footer`
  background: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
  padding: ${({ theme }) => theme.toolkit.spacing.M16} 0;
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    padding: ${({ theme }) => theme.toolkit.spacing.L40} 0;
  }
`;

export const InnerWrapper = styled.div`
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    max-width: 90rem;
    margin: 0 auto;
  }
`;

export const ListWrapper = styled.ul`
  // >=640
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    column-count: 1;
    display: flex;
  }

  // [440 - 640)
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.small}) and (${({ theme }) =>
      theme.toolkit.mediaQuery.smallOnly}) {
    column-count: 3;
  }

  // < 440
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.xsmallOnly}) {
    column-count: 2;
  }

  li {
    padding-bottom: ${({ theme }) => theme.toolkit.spacing.S8};
    @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
      padding-bottom: 0;
      margin-right: ${({ theme }) => theme.toolkit.spacing.M32};
    }
  }
`;

export const FooterBottom = styled.section`
  display: flex;
  padding-top: ${({ theme }) => theme.toolkit.spacing.S8};
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.toolkit.spacing.S8};
  }
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    padding-top: ${({ theme }) => theme.toolkit.spacing.M20};
  }
`;

export const Copyright = styled.span`
  ${({ theme }) => theme.toolkit.typography.fontSizes.S10};
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
`;
