// third-party library imports
import { useState, useEffect, forwardRef } from 'react';

// relative imports: styles and types
import { ModalWrapper, ModalContent } from './Modal.styled';
import * as types from './Modal.types';

// relative imports: sub-components
import ModalHeader from './subComponents/ModalHeader';
import ModalBody from './subComponents/ModalBody';
import ModalFooter from './subComponents/ModalFooter';

/**
* Represents a Modal component that provides a comprehensive set of features to manage modal dialogs effectively in a React application. The Modal includes support for an overlay, dynamic visibility, custom sizing, and event handling for various user interactions, as well as managing modal visibility based on the `show` prop.
 * 
 * Additionally, the component selectively manages closing actions based on the modal size and the location of mouse clicks, such as closing the Modal when clicking on the backdrop but preventing closure when clicking inside the Modal, except for large-sized Modals where clicking the backdrop does not close the Modal.
 * 
 * ## Header and Footer
 * 
 * The Header and Footer of the Modal are provided as optional sub components and can be customized based on the use case. 
 * 
 * The Header takes in an optional `hasClose` prop to determine if the Close button should be displayed, and the footer can contain any action buttons or additional content. If the `hasClose` prop is provided, you must also pass a `handleCloseClick` function to handle the close button click event.
 * 
 * The Footer component is `sticky` to ensure that it remains at the bottom of the Modal, even when the content is scrollable. The Footer can contain any action buttons or additional content relevant to the Modal's purpose. If the Modal passes a prop `isLargeModalWithFooter`, the Footer, when used on a large modal, will have all its padding set to zero except for the top padding. 
 * 
 * ## Modal Body
 * 
 * The modal body is the main content area of the Modal and can contain any content that needs to be displayed to the user. It can include text, images, forms, or other relevant elements to the Modal's purpose.

If a Modal has a Header or Footer, adjust the `hasHeader` and `hasFooter` props accordingly. These props are needed to switch off the padding on the top and bottom of the Modal Body when there is no Header or Footer. This is to avoid unnecessary spacing when the Modal does not have a Header or Footer.
 * 
 * ###Example Use of the Small Modal
 *
 * ```jsx
 * import { Modal, ModalBody, ModalFooter, ModalHeader } from 'atoms';
 * 
 * <Modal
 *  onClose={handleCloseModal}
 *  overlay={props.overlay}
 *  show={showModal}
 *  size={props.size}
 *  testId="test"
 *>
 * <ModalHeader
 *  hasCloseButton={true}
 *  handleCloseClick={handleCloseModal}
 *  size={props.size}
 * >
 *  <Text as="p" size={TextSize.L18} fontWeight={TextFontWeight.SEMIBOLD}>
 *    Delete this photo?
 *  </Text>
 * </ModalHeader>
 * <ModalBody
 *   hasHeader={false}
 *   hasFooter={false}
 *   size={props.size}
 * >
 *  <p>Are you sure you want to proceed?</p>
 * </ModalBody>
 * <ModalFooter size={props.size}>
 *  <Text as="p" size={TextSize.S10} fontWeight={TextFontWeight.SEMIBOLD}>
 *    Footer Example
 *   </Text>
 * </ModalFooter>
 *</Modal>
 * ```
 * 
 * @param {types.IModal} props - The properties passed to the Modal component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the modal.
 * @param {boolean} props.isLargeModalWithFooter - Controls the padding on the footer when used on a large modal.
 * @param {Function} props.onClose - Callback function to be called when the modal needs to be closed.
 * @param {boolean} props.overlay - Determines whether a backdrop is displayed behind the modal.
 * @param {boolean} props.show - Controls the visibility of the modal.
 * @param {types.Size} props.size - The size of the modal, which may affect its styling or layout.
 * @param {string} props.testId - An identifier used for testing purposes.
 * @param {React.Ref<HTMLDivElement>} ref - Ref object that can be used to refer to the modal HTML element directly.
 * @returns {JSX.Element | null} A ModalWrapper containing ModalContent or null if not visible.
 *
 */

const Modal = forwardRef<HTMLDivElement, types.IModal>(
  (props: types.IModal, ref) => {
    const { children, isLargeModalWithFooter, onClose, overlay, show, size, testId } = props; // [TO DO]: add accessibility, trap the focus inside the modal when modal opens and return focus to the element that triggered the modal when modal closes

    const [showModal, setShowModal] = useState(show);
    useEffect(() => {
      setShowModal(show);
      typeof window !== 'undefined' && window && show
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'unset');
    }, [show]); // will close modal if user clicks on backdrop

    const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
      if (size !== types.Size.LARGE) {
        // do not close large modal when clicking on backdrop
        if (event.target === event.currentTarget) {
          onClose();
        }
      }
    }; // will stop modal closing when user clicks on the modal content

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    };

    return showModal ? (
      <ModalWrapper overlay={overlay} size={size} onClick={handleBackdropClick} isLargeModalWithFooter={isLargeModalWithFooter}>
        <ModalContent
          data-testid={testId}
          ref={ref}
          size={size}
          onClick={handleModalClick}
        >
          {children}
        </ModalContent>
      </ModalWrapper>
    ) : null;
  }
);

export { Modal, ModalBody, ModalFooter, ModalHeader };
