import * as Types from './StickyButton.types';
import * as Styled from './StickyButton.styled';

export const StickyButton = ({ children, fill }: Types.TStickyButton) => {
  return (
    <Styled.Overlay>
      <Styled.ButtonContainer fill={fill}>{children}</Styled.ButtonContainer>
    </Styled.Overlay>
  );
};
