import styled, { DefaultTheme } from 'styled-components';
import { TPanelStyled } from './StickeyPanel.types';

const getBg = (theme: DefaultTheme, bgcolor: string | undefined) => {
  switch (bgcolor) {
    case 'blue':
      return `background: ${theme.toolkit.brand.primary.LIGHTEST};`;
    case 'grey':
      return `background: ${theme.toolkit.brand.grey?.LIGHTEST};`;
    case 'green':
      return `background: ${theme.toolkit.brand.success.LIGHTEST};`;
    case 'white':
      return `background: white;`;
    default:
      return;
  }
};

export const PanelWrapper = styled.div<TPanelStyled>`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 5;
  padding: ${({ theme }) => theme.toolkit.spacing.M16};
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  ${({ bgcolor, theme }) => getBg(theme, bgcolor)};
  ${({ stickyPosition }) =>
    stickyPosition === 'bottom' ? 'bottom: 0;' : 'top: 0;'};
  ${({ shadow, stickyPosition }) =>
    shadow &&
    (stickyPosition === 'bottom'
      ? 'box-shadow: 0px -2px 8px 0px #33333314;'
      : 'box-shadow: 0px 2px 8px 0px #33333314;')};
`;
