import * as Styled from './Label.styled';
import { TLabel, LabelSize, LabelWeight } from './Label.types';

export const Label = ({
  required = false,
  children,
  secondaryText,
  weight = LabelWeight.SEMIBOLD,
  size = LabelSize.S14,
  vertical = false,
  labelColor = '',
  secondaryColor = '',
  ...rest
}: TLabel) => {
  return (
    <Styled.Label {...rest} weight={weight} size={size} vertical={vertical}>
      <Styled.LabelText weight={weight} labelColor={labelColor}>
        {children}
      </Styled.LabelText>
      {secondaryText && (
        <Styled.SecondaryText
          vertical={vertical}
          size={size}
          secondaryColor={secondaryColor}
        >
          {secondaryText}
        </Styled.SecondaryText>
      )}
      {required && (
        <Styled.RequiredIndicator aria-hidden="true">
          *
        </Styled.RequiredIndicator>
      )}
    </Styled.Label>
  );
};

export default Label;
