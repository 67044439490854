export enum CardContainerBorder {
  ON = 'ON',
  OFF = 'OFF',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export enum CardContainerCornerRadius {
  ON = 'ON',
  OFF = 'OFF',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export type CardContainerProps = {
  backgroundColor?: string;
  border?: CardContainerBorder;
  borderMobile?: CardContainerBorder;
  children?: React.ReactNode;
  cornerRadius?: CardContainerCornerRadius;
  cornerRadiusMobile?: CardContainerCornerRadius;
  hoverBackgroundColor?: string;
  mobileFullWidth?: boolean;
  onClick?: () => void;
  padding?: boolean;
};
