import styled from 'styled-components';
import { BrandBannerProps } from './BrandBanner.types';

export const Wrapper = styled.div<BrandBannerProps>`
  display: grid;
  grid-template-columns: calc(56% - 8px) calc(44% - 8px);
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  height: ${({ size, theme }) =>
    size === 'SMALL' ? theme.toolkit.spacing.L40 : theme.toolkit.spacing.L48};
  background: ${({ backgroundColor }) => backgroundColor || '#FFFFFF'};
  color: ${({ theme, fontColor }) =>
    fontColor || theme.toolkit.brand.grey?.DARKER};
  padding: 0 ${({ theme }) => theme.toolkit.spacing.S8};

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    height: ${({ size, theme }) =>
      size === 'SMALL' ? theme.toolkit.spacing.L48 : theme.toolkit.spacing.L56};
  }
`;

export const LeftSlotWrapper = styled.div<BrandBannerProps>`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  img {
    max-height: ${({ size, theme }) =>
      size === 'SMALL' ? theme.toolkit.spacing.M24 : theme.toolkit.spacing.M32};
  }

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    height: ${({ size, theme }) =>
      size === 'SMALL' ? theme.toolkit.spacing.M32 : theme.toolkit.spacing.L40};
    img {
      max-height: ${({ size, theme }) =>
        size === 'SMALL'
          ? theme.toolkit.spacing.M32
          : theme.toolkit.spacing.L40};
    }
  }
`;

export const RightSlotWrapper = styled.div<BrandBannerProps>`
  align-items: center;
  display: grid;
  grid-template-columns: minmax(calc(56%), 1fr) minmax(auto, auto);
  height: 100%;
  justify-content: space-between;
`;

export const TextWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ImageWrapper = styled.div`
  height: ${({ theme }) => theme.toolkit.spacing.M32};
  justify-content: flex-end;
  margin: auto;
  padding-left: ${({ theme }) => theme.toolkit.spacing.S8};
  width: 100%;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    height: ${({ theme }) => theme.toolkit.spacing.L40};
    padding-left: ${({ theme }) => theme.toolkit.spacing.S12};
  }
`;
