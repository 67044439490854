import * as Atom from '../../../atoms';
import Card from '../../../organisms/card/Card';
import { CardContent, CardLayout } from '../../../organisms';
import { InfoTitle } from '../../../molecules/infoTitle/InfoTitle';
import { ImgPlacement, InfoTitleSize } from '../../../molecules';
import Link from 'next/link';
import styled from 'styled-components';
import { getImageElement } from './UnitImageHelper';

type SrpCardSubUnitType = {
  props: SrpCardSubUnitUIType;
};

export type SrpCardSubUnitUIType = {
  metaProps: any;
  image?: { src: string; alt: string };
  titlePrice: string;
  remainingSubunits?: number;
  shouldShowImage: boolean;
  url: string;
  isLoadEagerly: boolean;
  dataTracking?: { [key: string]: string };
};

const cardContentLayoutData = {
  cardContentPaddingX: '16px',
  cardContentPaddingY: '12px',
};

export const StyledA = styled.a`
  text-decoration: none;
  display: block;
  color: inherit;
  border-top: 1px solid #dddddd;

  &:focus,
  &:active {
    color: inherit;
    outline: none;
  }
  &:hover {
    color: inherit;
    background-color: #f4f4f4;
  }
`;

export const SrpCardSubUnit = (
  contentProps: SrpCardSubUnitType,
): JSX.Element => {
  const {
    metaProps,
    image,
    titlePrice,
    shouldShowImage,
    url,
    isLoadEagerly,
    dataTracking,
  } = contentProps.props;

  const imageElement = getImageElement(shouldShowImage, image, isLoadEagerly);

  return (
    <Link href={url} passHref legacyBehavior>
      <StyledA>
        <Card
          cardContentLayout={cardContentLayoutData}
          layout={CardLayout.VerticalDesktop}
          cardContent={
            <>
              <CardContent.Row
                startColumn={1}
                spanColumns={11}
                dataTracking={dataTracking?.['subunit']}
              >
                <div style={{ minWidth: '660px' }}>
                  <InfoTitle
                    size={InfoTitleSize.SMALL}
                    img={imageElement}
                    imgPlacement={ImgPlacement.LEFTCENTER}
                    heading={
                      <Atom.Text
                        as="p"
                        size={Atom.TextSize.S14}
                        fontWeight={Atom.TextFontWeight.SEMIBOLD}
                        textColor="#333333"
                      >
                        {titlePrice}
                      </Atom.Text>
                    }
                    metaLabel={
                      <Atom.MetaLabel
                        MetaLabelSizes={Atom.MetaLabelSizes.MEDIUM}
                        metaProps={metaProps}
                      ></Atom.MetaLabel>
                    }
                  />
                </div>
              </CardContent.Row>
              <CardContent.Row
                startColumn={12}
                spanColumns={1}
                verticalAlignment="center"
              >
                <div style={{ height: '16px', color: '#717171' }}>
                  <Atom.Icon size={12} icon="CHEVRONRIGHT" />
                </div>
              </CardContent.Row>
            </>
          }
        />
      </StyledA>
    </Link>
  );
};
