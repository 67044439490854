import styled from 'styled-components';

export const InputGroupWrapper = styled.div<{
  maxColumns: number;
  spacing: number;
  oneColumnOnMobile: boolean;
}>`
  display: grid;
  grid-gap: ${({ spacing }) => `${spacing}px`};
  padding: 0 1.5px;

  // >= 1024
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.large}) {
    grid-template-columns: ${({ maxColumns }) =>
      maxColumns && `repeat(${maxColumns}, 1fr)`};
  }

  // [640 - 1024)
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.medium}) and (${({ theme }) =>
      theme.toolkit.mediaQuery.mediumOnly}) {
    grid-template-columns: ${({ maxColumns }) =>
      maxColumns > 3
        ? `repeat(${maxColumns - 1}, 1fr)`
        : `repeat(${maxColumns}, 1fr)`};
  }

  // [440 - 640)
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.small}) and (${({ theme }) =>
      theme.toolkit.mediaQuery.smallOnly}) {
    grid-template-columns: ${({ maxColumns }) =>
      maxColumns > 2 ? `repeat(2, 1fr)` : `repeat(${maxColumns}, 1fr)`};
  }

  // < 440
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.xsmallOnly}) {
    grid-template-columns: ${({ maxColumns, oneColumnOnMobile }) =>
      maxColumns > 1 && !oneColumnOnMobile
        ? 'repeat(2, 1fr)'
        : 'repeat(1, 1fr)'};
  }
`;
