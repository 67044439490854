import { ReactNode } from 'react';

export enum BrandBannerSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export type BrandBannerProps = {
  size?: BrandBannerSize;
  backgroundColor?: string;
  fontColor?: string;
  leftSideLogo?: ReactNode;
  rightSideImage?: JSX.Element;
  rightTitle?: ReactNode;
  rightSubtitle?: ReactNode;
  dataTracking?: { [key: string]: string };
};
