import styled from 'styled-components';

export const NavItem = styled.li`
  button {
    background-color: transparent;
    height: ${({ theme }) => theme.toolkit.spacing.M24}
    display: block;
    text-decoration: none;
    cursor: pointer;
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.SEMIBOLD};
    ${({ theme }) => theme.toolkit.typography.fontSizes.M14};
    padding: ${({ theme }) => theme.toolkit.spacing.S4} ${({ theme }) =>
  theme.toolkit.spacing.S8};
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
      &:hover {
        color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
      }
      &:visited {
      color: inherit;
    }
    }
  }


  @media ${({ theme }) => theme.toolkit.mediaQuery.smallOnly} {
    margin-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
    button a:hover,
    a:hover {
      color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
    }
  }

  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    margin-left: ${({ theme }) => theme.toolkit.spacing.M24};
    position: relative;
    padding: ${({ theme }) => theme.toolkit.spacing.M16} 0;
    button a:hover,
    button:hover {
      color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
    }

    a {
      color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
      text-decoration: none;
      border-bottom: ${({ theme }) => theme.toolkit.spacing.S2};
        ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
      &:hover {
        color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
        text-decoration: ${({ theme }) =>
          theme.toolkit.spacing.S2} solid underline ${({ theme }) =>
  theme.toolkit.brand.primary.BASE};
        text-underline-offset: ${({ theme }) => theme.toolkit.spacing.S8}
      }
    }
  }
`;

export const SubNavWrapper = styled.ul<{ bgColor?: string }>`
  padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
  @media ${({ theme }) => theme.toolkit.mediaQuery.mediumOnly} {
    margin-top: ${({ theme }) => theme.toolkit.spacing.M16};
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    display: none;
    position: absolute;
    left: 0;
    top: 3.125rem;
    min-width: 12.5rem;
    background: ${({ theme }) => theme.toolkit.brand.background.primary};
    border: 0.0625rem solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
    border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
    padding: ${({ theme }) => theme.toolkit.spacing.S12};
  }
  &[aria-hidden='false'] {
    display: block;
  }
`;

export const SubNavItem = styled.li`
  display: block;
  margin-bottom: ${({ theme }) => theme.toolkit.spacing.M24};
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    margin-bottom: ${({ theme }) => theme.toolkit.spacing.S12};
  }
  a {
    ${({ theme }) => theme.toolkit.typography.fontSizes.M14};
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.SEMIBOLD};
  }
`;
