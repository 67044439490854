import styled, { keyframes } from 'styled-components';
import { TSpinner } from './Spinner.types';

const spinnerAnimation = keyframes`
from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const SpinnerWrapper = styled.div<TSpinner>`
  left: ${({ fullPage }) => (fullPage ? '50%' : 0)};
  position: ${({ fullPage }) => (fullPage ? 'absolute' : 'relative')};
  top: ${({ fullPage }) => (fullPage ? '50%' : 0)};
`;

export const SpinnerIcon = styled.span<TSpinner>`
  display: block;
  height: ${({ size, theme }) =>
    size === 'small'
      ? `${theme.toolkit.spacing.M16}`
      : `${theme.toolkit.spacing.M24}`};
  width: ${({ size, theme }) =>
    size === 'small'
      ? `${theme.toolkit.spacing.M16}`
      : `${theme.toolkit.spacing.M24}`};
  border-width: ${({ size }) => (size === 'small' ? '2px' : '3px')};
  border-style: solid;
  ${({ colour, theme }) =>
    colour === 'blue' && `border-color: ${theme.toolkit.brand.primary.BASE}`};
  ${({ colour }) => colour === 'white' && 'border-color: white'};
  ${({ colour, theme }) =>
    colour === 'black' && `border-color: ${theme.toolkit.brand.grey?.DARKER}}`};
  border-top-color: transparent;
  border-radius: 50%;
  animation: ${spinnerAnimation} 800ms linear infinite;
`;
