import type { LabelHTMLAttributes } from 'react';

export enum LabelWeight {
  REGULAR = 'REGULAR',
  SEMIBOLD = 'SEMIBOLD',
}

export enum LabelSize {
  S12 = 'S12',
  S14 = 'S14',
  M16 = 'M16',
}

type TLabel = {
  required?: boolean;
  weight?: LabelWeight;
  size?: LabelSize;
  secondaryText?: string;
  vertical?: boolean;
  labelColor?: string;
  secondaryColor?: string;
} & LabelHTMLAttributes<HTMLLabelElement>;

type TLabelStyled = {
  labelColor?: string | undefined;
  secondaryColor?: string | undefined;
  size?: LabelSize;
  vertical?: boolean | undefined;
  weight?: LabelWeight;
};

export { type TLabel, type TLabelStyled };
