import * as Styled from './BrandBanner.styled';

import { BrandBannerProps } from './BrandBanner.types';

export const BrandBanner = ({
  size,
  leftSideLogo,
  rightSideImage,
  rightSubtitle,
  rightTitle,
  backgroundColor,
  fontColor,
  dataTracking,
}: BrandBannerProps) => {
  return (
    <Styled.Wrapper
      {...(dataTracking && {
        'data-tracking': dataTracking?.['brandBanner'],
      })}
      size={size}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
    >
      <Styled.LeftSlotWrapper
        size={size}
        {...(dataTracking && {
          'data-tracking': dataTracking?.['leftSlot'],
        })}
      >
        {leftSideLogo}
      </Styled.LeftSlotWrapper>
      <Styled.RightSlotWrapper size={size}>
        <Styled.TextWrapper>
          {rightTitle}
          {rightSubtitle}
        </Styled.TextWrapper>
        {rightSideImage && (
          <Styled.ImageWrapper>{rightSideImage}</Styled.ImageWrapper>
        )}
      </Styled.RightSlotWrapper>
    </Styled.Wrapper>
  );
};

export default BrandBanner;
