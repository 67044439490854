import { ReactNode, InputHTMLAttributes } from 'react';
import { LabelSize, LabelWeight } from '../../atoms/label/Label.types';

export type TCheckbox = {
  checked?: boolean | undefined;
  hasError?: boolean | undefined;
  icon?: ReactNode | undefined;
  inputLabel: string;
  labelWeight?: LabelWeight;
  secondary?: string | undefined;
  subtext?: string | undefined;
  variant?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const VariantMap: {
  [key: string]: { checkboxWidth: string; textSize: LabelSize };
} = {
  medium: { checkboxWidth: '40px', textSize: LabelSize.S14 },
  small: { checkboxWidth: '36px', textSize: LabelSize.S12 },
};
