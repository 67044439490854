import { forwardRef } from 'react';
import type {
  SelectOptionGroupProps,
  SelectOptionProps,
  TSelect,
} from './Select.types';
import * as Styled from './Select.styled';
import Icon from '../icon/Icon';

const Select = forwardRef<HTMLSelectElement, TSelect>((props: TSelect, ref) => {
  const { hasError = false, hasPlaceholder = true, children, ...rest } = props;
  // TODO: Update hasValue condition to work with uncontrolled selects.
  const hasValue = !!props.value;

  return (
    <Styled.Wrapper>
      <Styled.Select
        hasPlaceholder={hasPlaceholder}
        hasError={hasError}
        hasValue={hasValue}
        ref={ref}
        {...rest}
      >
        {children}
      </Styled.Select>
      <Styled.IconWrapper>
        <Icon icon="CHEVRONDOWN" size={12} />
      </Styled.IconWrapper>
    </Styled.Wrapper>
  );
});

const Option = (props: SelectOptionProps) => <option {...props} />;

const OptionGroup = (props: SelectOptionGroupProps) => <optgroup {...props} />;

export default Object.assign(Select, {
  Option,
  OptionGroup,
});
