import { useEffect, useState } from 'react';
import { Indicator, IndicatorContainer } from './GalleryIndicator.styled';
import { GalleryIndicatorProps } from './GalleryIndicator.types';

export const GalleryIndicator = (props: GalleryIndicatorProps) => {
  const { currentIndex, items } = props;
  const [offset, setOffset] = useState(0);
  const indicatorsArray = Array.from({ length: items }, (_, i) => i + 1);

  useEffect(() => {
    // Calculate the offset for sliding effect. We only need to slide when we have 5+ items
    // Active item and gap is 0.5rem (8px) and inactive are 6px. So the total width is 50px
    // When we slide we will be hiding one inactive item and moving 8px of gap. So each slide
    // is 14px. We use the current index to find out many 14px we are transforming
    const SLIDE_WIDTH = 14;

    if (items <= 4 || currentIndex <= 2) {
      setOffset(0);
    } else {
      const maxOffset = Math.max(0, items - 4);
      const newOffset = Math.min(maxOffset, currentIndex - 2);

      setOffset(-newOffset * SLIDE_WIDTH);
    }
  }, [currentIndex, items]);

  return (
    <IndicatorContainer data-testid="indicator-container" items={items}>
      {indicatorsArray?.map((_, i) => (
        <Indicator
          key={i}
          active={i === currentIndex}
          data-testid={
            i === currentIndex ? 'indicator-active' : 'indicator-default'
          }
          translateXOffset={offset}
        />
      ))}
    </IndicatorContainer>
  );
};

export default GalleryIndicator;
