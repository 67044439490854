import { useBurger } from './Burger.hook';
import * as Styled from './Burger.styled';
import { BurgerProps } from './Burger.types';

export function Burger({ callback, initialState }: BurgerProps) {
  const { open, burgerToggle } = useBurger(callback, initialState);
  return (
    <Styled.BurgerWrapper
      onClick={() => {
        burgerToggle();
      }}
      type="button"
      aria-haspopup={true}
      aria-expanded={open}
      aria-controls="Main__Navigation"
    >
      <Styled.Burger role="presentation" className={open ? 'active' : ''}>
        <span></span>
        <span></span>
        <span></span>
      </Styled.Burger>
      <span className="hide-accessibility">
        {open ? 'Close' : 'Open'} the main navigation
      </span>
    </Styled.BurgerWrapper>
  );
}
