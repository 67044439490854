import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import { SortableProps } from './Sortable.types';

class CustomKeyboardSensor extends KeyboardSensor {
  static override activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: (event: KeyboardEvent) => {
        if (event.key !== 'Enter') return false;
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

const shouldHandleEvent = (element: HTMLElement | null) => {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option',
  ];
  if (
    element?.tagName &&
    !interactiveElements.includes(element.tagName.toLowerCase())
  ) {
    return true;
  }

  return false;
};

const Sortable = <T extends { id: UniqueIdentifier }>(
  props: SortableProps<T>
) => {
  const { children, data, setData, onSort } = props;
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 5,
      },
    }),
    useSensor(CustomKeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over.id);
      const newData = arrayMove(data, oldIndex, newIndex);
      setData(newData);
      onSort?.(newData);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={data} strategy={rectSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export { Sortable };
