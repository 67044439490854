export enum TabButtonStyle {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}
export enum TabButtonSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export type TabButtonStyleProps = {
  TabButtonSize?: string;
  TabButtonStyle?: string;
  isActive?: boolean;
  layout?: string;
};

export type TabButtonProps = {
  children: JSX.Element | string;
  testId: string;
  TabButtonSize?: TabButtonSizes;
  TabButtonStyle?: TabButtonStyle;
  isActive?: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  content?: JSX.Element;
  isDisabled?: boolean;
  layout?: string;
};
