import styled, { css } from 'styled-components';
import { SelectStyledProps } from './Select.types';

export const Wrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 1.125rem;
  right: ${({ theme }) => theme.toolkit.spacing.S12};
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
`;

export const Select = styled.select<SelectStyledProps>`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 100%;
  height: ${({ theme }) => theme.toolkit.spacing.L48};
  border: none;
  padding: ${({ theme }) =>
    `${theme.toolkit.spacing.S12} ${theme.toolkit.spacing.M32} ${theme.toolkit.spacing.S12} ${theme.toolkit.spacing.M20}`};
  ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
  background: #ffffff;
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  color: ${({ hasValue, theme, hasPlaceholder }) =>
    hasPlaceholder
      ? hasValue
        ? theme.toolkit.brand.grey?.DARKER
        : theme.toolkit.brand.grey?.DARK
      : theme.toolkit.brand.grey?.DARKER};

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px
      ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
    color: ${({ theme }) => theme.toolkit.brand.grey?.BASE};
    ~ ${IconWrapper} {
      color: ${({ theme }) => theme.toolkit.brand.grey?.BASE};
    }
    opacity: 1;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:focus {
        box-shadow: inset 0 0 0 1px
          ${({ theme }) => theme.toolkit.brand.warning.DARK};
      }
    `}
`;
