import styled from 'styled-components';
import { IconStyleProps } from './Icons.types';

export const IconWrapper = styled.div<IconStyleProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  svg{
    color: ${({ color }) => color};
  }
`;
