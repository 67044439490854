import {
  CarouselContainer,
  PhotoItem,
  CarouselScroll,
  CarouselBlurLeft,
  CarouselBlurRight,
} from './Carousel.styled';
import { TCarousel } from './Carousel.types';
import { useCarousel } from './Carousel.hooks';

export const Carousel = (props: TCarousel) => {
  const { photos, callback, activeIndex, disabled } = props;

  const {
    handleWheel,
    handleMouseDown,
    handleMouseMove,
    handleImageClick,
    handleMouseUp,
    handleScroll,
    bounceLeft,
    bounceRight,
    containerRef,
    blurLeft,
    blurRight,
  } = useCarousel(callback, disabled);

  return (
    <CarouselContainer data-testid="carousel">
      {blurLeft && <CarouselBlurLeft />}
      {blurRight && <CarouselBlurRight />}
      <CarouselScroll
        ref={containerRef}
        className={
          bounceLeft ? 'bounce-left' : bounceRight ? 'bounce-right' : ''
        }
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onWheel={handleWheel}
        onScroll={handleScroll}
      >
        {photos.map((photo, index) => (
          <PhotoItem
            key={index}
            className={'photo-item' + (index === activeIndex ? ' active' : '')}
            onClick={(e) => handleImageClick(e, index)}
          >
            <img src={photo} alt={`carousel-${index}`} />
          </PhotoItem>
        ))}
      </CarouselScroll>
    </CarouselContainer>
  );
};
