import { TabButtonProps } from '../../atoms';
import {
  TabButtonStyle,
  TabButtonSizes,
} from '../../atoms/tabButton/TabButton.types';

export enum ETabLayout {
  FILL = 'FILL',
  FIXED = 'FIXED',
  SPACE_BETWEEN = 'SPACE_BETWEEN',
}

export type TTabBase = {
  tabStyle?: TabButtonStyle;
  underlined?: boolean;
  center?: boolean;
  fullPage?: boolean;
  layout?: ETabLayout;
};

export interface ITab extends TTabBase {
  tabs: TabButtonProps[];
  linkSize?: TabButtonSizes;
  defaultTab?: number;
  currentTab: number;
  setCurrentTab: (index: number) => void;
  progress?: number;
  overflow?: number;
}

export type ITabStyle = TTabBase;

export type TTabHook = {
  currentTab: number;
  setCurrentTab: (index: number) => void;
};
