import styled, { css } from 'styled-components';
import { Icon } from '../../atoms';
import * as Types from './RoundButton.types';

type StyledProps = {
  buttonType?: Types.RoundButtonTypes;
  buttonSize?: Types.RoundButtonSizes;
  isDisabled?: boolean;
  hasError?: boolean;
};

export const IconContainer = styled(Icon)<{ buttonType?: string }>`
  width: ${({ theme }) => theme.toolkit.spacing.M16};
  height: ${({ theme }) => theme.toolkit.spacing.M16};
`;

const getSize = (size?: string) => {
  switch (size) {
    case 'MEDIUM':
      return css`
        height: ${({ theme }) => theme.toolkit.spacing.L48};
        width: ${({ theme }) => theme.toolkit.spacing.L48};
      `;
    case 'SMALL':
      return css`
        height: ${({ theme }) => theme.toolkit.spacing.L40};
        width: ${({ theme }) => theme.toolkit.spacing.L40};
      `;
    case 'X_SMALL':
      return css`
        height: ${({ theme }) => theme.toolkit.spacing.M32};
        width: ${({ theme }) => theme.toolkit.spacing.M32};
      `;
    default:
      return '';
  }
};

const getStyle = (
  buttonType?: string,
  hasError?: boolean,
  isDisabled?: boolean
) => {
  switch (buttonType) {
    case 'PRIMARY':
      return hasError && isDisabled
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.error.LIGHTER};
            color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
          `
        : hasError
        ? css`
            background-color: ${({ theme }) => theme.toolkit.brand.error.DARK};
            color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
          `
        : isDisabled
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.LIGHTER};
            color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
          `
        : css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.BASE};
            color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
            &:hover {
              background-color: ${({ theme }) =>
                theme.toolkit.brand.primary.DARKER};
            }
            &:active {
              background-color: ${({ theme }) =>
                theme.toolkit.brand.primary.LIGHT};
            }
            &:focus:not(:focus-visible) {
              outline: none;
            }
            &:focus:focus-visible {
              outline: ${({ theme }) => theme.toolkit.spacing.S4} solid
                ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            }
          `;
    case 'SECONDARY':
      return hasError && isDisabled
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            color: ${({ theme }) => theme.toolkit.brand.error.LIGHTER};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.error.LIGHTER};
          `
        : hasError
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            color: ${({ theme }) => theme.toolkit.brand.error.DARK};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.error.DARK};
          `
        : isDisabled
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.primary.LIGHTER};
            color: ${({ theme }) => theme.toolkit.brand.primary.LIGHTER};
          `
        : css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.primary.BASE};
            &:hover {
              border: 0.0625rem solid
                ${({ theme }) => theme.toolkit.brand.primary.DARKER};
              background-color: ${({ theme }) =>
                theme.toolkit.brand.primary.LIGHTEST};
              color: ${({ theme }) => theme.toolkit.brand.primary.DARKER};
            }
            &:active {
              background-color: ${({ theme }) =>
                theme.toolkit.brand.primary.LIGHTEST};
            }
            &:focus:not(:focus-visible) {
              outline: none;
            }
            &:focus:focus-visible {
              outline: ${({ theme }) => theme.toolkit.spacing.S4} solid
                ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            }
          `;
    case 'TERTIARY':
      return hasError && isDisabled
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            color: ${({ theme }) => theme.toolkit.brand.error.LIGHTER};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.error.LIGHTER};
          `
        : hasError
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            color: ${({ theme }) => theme.toolkit.brand.error.DARK};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.error.DARK};
          `
        : isDisabled
        ? css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
            color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
          `
        : css`
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
            color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
            border: 0.0625rem solid
              ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            &:hover,
            &:active {
              border: 0.0625rem solid
                ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
              background-color: ${({ theme }) =>
                theme.toolkit.brand.grey?.LIGHTEST};
              color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
            }
            &:focus:not(:focus-visible) {
              outline: none;
            }
            &:focus:focus-visible {
              outline: ${({ theme }) => theme.toolkit.spacing.S4} solid
                ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            }
          `;
    case 'OVERLAY':
      return isDisabled
        ? css`
            color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
            background-color: rgba(51, 51, 51, 0.16);
          `
        : css`
            background-color: rgba(51, 51, 51, 0.56);
            color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
            &:hover {
              background-color: rgba(51, 51, 51, 0.64);
            }
            &:active {
              background-color: rgba(51, 51, 51, 0.48);
            }
            &:focus:not(:focus-visible) {
              outline: none;
            }
            &:focus:focus-visible {
              outline: ${({ theme }) => theme.toolkit.spacing.S4} solid
                ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            }
          `;
    default:
      return '';
  }
};

export const RoundButton = styled.button<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${({ buttonSize }) => getSize(buttonSize)}
  ${({ buttonType, hasError, isDisabled }) =>
    getStyle(buttonType, hasError, isDisabled)}
`;
