import styled, { css } from 'styled-components';
import { TextareaProps } from './Textarea.types';

const getDisabledStyles = () => css`
  background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
  border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};

  ::placeholder {
    color: ${({ theme }) => theme.toolkit.brand.grey?.BASE};
  }

  &:focus {
    border-color: ${(props) => props.theme.toolkit.brand.text.disabled};
  }
`;

const getErrorStyles = () => {
  return css`
    border: 1px solid ${({ theme }) => theme.toolkit.brand.warning.DARK};

    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.toolkit.brand.warning.DARK};
    }
  `;
};

const Textarea = styled.textarea<TextareaProps>((props) => {
  const { hasError, disabled } = props;

  return css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: ${({ theme }) => theme.toolkit.spacing.S12}
      ${({ theme }) => theme.toolkit.spacing.M20};
    font: inherit;
    ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
    border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    outline: none;
    resize: ${props.resize};
    cursor: auto;

    ::-webkit-scrollbar {
      width: ${({ theme }) => theme.toolkit.spacing.S12};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
      border-radius: ${({ theme }) => theme.toolkit.spacing.S12};
      border: ${({ theme }) => theme.toolkit.spacing.S4} solid
        ${({ theme }) => theme.toolkit.brand.background.primary};
    }

    ::placeholder {
      color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
    }

    &:focus {
      outline: ${({ theme }) => theme.toolkit.spacing.S2} solid
        ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
      outline-offset: -2px;
    }

    ${hasError && getErrorStyles()}
    ${disabled && getDisabledStyles()}
  `;
});

export { Textarea };
