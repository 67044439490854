import { SpinnerIcon, SpinnerWrapper } from './Spinner.styled';
import { TSpinner } from './Spinner.types';

export const Spinner = (props: TSpinner) => {
  return (
    <SpinnerWrapper fullPage={props.fullPage}>
      <SpinnerIcon role="alert" aria-live="assertive" {...props} />{' '}
    </SpinnerWrapper>
  );
};
