import styled from 'styled-components';
import * as Types from './Link.types';

export const Link = styled.a<Types.LinkStyleProps>`
  ${({ theme }) => theme.toolkit.components.link.base};
  ${({ linkStyle, theme }) =>
    theme.toolkit.components.link.variants[linkStyle]};
  font-weight: ${({ weight, theme }) =>
    theme.toolkit.components.link.weights[weight]};
  ${({ type, theme }) => theme.toolkit.components.link.type[type]};
  text-decoration: ${({ underlined }) => underlined && 'underline'};

  &:focus-visible {
    outline-style: solid;
    outline-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
    outline-width: ${({ theme }) => theme.toolkit.spacing.S4};
    box-shadow: 0px 0px 0px 4px
      ${({ theme }) => theme.toolkit.brand.grey?.LIGHT}; //Adds a radius to the outline
    border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
  }
`;

//Container to hold the Link text and Icon
export const LinkContentWrapper = styled.span<Types.LinkContentWrapperStyle>`
  ${({ theme, size }) => theme.toolkit.components.link.size[size]};
  display: flex;
  flex-direction: ${({ iconLeading }) => (iconLeading ? 'row' : 'row-reverse')};
  gap: ${({ theme }) => theme.toolkit.spacing.S4};
  align-items: baseline;
  width: fit-content;

  svg {
    width: ${({ iconSize }) => `${iconSize}px`};
    height: ${({ iconSize }) => `${iconSize}px`};
    position: relative;
    bottom: -0.1375rem;
  }
`;
