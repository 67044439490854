import * as Types from './Link.types';
import * as Styled from './Link.styled';

export const Link = ({
  type = Types.Type.STANDALONE,
  size = Types.Size.MEDIUM,
  style = Types.Style.PRIMARY,
  weight = Types.Weight.REGULAR,
  children,
  href,
  underlined,
  iconLeading,
  openNewTab,
  onClick,
}: Types.LinkProps) => {
  const getIconSize = (textSize: string | undefined) => {
    return textSize === 'LARGE' ? 20 : 16;
  };
  return (
    <Styled.Link
      tabIndex={0} /* tabindex is to allow focus state */
      type={type}
      linkStyle={style}
      underlined={underlined}
      weight={weight}
      target={openNewTab ? '_blank' : ''} //_blank will open link in new tab
      href={href}
      onClick={onClick}
    >
      <Styled.LinkContentWrapper
        iconLeading={iconLeading}
        iconSize={getIconSize(size)}
        size={size}
      >
        {children}
      </Styled.LinkContentWrapper>
    </Styled.Link>
  );
};
