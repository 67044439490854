import styled, { css } from 'styled-components';
import { DateTimePickerIcons } from './DateTimePickerIcons';

const HeaderTextCss = css`
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  font-size: 14px;
  line-height: 50px;
  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.SEMIBOLD};
`;

export const DatePickerCSSWrapper = styled.div`
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #ccc;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
    content: '';
    z-index: -1;
    border-width: 8px;
    left: -8px;
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #f0f0f0;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    top: 0;
  }
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
  }

  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
    bottom: 0;
    margin-bottom: -8px;
  }
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after {
    border-bottom: none;
    border-top-color: #fff;
  }
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::after {
    bottom: 0;
  }
  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before {
    bottom: -1px;
    border-top-color: #aeaeae;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
  }

  .react-datepicker {
    font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
    font-size: ${({ theme }) => theme.toolkit.spacing.S8};
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.1875rem;
    display: inline-block;
    position: relative;
  }

  .react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
  }

  .react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
  }
  .react-datepicker--time-only .react-datepicker__time,
  .react-datepicker--time-only .react-datepicker__time-box {
    border-bottom-left-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper {
    z-index: 100;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 10px;
  }

  .react-datepicker-popper[data-placement='bottom-end']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement='top-end']
    .react-datepicker__triangle {
    left: auto;
    right: 50px;
  }
  .react-datepicker-popper[data-placement^='top'] {
    padding-bottom: 10px;
  }
  .react-datepicker-popper[data-placement^='right'] {
    padding-left: 8px;
  }
  .react-datepicker-popper[data-placement^='right']
    .react-datepicker__triangle {
    left: auto;
    right: 42px;
  }
  .react-datepicker-popper[data-placement^='left'] {
    padding-right: 8px;
  }
  .react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
    left: 42px;
    right: auto;
  }

  .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.1875rem;
    padding: 8px 0;
    position: relative;
  }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
  }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.1875rem;
  }

  .react-datepicker__year-dropdown-container--select,
  .react-datepicker__month-dropdown-container--select,
  .react-datepicker__month-year-dropdown-container--select,
  .react-datepicker__year-dropdown-container--scroll,
  .react-datepicker__month-dropdown-container--scroll,
  .react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 15px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.59rem;
  }

  .react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
  }
  .react-datepicker__navigation--previous {
    left: 2px;
  }
  .react-datepicker__navigation--next {
    right: 2px;
  }
  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 85px;
  }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-datepicker__navigation--years-previous {
    top: 4px;
  }
  .react-datepicker__navigation--years-upcoming {
    top: -4px;
  }
  .react-datepicker__navigation:hover *::before {
    border-color: #a6a6a6;
  }

  .react-datepicker__navigation-icon {
    position: relative;
    top: -1px;
    font-size: 20px;
    width: 0;
  }
  .react-datepicker__navigation-icon--next {
    left: -2px;
  }
  .react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: -7px;
  }
  .react-datepicker__navigation-icon--previous {
    right: -2px;
  }
  .react-datepicker__navigation-icon--previous::before {
    transform: rotate(225deg);
    right: -7px;
  }

  .react-datepicker__month-container {
    float: left;
  }

  .react-datepicker__year {
    margin: ${({ theme }) => theme.toolkit.spacing.S4};
    text-align: center;
  }
  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }
  .react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: ${({ theme }) => theme.toolkit.spacing.L40};
    margin: 2px;
  }

  .react-datepicker__month {
    margin: ${({ theme }) => theme.toolkit.spacing.S4};
    text-align: center;
  }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: ${({ theme }) => theme.toolkit.spacing.L40};
    margin: 2px;
  }

  .react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 5px 0 10px 15px;
    text-align: left;
  }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container {
    display: inline-block;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input {
    display: inline-block;
    margin-left: 10px;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input {
    width: auto;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type='time']::-webkit-inner-spin-button,
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type='time']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__input
    input[type='time'] {
    -moz-appearance: textfield;
  }
  .react-datepicker__input-time-container
    .react-datepicker-time__input-container
    .react-datepicker-time__delimiter {
    margin-left: 5px;
    display: inline-block;
  }

  .react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 85px;
  }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.1875rem;
    position: absolute;
    right: -87px;
    top: 0;
  }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.1875rem;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 85px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
    border-bottom-right-radius: 0.1875rem;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.0625rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
    white-space: nowrap;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #ccc;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
  }

  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.0625rem;
    line-height: 1.0625rem;
    text-align: center;
    margin: 0.10375rem;
  }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
  }
  .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.1875rem;
    background-color: #f0f0f0;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day-names {
    margin-bottom: -8px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.0625rem;
    line-height: 1.0625rem;
    text-align: center;
    margin: 0.10375rem;
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    border-radius: 0.1875rem;
    background-color: #216ba5;
    color: #fff;
  }
  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: #1d5d90;
  }
  .react-datepicker__month--disabled,
  .react-datepicker__quarter--disabled {
    color: #ccc;
    pointer-events: none;
  }
  .react-datepicker__month--disabled:hover,
  .react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent;
  }

  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    cursor: pointer;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 0.1875rem;
    background-color: #f0f0f0;
  }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: bold;
  }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted,
  .react-datepicker__quarter-text--highlighted,
  .react-datepicker__year-text--highlighted {
    border-radius: 0.1875rem;
    background-color: #3dcc4a;
    color: #fff;
  }
  .react-datepicker__day--highlighted:hover,
  .react-datepicker__month-text--highlighted:hover,
  .react-datepicker__quarter-text--highlighted:hover,
  .react-datepicker__year-text--highlighted:hover {
    background-color: #32be3f;
  }
  .react-datepicker__day--highlighted-custom-1,
  .react-datepicker__month-text--highlighted-custom-1,
  .react-datepicker__quarter-text--highlighted-custom-1,
  .react-datepicker__year-text--highlighted-custom-1 {
    color: magenta;
  }
  .react-datepicker__day--highlighted-custom-2,
  .react-datepicker__month-text--highlighted-custom-2,
  .react-datepicker__quarter-text--highlighted-custom-2,
  .react-datepicker__year-text--highlighted-custom-2 {
    color: green;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.1875rem;
    background-color: #216ba5;
    color: #fff;
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: #1d5d90;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.1875rem;
    background-color: #bad9f1;
    color: rgb(0, 0, 0);
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #1d5d90;
  }
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__month-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__quarter-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ),
  .react-datepicker__year-text--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: rgba(33, 107, 165, 0.5);
  }
  .react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__month-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__quarter-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ),
  .react-datepicker__month--selecting-range
    .react-datepicker__year-text--in-range:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range
    ) {
    background-color: #f0f0f0;
    color: #000;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled,
  .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc;
  }
  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__month-text.react-datepicker__month--selected:hover,
  .react-datepicker__month-text.react-datepicker__month--in-range:hover,
  .react-datepicker__month-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__month--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: #216ba5;
  }
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background-color: #f0f0f0;
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 0.3125rem;
  }

  .react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 25px;
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    position: relative;
  }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer;
  }
  .react-datepicker__year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__year-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view:hover
    .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover
    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-year-read-view:hover
    .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    right: -16px;
    top: 0;
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.1875rem;
    border: 1px solid #aeaeae;
  }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
  }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem;
  }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #ccc;
  }
  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-upcoming,
  .react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
  }
  .react-datepicker__year-option:hover
    .react-datepicker__navigation--years-previous,
  .react-datepicker__month-option:hover
    .react-datepicker__navigation--years-previous,
  .react-datepicker__month-year-option:hover
    .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
  }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
  }

  .react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }
  .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '×';
  }

  .react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }

  .react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 1.875rem;
    line-height: 1.875rem;
  }

  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: ${({ theme }) => theme.toolkit.spacing.M20};
      line-height: ${({ theme }) => theme.toolkit.spacing.M20};
    }
  }

  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 0.9rem;
  }

  .react-datepicker__children-container {
    width: 8.625rem;
    margin: ${({ theme }) => theme.toolkit.spacing.S4};
    padding-right: ${({ theme }) => theme.toolkit.spacing.S2};
    padding-left: ${({ theme }) => theme.toolkit.spacing.S2};
    height: auto;
  }

  .react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }

  .react-datepicker__calendar-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
  }
`;

export const DatePickerWrapper = styled.div<{
  mode: string;
  showDropdown: boolean;
}>`
  position: relative;
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};

  /* component common styles */
  .react-datepicker {
    font-family: 'Open Sans';
    width: 290px !important;
    border-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
    border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
    @media only screen and (min-width: 321px) {
      width: initial;
    }
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  /* date & datetime: date selection container size */
  .react-datepicker__month {
    margin: 0;
    width: ${({ mode }) => (mode === 'datetime' ? '206px;' : '288px;')};
    padding: ${({ mode }) =>
      mode === 'datetime' ? '22px 13px 0;' : '22px 24px 0;'};
    ${({ mode }) => mode === 'month' && `height: 144px; padding: 20px 24px;`}
  }

  /* date & datetime: time selection container  */
  .react-datepicker__time-container {
    background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
    ${({ mode }) => mode === 'time' && `border-top-left-radius: 4px`};
    border-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
    border-top-right-radius: ${({ theme }) => theme.toolkit.spacing.S4};
    border-bottom-right-radius: ${({ theme }) => theme.toolkit.spacing.S4};
    width: ${({ mode }) =>
      mode === 'datetime' ? '82px !important;' : '288px;'};
  }

  /* month: month line container */
  .react-datepicker__month-wrapper {
    display: flex;
    ${({ theme }) => theme.toolkit.typography.fontSizes.M14}
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
  }

  /* month: month text styles */
  .react-datepicker__month-text {
    width: ${({ theme }) => theme.toolkit.spacing.L48};
    height: ${({ theme }) => theme.toolkit.spacing.M24};
    margin: 0;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.REGULAR} !important;
    :hover {
      background: ${({ theme }) => theme.toolkit.brand.primary.LIGHTER};
    }
  }

  /* time & datetime list styles */
  .react-datepicker__time-list,
  .react-datepicker-time__header {
    ${({ mode }) => mode === 'time' && `width: 288px;`}
  }

  .react-datepicker--time {
    ${({ mode }) => mode === 'time' && `height: 240px; width: 288px;`}
  }

  .react-datepicker__time-box {
    ${({ mode }) => mode === 'time' && `width: 288px !important;`}
    ${({ mode }) => mode === 'datetime' && `width: 81px !important;`}
  }

  .react-datepicker__time-list {
    ${({ mode }) => mode === 'time' && `height: 240px;`}
    ${({ mode }) => mode === 'datetime' && `width: 81px !important;`}
    
    /* scroll bar custom styles */
    ::-webkit-scrollbar {
      width: ${({ theme }) =>
        theme.toolkit.spacing.S8}; /* width of the entire scrollbar */
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) =>
        theme.toolkit.brand.background
          .primary}; /* color of the tracking area */
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) =>
        theme.toolkit.brand.grey?.LIGHTER}; /* color of the scroll thumb */
      border-radius: ${({ theme }) =>
        theme.toolkit.spacing.S4}; /* roundness of the scroll thumb */
      border: ${({ theme }) => theme.toolkit.spacing.S2} solid
        ${({ theme }) => theme.toolkit.brand.background.primary}; /* creates padding around scroll thumb */
    }
  }

  .react-datepicker__time-list-item {
    ${({ mode }) => mode === 'time' && `width: 252px !important;`}
  }

  /* date & datetime: every week line custom styles */
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
  }

  /* date styles */
  .react-datepicker .react-datepicker__day.react-datepicker__day--disabled {
    :hover {
      background-color: ${({ theme }) =>
        theme.toolkit.brand.grey?.TEXTCONTRAST};
    }
    color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  }

  .react-datepicker__day {
    border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
    margin: 0;
  }

  /* date and time text color */
  .react-datepicker .react-datepicker__day-name,
  .react-datepicker .react-datepicker__day,
  .react-datepicker .react-datepicker__time-name {
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.REGULAR} !important;
  }

  /* date block size */
  .react-datepicker__day,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected {
    width: ${({ theme }) => theme.toolkit.spacing.M24};
    height: ${({ theme }) => theme.toolkit.spacing.M24};
    ${({ theme }) => theme.toolkit.typography.fontSizes.M14};
  }

  /* disabled color */
  .react-datepicker.react-datepicker__day-name--disabled,
  .react-datepicker.react-datepicker__day--disabled,
  .react-datepicker.react-datepicker__month--disabled,
  .react-datepicker.react-datepicker__time-name--disabled {
    color: #cccccc !important;
  }

  /* common header styles */
  .react-datepicker-time__header,   /* for time only */
  .react-datepicker__header {
    ${HeaderTextCss};
    height: ${({ mode, showDropdown }) =>
      (mode === 'datetime' || mode === 'date') && showDropdown
        ? '100px;'
        : mode === 'datetime' || mode === 'date'
          ? '92px;'
          : mode === 'month' && showDropdown
            ? '60px;'
            : '52px;'};
    background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
  }

  .react-datepicker__header {
    border: 0;
    ${({ mode }) => mode === 'datetime' && `border-top-right-radius: 0;`}
    padding: 2px 0px 0px;
  }

  /* datetime: time header styles */
  .react-datepicker__header--time {
    border-top-right-radius: ${({ theme }) => theme.toolkit.spacing.S4};
    width: 81px;
  }

  /* time: header styles */
  .react-datepicker-time__header {
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    font-size: 14px;
    line-height: 20px;
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.SEMIBOLD};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* week label line */
  .react-datepicker__day-names {
    font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.REGULAR};
    ${({ theme }) => theme.toolkit.typography.fontSizes.M14}
    padding: 
    ${({ mode, showDropdown }) =>
      mode === 'datetime' && showDropdown
        ? '18px 15px;'
        : mode === 'datetime' && !showDropdown
          ? '0 15px;'
          : mode === 'date' && !showDropdown
            ? '0px 24px;'
            : '18px 24px;'};
    display: flex;
    justify-content: space-between;
    .react-datepicker__day-name {
      line-height: inherit;
    }
  }

  /* hide */
  .react-datepicker__triangle {
    display: none;
  }

  /* common selected styled */
  li.react-datepicker__time-list-item--selected,
  .react-datepicker__time-list-item.react-datepicker__time-list-item--selected,
  .react-datepicker__quarter-text--keyboard-selected {
    background-color: ${({ theme }) =>
      theme.toolkit.brand.primary.BASE} !important;
    color: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.REGULAR} !important;
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
    color: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST} !important;
  }

  .react-datepicker__day--in-range {
    color: white !important;
    background-color: ${({ theme }) =>
      theme.toolkit.brand.primary.BASE} !important;
  }

  .react-datepicker__day--in-selecting-range {
    color: white !important;
    background-color: ${({ theme }) =>
      theme.toolkit.brand.primary.BASE} !important;
  }

  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end {
    color: white !important;
    background-color: ${({ theme }) =>
      theme.toolkit.brand.primary.BASE} !important;
    :hover {
      color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER} !important;
      background-color: ${({ theme }) =>
        theme.toolkit.brand.primary.LIGHTER} !important;
    }
  }

  .react-datepicker__day:not(.react-datepicker__day--disabled) {
    :hover {
      color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER} !important;
      background-color: ${({ theme }) =>
        theme.toolkit.brand.primary.LIGHTER} !important;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  }

  /* month: selected styles */
  .react-datepicker__month--selected {
    background: ${({ theme }) => theme.toolkit.brand.primary.BASE} !important;
    color: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
    font-weight: ${({ theme }) =>
      theme.toolkit.typography.fontWeights.REGULAR} !important;
    :hover {
      background-color: ${({ theme }) =>
        theme.toolkit.brand.primary.BASE} !important;
    }
  }

  .react-datepicker__month-text--keyboard-selected:not(
      .react-datepicker__month--selected
    ) {
    background-color: ${({ theme }) => theme.toolkit.brand.grey?.TEXTCONTRAST};
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    :hover {
      background-color: ${({ theme }) =>
        theme.toolkit.brand.primary.LIGHTER} !important;
    }
  }

  /* time: time item styles */
  .react-datepicker__time-list-item,
  .react-datepicker__time-list-item--selected {
    display: flex;
    ${({ mode }) => mode === 'time' && `width: 260px !important;`}
    max-height: 14px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin: ${({ theme }) => theme.toolkit.spacing.M16} 0 0 !important;
  }

  .react-datepicker__time-list-item:not(
      .react-datepicker__time-list-item--selected
    ) {
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    :hover {
      color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER} !important;
      background-color: ${({ theme }) =>
        theme.toolkit.brand.primary.LIGHTER} !important;
    }
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
  }
`;

export const StyledHeader = styled.div<{ mode: string; showDropdown: boolean }>`
  ${HeaderTextCss};
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  padding: ${({ mode, showDropdown }) =>
    mode === 'datetime' && showDropdown
      ? `16px 0 0;`
      : mode === 'datetime'
        ? '0;'
        : mode === 'month' && showDropdown
          ? '16px 0;'
          : mode === 'date' && showDropdown
            ? '16px 0 0;'
            : '0;'};
  margin-right: 0;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.toolkit.spacing.S4};
`;

const base64prefix = 'data:image/svg+xml;base64,';

export const StyledSelectDropdown = styled.select`
  flex: 2;
  font-size: 14px;
  line-height: 20px;
  max-width: 68px;
  min-height: 28px;
  font-family: 'Open Sans';
  border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
  border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
  appearance: none;
  padding-left: ${({ theme }) => theme.toolkit.spacing.S8};
  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.SEMIBOLD};
  background: url(${base64prefix}${btoa(DateTimePickerIcons['ARROW_DOWN'])})
    no-repeat right 4px center;
  background-color: ${({ theme }) => theme.toolkit.brand.background.primary};
  cursor: pointer;
`;

export const MonthAndYear = styled.span`
  flex: 1;
`;

export const PrevNextButton = styled.button<{ iconType: string; mode: string }>`
  position: relative;
  width: ${({ theme }) => theme.toolkit.spacing.S12};
  height: ${({ theme }) => theme.toolkit.spacing.S12};
  padding: 0;
  cursor: pointer;
  ${({ iconType, mode }) =>
    mode === 'datetime' &&
    (iconType === 'PREV' ? `margin-left: 12px;` : `margin-right: 12px;`)};

  ${({ iconType, mode }) =>
    (mode === 'date' || mode === 'month') &&
    (iconType === 'PREV' ? `margin-left: 27px;` : `margin-right: 27px;`)};

  ${({ iconType }) => `background: url(${base64prefix}${btoa(
    DateTimePickerIcons[iconType === 'PREV' ? 'ARROW_LEFT' : 'ARROW_RIGHT'],
  )})
    no-repeat center;`}
  background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
`;

export const Input = styled.input<{
  hasValue: boolean;
  hasError: boolean | undefined;
  disabled: boolean | undefined;
}>`
  font-family: 'Open Sans';
  display: block;
  width: 100%;
  height: ${({ theme }) => theme.toolkit.spacing.L48};
  padding: ${({ theme }) => `
    ${theme.toolkit.spacing.S12} 
    ${theme.toolkit.spacing.M32} 
    ${theme.toolkit.spacing.S12} 
    ${theme.toolkit.spacing.M20}
  `};
  border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
  outline: none;
  border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  appearance: none;
  background-color: ${({ theme }) => theme.toolkit.brand.background.primary};
  ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  text-align: left;
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:focus {
    border: ${({ theme }) => theme.toolkit.spacing.S2} solid
      ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    padding: ${({ theme }) => theme.toolkit.spacing.S12}
      ${({ theme }) => theme.toolkit.spacing.M32}
      ${({ theme }) => theme.toolkit.spacing.S12} 19px;
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
    color: ${({ theme }) => theme.toolkit.brand.grey?.BASE};
    background-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
  }

  ${({ hasError }) =>
    hasError &&
    `
    border: 1px solid #a30a0a !important;
    `};

  ${({ hasValue, theme }) =>
    !hasValue &&
    `
    background: url(${base64prefix}${btoa(
      DateTimePickerIcons['ARROW_DOWN'],
    )}) no-repeat right 13px center;
    background-color: ${theme.toolkit.brand.background.primary};
    `};
`;

export const InputIcon = styled.button`
  position: absolute;
  width: ${({ theme }) => theme.toolkit.spacing.M24};
  height: ${({ theme }) => theme.toolkit.spacing.M24};
  padding: 0;
  top: ${({ theme }) => theme.toolkit.spacing.S12};
  right: ${({ theme }) => theme.toolkit.spacing.S8};
  background: url(${base64prefix}${btoa(DateTimePickerIcons['CANCEL'])})
    no-repeat center;
`;

export const RangeLabel = styled.label`
  line-height: 28px;
  font-size: 14px;
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
`;
