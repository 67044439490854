import { ReactNode } from 'react';

export enum ImgPlacement {
  LEFT = 'LEFT',
  LEFTCENTER = 'LEFTCENTER',
  TOP = 'TOP',
}

export enum InfoTitleSize {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type InfoTitleProps = {
  heading?: ReactNode;
  badge?: ReactNode;
  subHeading?: ReactNode;
  button?: ReactNode;
  img?: ReactNode;
  imgPlacement?: ImgPlacement;
  metaLabel?: ReactNode;
  size?: InfoTitleSize;
};
