import { forwardRef } from 'react';
import { useInput } from './Input.hook';

import * as Styled from './Input.styled';
import { TInput } from './Inputs.types';

export const Input = forwardRef<HTMLInputElement, TInput>(
  (props: TInput, ref) => {
    const { leading, trailing, hasError, disabled, onFocus, onBlur, ...rest } =
      props;
    const { isFocussed, handleOnFocus, handleOnBlur } = useInput(
      onFocus,
      onBlur
    );

    return (
      <Styled.Container
        hasError={hasError}
        disabled={disabled}
        focussed={isFocussed}
      >
        {leading && <Styled.Leading>{leading}</Styled.Leading>}
        <Styled.Input
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        {trailing && <Styled.Trailing>{trailing}</Styled.Trailing>}
      </Styled.Container>
    );
  }
);

export default Input;
