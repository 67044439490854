import type { ReactNode } from 'react';
import {
  DefaultTheme as DefaultThemeStyledComponents,
  ThemeProvider as SCThemeProvider,
} from 'styled-components';
import { DefaultTheme } from '@dist-property-frontend/configs';

type Props = {
  children: ReactNode;
  theme: DefaultThemeStyledComponents;
};

const ThemeProvider = ({ children, theme }: Props) => {
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>;
};

export { DefaultTheme, ThemeProvider };
