import { Button, ButtonTypes } from '../../atoms';
import { ButtonGroupContainer } from './ButtonGroup.styled';
import * as types from './ButtonGroup.types';

export const ButtonGroup = ({
  width,
  buttons,
  buttonSizes,
  includesGhostButton = buttons
    .map((t) => t.buttonType ?? ButtonTypes.PRIMARY)
    .includes(ButtonTypes.GHOST), //Gap between buttons is larger if a GHOST button exists
  layout,
}: types.TButtonGroup) => {
  return (
    <ButtonGroupContainer
      width={width}
      buttonSizes={buttonSizes}
      includesGhostButton={includesGhostButton}
      layout={layout}
    >
      {buttons.map((buttonProps, index) => {
        return (
          Object.keys(buttonProps).length !== 0 && (
            <Button
              key={index}
              {...buttonProps}
              buttonSize={buttonSizes}
              fullWidth={width === types.Width.FILL}
              isButtonGroup
            />
          )
        );
      })}
    </ButtonGroupContainer>
  );
};
export default ButtonGroup;
