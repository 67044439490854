// relative Imports
import * as types from '../Modal.types';
import { ModalFooterContainer } from '../Modal.styled';

/**
 * Represents a footer component for a modal dialog. This component is used to render the footer part of a modal, where action buttons or custom content can be placed. It supports custom sizing, which can be specified through props to control the styling or layout of the footer within the modal.
 *
 * ## Example Usage
 *
 * ```jsx
 * <ModalFooter size="small">
 *   <Button onClick={handleClose}>Cancel</Button>
 *   <Button primary onClick={handleConfirm}>Confirm</Button>
 * </ModalFooter>
 * ```
 *
 * @param {types.IModalFooterProps} props - The properties passed to the ModalFooter component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the modal footer. Typically includes buttons or other interactive elements.
 * @param {types.Size} props.size - Optional. The size of the modal footer, which can be 'small', 'medium', or 'large', influencing its styling and layout.
 * @returns {JSX.Element} Renders a `ModalFooterContainer` which wraps the footer content in a styled div according to the specified size.
 */

const ModalFooter = (props: types.IModalFooterProps) => {
  const { children, size } = props;
  return <ModalFooterContainer size={size}>{children}</ModalFooterContainer>;
};

export default ModalFooter;
