import { PromoPanelProps } from './PromoPanel.types';
import * as Styled from './PromoPanel.styled';
import { InfoTitle } from '../../molecules';
import * as Atom from '../../atoms';

export const PromoPanel = ({
  heading,
  subHeading,
  buttonText,
  buttonType,
  imgPlacement,
  image,
  variant,
}: PromoPanelProps) => {
  return (
    <Styled.Wrapper variant={variant}>
      <InfoTitle
        imgPlacement={imgPlacement}
        img={image}
        heading={
          <Atom.Text
            as="p"
            size={Atom.TextSize.M16}
            fontWeight={Atom.TextFontWeight.SEMIBOLD}
          >
            {heading}
          </Atom.Text>
        }
        subHeading={
          <Atom.Text as="p" size={Atom.TextSize.S14}>
            {subHeading}
          </Atom.Text>
        }
      />
      <Atom.Button buttonSize={Atom.ButtonSizes.SMALL} buttonType={buttonType}>
        {buttonText}
      </Atom.Button>
    </Styled.Wrapper>
  );
};
