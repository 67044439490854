import React, { forwardRef } from 'react';
import * as Styled from './RadioButtonCard.styled';
import * as Types from './RadioButtonCard.types';
import * as LinkType from '../../atoms/link/Link.types';
import * as Atom from '../../atoms';
import AdViewBar from './AdViewBar';
import RadioBulletListTooltip from './RadioBulletListTooltip';
import RadioBulletListText from './RadioBulletListText';
import { useTheme } from 'styled-components';

export const RadioButtonCard = forwardRef<
  HTMLInputElement,
  Types.TRadioButtonCard
>((props: Types.TRadioButtonCard, ref) => {
  const {
    value,
    inputLabel,
    hasError = false,
    checked = false,
    disabled = false,
    recommendation = false,
    adViewBar,
    content,
    header,
    preview,
    onChange,
    callback,
    ...rest
  } = props;

  const theme = useTheme();
  const greyTheme = theme.toolkit.brand.grey;

  return (
    <Styled.RadioButtonWrapper
      checked={checked}
      disabled={disabled}
      recommendation={recommendation}
    >
      <Styled.Content>
        <div style={{ textAlign: 'left' }}>
          <Atom.Text
            as="h2"
            size={Atom.TextSize.S14}
            fontWeight={Atom.TextFontWeight.REGULAR}
            textColor={greyTheme?.DARKER}
          >
            {header.title}
          </Atom.Text>
        </div>
        {header.subTitle ? (
          <div style={{ textAlign: 'left' }}>
            <Atom.Text
              as="p"
              size={Atom.TextSize.M16}
              fontWeight={Atom.TextFontWeight.SEMIBOLD}
              textColor={greyTheme?.DARKER}
            >
              {header.subTitle}
            </Atom.Text>
          </div>
        ) : (
          <Atom.Space space={'M24'} />
        )}
        <Atom.Space space={'M16'} />
        <div style={{ textAlign: 'left' }}>
          <Atom.Text
            as="p"
            size={Atom.TextSize.S12}
            fontWeight={Atom.TextFontWeight.REGULAR}
            textColor={greyTheme?.DARKER}
          >
            Ad Views
          </Atom.Text>
        </div>
        <Atom.Space space={'S8'} />
        <AdViewBar adViewBar={adViewBar} />
        <Atom.Space space={'M24'} />
        <Styled.BulletList>
          {Object.entries(content).map(([key, value]) => {
            return (
              <Styled.ListItem key={key}>
                {value && value['tooltip']
                  ? RadioBulletListTooltip(key, value)
                  : RadioBulletListText(key, value)}
              </Styled.ListItem>
            );
          })}
        </Styled.BulletList>
        <Atom.Space space={'M16'} />
      </Styled.Content>
      <Styled.Divider />
      <Styled.RadioIndicator>
        {preview && (
          <Atom.Link
            type={LinkType.Type.STANDALONE}
            size={LinkType.Size.MEDIUM}
            style={LinkType.Style.SECONDARY}
            weight={LinkType.Weight.SEMIBOLD}
            underlined={true}
            onClick={callback}
          >
            Preview
          </Atom.Link>
        )}
        <Styled.RadioContainer>
          <Styled.StyledRadioButton
            type={'radio'}
            hasError={hasError}
            disabled={disabled}
            checked={checked}
            ref={ref}
            {...rest}
            value={value}
            onChange={onChange}
          />
          <Styled.RadioButtonLabel>
            <Atom.Text
              variant={'span'}
              size={Atom.TextSize.S14}
              fontWeight={Atom.TextFontWeight.REGULAR}
              textColor={disabled ? greyTheme?.BASE : greyTheme?.DARKER}
            >
              {inputLabel}
            </Atom.Text>
          </Styled.RadioButtonLabel>
        </Styled.RadioContainer>
      </Styled.RadioIndicator>
    </Styled.RadioButtonWrapper>
  );
});
