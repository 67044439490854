import { CardContentRowWrapper } from './CardContent.styled';
import { CardContentRowProps } from './CardContent.types';

export function CardContentRow({
  startColumn = 1,
  spanColumns = 12,
  extraPaddingTop,
  maxWrappedLines,
  verticalAlignment,
  children,
  dataTracking,
}: CardContentRowProps) {
  return (
    <CardContentRowWrapper
      startColumn={startColumn}
      spanColumns={spanColumns}
      extraPaddingTop={extraPaddingTop}
      maxWrappedLines={maxWrappedLines}
      verticalAlignment={verticalAlignment}
      {...(dataTracking && {
        'data-tracking': dataTracking,
      })}
    >
      {children}
    </CardContentRowWrapper>
  );
}
