import styled from 'styled-components';
import * as Types from './Tabs.types';
import { TabButtonStyle } from '../../atoms/tabButton/TabButton.types';
import * as TabStyles from '../tabs/Tabs.types';

export const TabsFullPageWrapper = styled.div<Types.ITabStyle>`
  ${({ fullPage }) =>
    fullPage &&
    `
    height:32px;
  `}
`;

export const TabsWrapper = styled.div<Types.ITabStyle>`
  margin: ${({ center }) => (center ? 'auto' : '')};
  width: ${({ layout }) => {
    switch (layout) {
      case TabStyles.ETabLayout.SPACE_BETWEEN:
        return '100%';
      case TabStyles.ETabLayout.FILL:
        return '100%';
      // TabStyles.ETabLayout.FIXED:
      default:
        return 'fit-content';
    }
  }};
  border-bottom: ${({ underlined }) =>
    underlined ? '0.0625rem solid' : 'none'};
  border-color: ${({ theme, tabStyle }) =>
    tabStyle === TabButtonStyle.DARK
      ? `${theme.toolkit.brand.grey?.LIGHTER}`
      : `${theme.toolkit.brand.primary.TEXTCONTRAST}`};
  ${({ fullPage }) =>
    fullPage &&
    `
    left: 0;
    position: absolute;
  `}
`;

export const TabsContainer = styled.div<Types.ITabStyle>`
  display: flex;

  // fixed
  ${({ theme, layout }) =>
    layout === TabStyles.ETabLayout.FIXED &&
    `
    gap: ${theme.toolkit.spacing.S8};
    justify-content: flex-start;
    width: fit-content;
    `};
  // filled space between
  ${({ layout }) =>
    layout === TabStyles.ETabLayout.FILL &&
    `
    width: 100%;
  `};
  // space between
  ${({ layout }) =>
    layout === TabStyles.ETabLayout.SPACE_BETWEEN &&
    `
    justify-content: space-between;
    width: 100%;
    `};

  margin: ${({ center }) => (center ? 'auto' : '')};
  max-width: 100%;
  overflow-x: auto; // Allow tabs to scroll horizontally when they don't fit on screen
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ fullPage, theme }) =>
    fullPage &&
    `
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 74.5rem;
    text-align: -webkit-center;

    @media only screen and (${theme.toolkit.mediaQuery.smallOnly}) {
      padding: 0 ${theme.spacing.M16};
    }

    @media only screen and (${theme.toolkit.mediaQuery.medium}) {
      padding: 0 ${theme.spacing.M32};
    }
  `}

  ::-webkit-scrollbar {
    display: none;
  }

  // Remove horizontal scrollbar only on touch screen devices
  @media (hover: none) {
    ::-webkit-scrollbar {
      height: 0;
      background-color: transparent;
    }
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
  }
`;

// General styling for the tab content.
// More detailed styling is handled by the component passed in as the 'content' prop
export const TabsContent = styled.div<{ overflow?: number }>`
  // padding handled by content props
  max-width: 100%;
  overflow-y: auto;
  // small screen behaviors
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    ${({ overflow }) => overflow && `max-height: ${640 - overflow}px`}
  }
`;
