export type BadgeProps = {
  children: JSX.Element | string;
  badgeStyle?: BadgeStyle;
  badgeColor?: BadgeColor;
  badgeSize?: BadgeSize;
  testId?: string;
  icon?: JSX.Element; 
  iconAlignment?: 'left' | 'right';
};
export type BadgeStyleProps = {
  badgeStyle?: BadgeStyle;
  badgeColor?: BadgeColor;
  badgeSize?: BadgeSize;
  icon?: JSX.Element;
  iconAlignment?: 'left' | 'right';
};

export enum BadgeStyle {
  OVERLAY = 'OVERLAY',
  OUTLINED = 'OUTLINED',
  FILLED_DARK = 'FILLED_DARK',
  FILLED_LIGHT = 'FILLED_LIGHT',
}

export enum BadgeColor {
  RED = 'RED',
  GREY_DARKER = 'GREY_DARKER',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  GREY_DARK = 'GREY_DARK',
}

export enum BadgeSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
