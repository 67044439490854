import { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  CardContainer,
  CardContainerCornerRadius,
  MaskedContainer,
  MaskedContainerSize,
  MaskedContainerShape,
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
} from '../../../atoms';
import { CustomCardGallery } from '../CardGallery/CustomCardGallery';
import { CustomCardBanner } from '../CardBanner/CustomCardBanner';
import { CustomCardContent } from '../cardContent/CustomCardContent';
import Card from '../../../organisms/card/Card';
import { CardLayout } from '../../../organisms';
import { renderSRPGalleryImages } from '../../gallerySRPHelper';
import { GalleryImageProps } from '../../../molecules';

enum AdFeatureLevelFull {
  STANDARD = 'standard',
  FEATURED = 'featured',
  PREMIUM = 'premium',
  PREMIER_PARTNER_LITE = 'advantageLite',
  PREMIER_PARTNER_PREMIUM = 'advantagePremium',
  PREMIER_PARTNER_FEATURED = 'advantageFeatured',
}

const prepareLayout = (type: AdFeatureLevelFull): CardLayout => {
  const isVerticalLayout = [
    AdFeatureLevelFull.FEATURED,
    AdFeatureLevelFull.PREMIER_PARTNER_FEATURED,
  ].includes(type);

  return isVerticalLayout
    ? CardLayout.VerticalDesktop
    : CardLayout.HorizontalDesktop;
};

const CardWrapper = styled.div`
  width: 680px;

  @media only screen and (max-width: 680px) {
    max-width: 100%;
  }
`;

export const SRPCard = ({
  adFeatureLevel,
  mockCardData,
  galleryImagesCollection,
}: {
  adFeatureLevel: any;
  mockCardData: any;
  galleryImagesCollection: any;
}) => {
  const layout = prepareLayout(adFeatureLevel as AdFeatureLevelFull);

  const selectedMockData = mockCardData[adFeatureLevel as AdFeatureLevelFull];

  const cardContentLayoutData = {
    bottomSlotContent: selectedMockData?.bottomSlotContent,
    hasBottomSlot: selectedMockData?.hasBottomSlot,
    cardContentPaddingX: '16px',
    cardContentPaddingY: '16px',
  };

  const [galleryIndex, setGalleryIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState<GalleryImageProps[]>(
    galleryImagesCollection,
  );
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    setGalleryIndex(0);
    setGalleryImages(galleryImagesCollection);
  }, []);
  const imagesList = galleryImages.map((image, index) => ({
    src: image.src ?? '',
    alt: image.alt ?? 'image',
    loading: index === 0 ? 'eager' : 'lazy',
  }));

  const RenderedImage = renderSRPGalleryImages({
    images:
      adFeatureLevel === AdFeatureLevelFull.STANDARD
        ? [imagesList[0]]
        : imagesList,

    totalImages:
      adFeatureLevel === AdFeatureLevelFull.STANDARD ? 1 : imagesList.length,
  });

  const galleryProps = {
    props: {
      ...selectedMockData?.cardGalleryProps,
      galleryType: selectedMockData?.cardGalleryProps.galleryType,
      index: galleryIndex,
      images: galleryImages,
      imageGridImages: galleryImages,
      slots: {
        bottomRight: selectedMockData?.cardGalleryProps.squareLogo ? (
          <MaskedContainer
            imageAlt="square Logo"
            image={selectedMockData?.cardGalleryProps.squareLogo}
            size={MaskedContainerSize.S40}
            shape={MaskedContainerShape.SQUARE}
          />
        ) : null,
      },
      setIndex: setGalleryIndex,
      onChange: (index: number) => setGalleryIndex(index),
      children: RenderedImage,
      hasIndicator: Boolean(adFeatureLevel !== AdFeatureLevelFull.STANDARD),
      hoverInteraction: true,
      hasSlideAnimation: true,
    },
  };

  const bannerProps = selectedMockData?.cardBannerProps
    ? { props: selectedMockData?.cardBannerProps }
    : null;

  const contentProps = {
    props: {
      ...selectedMockData?.cardContentProps,
      titleTagline: selectedMockData?.cardContentProps.titleTagline,
      titlePrice: selectedMockData?.cardContentProps.titlePrice,
      subtitle: selectedMockData?.cardContentProps.subtitle,
      topRightSlot: (
        <Button
          buttonSize={ButtonSizes.SMALL}
          buttonType={ButtonTypes.GHOST}
          Icon={<Icon icon={isSaved ? 'HEARTFILLED' : 'HEART'} size={32} />}
          onClick={() => setIsSaved(!isSaved)}
        />
      ),
    },
  };

  return (
    <CardWrapper>
      <CardContainer cornerRadius={CardContainerCornerRadius.ON}>
        <Card
          layout={layout}
          cardContentLayout={cardContentLayoutData}
          cardGallery={<CustomCardGallery {...galleryProps} />}
          cardBanner={
            bannerProps ? <CustomCardBanner {...bannerProps} /> : null
          }
          cardContent={<CustomCardContent {...contentProps} />}
        />
      </CardContainer>
    </CardWrapper>
  );
};
