import styled from 'styled-components';

export const ListBox = styled.ul`
  width: 100%;
  background-color: white;
  border: 0.13125rem solid ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  border-bottom-right-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  border-bottom-left-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  border-top: 0;
  position: absolute;
  top: 2.75rem;
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
  max-height: 14rem;
  overflow: overlay;
  z-index: 10;
  &::-webkit-scrollbar {
    width: 0.8125rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0.625rem;
    width: ${({ theme }) => theme.toolkit.spacing.S8};
    border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  }

  ::-webkit-scrollbar-thumb {
    transition: 0.25s ease-in-out;
    border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
    background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
    border: 0.3125rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    height: 30%;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
    background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  }
  li {
    ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
    align-items: center;
    display: flex;
    min-height: ${({ theme }) => theme.toolkit.spacing.L48};
    padding: ${({ theme }) =>
      `${theme.toolkit.spacing.S12} ${theme.toolkit.spacing.M20}`};
  }
  &:before {
    display: block;
    content: '';
    background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
    height: 1px;
    width: 100%;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    bottom: 10px;
  }
`;

export const ListBoxItem = styled.li`
  ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
  align-items: center;
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  display: flex;
  min-height: 3rem;
  padding: ${({ theme }) =>
    `${theme.toolkit.spacing.S12} ${theme.toolkit.spacing.M20}`};
  transition: 0.25s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  button {
    background: transparent;
    padding: 0;
    text-align: left;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Loader = styled.div`
  height: ${({ theme }) => theme.toolkit.spacing.L48};
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;
