import React from 'react';
import * as Atom from '../../../atoms';
import Card from '../../../organisms/card/Card';
import { CardContent, CardLayout } from '../../../organisms';

type RemainingSubunitsCardProps = {
  remainingSubunits: number;
  dataTracking?: string;
};

export const RemainingSubunitsCard: React.FC<RemainingSubunitsCardProps> = ({
  remainingSubunits,
  dataTracking,
}) => {
  return (
    <div style={{ borderTop: '1px solid #dddddd' }}>
      <Card
        layout={CardLayout.VerticalDesktop}
        cardContentLayout={{
          cardContentPaddingX: '16px',
          cardContentPaddingY: '12px',
        }}
        cardContent={
          <CardContent.Row
            startColumn={1}
            spanColumns={12}
            dataTracking={dataTracking}
          >
            <Atom.Text as="p" size={Atom.TextSize.S12} textColor="#717171">
              {remainingSubunits} more Property Type
              {remainingSubunits > 1 ? 's' : ''} in this Development
            </Atom.Text>
          </CardContent.Row>
        }
      />
    </div>
  );
};
