import { CardContentBottomSlot } from './CardContentBottomSlot';
import { CardContentTopRightSlot } from './CardContentTopRightSlot';
import { CardContentRoot } from './CardContentRoot';
import { CardContentRow } from './CardContentRow';

export const CardContent = {
  Root: CardContentRoot,
  CardContentTopRightSlot: CardContentTopRightSlot,
  BottomSlot: CardContentBottomSlot,
  Row: CardContentRow,
};
