import styled, { css } from 'styled-components';
import { ImgPlacement, InfoTitleSize } from './InfoTitle.types';

export type InfoTitleStyleProps = {
  imgPlacement?: ImgPlacement;
  size: InfoTitleSize;
};

export const Wrapper = styled.div<InfoTitleStyleProps>`
  display: flex;
  flex-direction: ${({ imgPlacement }) =>
    imgPlacement === 'LEFT' || imgPlacement === 'LEFTCENTER'
      ? 'row'
      : 'column'};
  ${({ imgPlacement, size }) =>
    (imgPlacement === 'LEFT' ||
      imgPlacement === 'LEFTCENTER' ||
      imgPlacement === 'TOP') &&
    css`
      gap: ${({ theme }) =>
        size === 'MEDIUM' || size === 'LARGE'
          ? theme.toolkit.spacing.M16
          : theme.toolkit.spacing.S12};
    `};
  ${({ imgPlacement }) =>
    imgPlacement === 'LEFTCENTER' &&
    css`
      align-items: center;
    `};
  img,
  svg {
    ${({ imgPlacement }) =>
      (imgPlacement === 'LEFT' || imgPlacement === 'LEFTCENTER') &&
      css`
        width: inherit;
        height: inherit;
      `};
  }
`;

export const ImgWrapper = styled.div`
  margin: 0;
`;

export const Content = styled.div<{ size: InfoTitleSize }>`
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: ${({ theme, size }) =>
      size === 'MEDIUM' || size === 'LARGE'
        ? theme.toolkit.spacing.S8
        : theme.toolkit.spacing.S4};
  }
  p {
    margin-bottom: ${({ theme, size }) =>
      size === 'MEDIUM' || size === 'LARGE'
        ? theme.toolkit.spacing.S8
        : theme.toolkit.spacing.S4};
  }
  div:last-child,
  p:last-child,
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  span:last-child {
    margin-bottom: 0;
  }
`;

export const ButtonWrapper = styled.div<{ size: InfoTitleSize }>`
  margin-top: ${({ theme, size }) =>
    size === 'MEDIUM' || size === 'LARGE'
      ? theme.toolkit.spacing.M16
      : theme.toolkit.spacing.S12};
  display: block;
`;
