import styled from 'styled-components';

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  line-height: 2.1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledP = styled.p`
  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.SEMIBOLD};
  ${({ theme }) => theme.toolkit.typography.fontSizes.S14}
`;

export const StyledDiv = styled.div`
  display: flex;
`;

export const StyledSpan = styled.span`
  font-size: ${({ theme }) => theme.toolkit.spacing.M16};
  margin: 0 ${({ theme }) => theme.toolkit.spacing.S8};
  width: ${({ theme }) => theme.toolkit.spacing.M32};
  text-align: center;
`;
