import styled from 'styled-components';

export const ResponsiveTitle = styled.p`
  ${({ theme }) => theme.toolkit.typography.fontSizes.S12};
  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.SEMIBOLD};
  max-width: 100%;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
  }
`;

export const ResponsiveSubtitle = styled.p`
  ${({ theme }) => theme.toolkit.typography.fontSizes.S10};
  max-width: 100%;

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    ${({ theme }) => theme.toolkit.typography.fontSizes.S12};
  }
`;

export const ResponsiveLogo = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
