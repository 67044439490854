import { InputGroupWrapper } from './InputGroup.styled';
import * as Types from './InputGroup.types';

export const InputGroup = ({
  maxColumns = 4,
  spacing = 8,
  oneColumnOnMobile,
  children,
}: Types.TInputGroup) => {
  return (
    <InputGroupWrapper
      maxColumns={maxColumns}
      spacing={spacing}
      oneColumnOnMobile={oneColumnOnMobile}
    >
      {children}
    </InputGroupWrapper>
  );
};

export default InputGroup;
