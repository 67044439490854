import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
`;

export const TextIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextWrapper = styled.div<{
  size: 'medium' | 'small';
  closeIcon: boolean;
  image: JSX.Element | undefined;
}>`
  display: flex;
  ${({ image, size }) =>
    image && (size === 'medium' ? 'margin-left: 16px;' : 'margin-left: 12px;')};
  flex-direction: column;
  align-items: flex-start;
  ${({ theme, closeIcon }) => closeIcon && `margin-right: ${theme.toolkit.spacing.S8}`};
`;

export const HeaderWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.toolkit.spacing.S4};
`;

export const ClosedIcon = styled.button`
  color: ${({ theme }) => theme?.toolkit?.brand?.grey?.DARK};
  display: flex;
  margin: 0 0 0 auto;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const Image = styled.div`
  height: 100%;
`;

/* Absolute positioning */
export const Tip = styled.div<{
  size: 'medium' | 'small';
  spanWidth: number;
  spanHeight: number;
  spanLeft: number;
  width: number;
  toolTipWidth: number;
}>`
  --tooltip-arrow-size: ${({ theme }) => theme.toolkit.spacing.S8};
  --tooltip-width-web: ${({ toolTipWidth }) => `${toolTipWidth}px`};
  // add mobile width
  --tooltip-width-mobile: 288px;
  --tooltip-gap: ${({ theme }) => theme.toolkit.spacing.S4};

  position: absolute;
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  left: ${({ spanWidth }) => `${spanWidth / 2}px`};
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  background: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
  ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
  padding: ${({ theme, size }) =>
    size === 'medium' ? theme.toolkit.spacing.M16 : theme.toolkit.spacing.S12};
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.2);
  width: var(--tooltip-width-web);
  z-index: 10;
  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.xsmallOnly}) {
    width: var(--tooltip-width-mobile);
  }

  /* CSS border triangles */
  &::before {
    content: ' ';
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: var(--tooltip-arrow-size);
  }

  /* default design */
  &.bottom-start {
    top: ${({ spanHeight }) =>
      `calc(${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap))`};
    transform: ${({ spanWidth }) => `translateX(-${spanWidth / 2}px)`};
  }

  &.bottom-start::before {
    bottom: 100%;
    left: ${({ spanWidth }) =>
      `calc(${spanWidth / 2}px - var(--tooltip-arrow-size))`};
    border-bottom-color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
  }

  &.bottom-center {
    top: ${({ spanHeight }) =>
      `calc(${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap))`};
    transform: translateX(-50%);
  }

  &.bottom-center::before {
    bottom: 100%;
    left: calc(var(--tooltip-width-web) / 2 - var(--tooltip-arrow-size));
    border-bottom-color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
    @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.xsmallOnly}) {
      left: calc(var(--tooltip-width-mobile) / 2 - var(--tooltip-arrow-size));
    }
  }

  &.bottom-end {
    top: ${({ spanHeight }) =>
      `calc(${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap))`};
    transform: ${({ spanWidth }) =>
      `translateX(calc(var(--tooltip-width-web) * -1 + ${spanWidth / 2}px))`};
    @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.xsmallOnly}) {
      transform: ${({ spanWidth }) =>
        `translateX(calc(var(--tooltip-width-mobile) * -1 + ${
          spanWidth / 2
        }px))`};
    }
  }

  &.bottom-end::before {
    bottom: 100%;
    right: ${({ spanWidth }) =>
      `calc(${spanWidth / 2}px - var(--tooltip-arrow-size))`};
    border-bottom-color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
  }

  &.bottom {
    top: ${({ spanHeight }) =>
      `calc(${spanHeight}px + var(--tooltip-arrow-size) + var(--tooltip-gap))`};
    transform: ${({ spanLeft, width }) =>
      `translateX(calc(-${spanLeft}px + (${width}px - var(--tooltip-width-mobile)) / 2))`};
  }
  &.bottom::before {
    bottom: 100%;
    left: ${({ spanLeft, width }) =>
      `calc(${spanLeft}px - (${width}px - var(--tooltip-width-mobile)) / 2)`};
    border-bottom-color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
  }
`;
