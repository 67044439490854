import { Logos } from './Logos';

import { LogoProps } from './Logo.types';
import { LogoWrapper } from './Logo.styled';

export const Logo = ({ name, width, height }: LogoProps) => {
  return (
    <LogoWrapper width={width} height={height}>
      {Logos[name]}
    </LogoWrapper>
  );
};
