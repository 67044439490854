import styled from 'styled-components';

export const IconWrapper = styled.div<{ height?: 'SMALL' | 'MEDIUM' }>`
  width: fit-content;
  display: inline-flex;
  justify-content: flex-start;
  svg {
    width: auto;
    height: ${({ height, theme }) =>
      height === 'SMALL'
        ? theme.toolkit.spacing.M20
        : theme.toolkit.spacing.M24};
  }
`;
