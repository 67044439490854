import styled from 'styled-components';
import { IllustrationStyleProps } from './Illustration.types';

export const IllustrationWrapper = styled.div<IllustrationStyleProps>`
  svg {
    display: block;
    height: ${({ height }) => `${height}px`};
    width: auto;
  }
`;
