export const MapIcons = {
  MAPPIN: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        className="map-pin"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0034 1.3335C9.73144 1.342 4.64913 6.42431 4.64062 12.6963C4.64062 22.2219 12.6687 29.0303 15.1388 30.4352C15.6738 30.744 16.333 30.744 16.868 30.4352C19.3381 29.0457 27.3662 22.2219 27.3662 12.6963C27.3577 6.42431 22.2754 1.342 16.0034 1.3335Z"
        fill="#4170C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 8.62061C18.2462 8.62061 20.0669 10.4413 20.0669 12.6873C20.0669 14.9332 18.2462 16.7539 16.0003 16.7539C13.7543 16.7539 11.9336 14.9332 11.9336 12.6873C11.9336 10.4413 13.7543 8.62061 16.0003 8.62061Z"
        fill="white"
      />
    </svg>
  ),
  MAPPIN_BIG: (
    <svg width="140" height="140" viewBox="0 0 140 140" fill="none">
      <circle cx="70" cy="70" r="70" fill="#4170C4" fillOpacity="0.24" />
      <ellipse
        cx="70"
        cy="92"
        rx="12"
        ry="4"
        fill="#4170C4"
        fillOpacity="0.24"
      />
    </svg>
  ),
};
