import { BER_ICONS } from './BerIconsList';
import { IconWrapper } from './BerIcon.styled';
import { BER_RATING } from '@dist-property-frontend/types';

export function BerIcon({
  rating,
  height = 'SMALL',
  ...props
}: {
  rating: BER_RATING;
  height?: 'SMALL' | 'MEDIUM';
}) {
  return (
    <IconWrapper
      aria-hidden={true}
      aria-label={`BER ${rating}`}
      height={height}
      {...props}
    >
      {BER_ICONS[rating]}
    </IconWrapper>
  );
}

export default BerIcon;
