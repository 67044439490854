import { type FocusEvent, useState } from 'react';

export const useInput = (
  onFocus?: React.FocusEventHandler,
  onBlur?: React.FocusEventHandler
) => {
  const [isFocussed, setIsFocussed] = useState(false);

  const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocussed(true);
    !!onFocus && onFocus(event);
  };
  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocussed(false);
    !!onBlur && onBlur(event);
  };
  return {
    isFocussed,
    handleOnFocus,
    handleOnBlur,
  };
};
