import styled from 'styled-components';

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto ${({ theme }) => theme.toolkit.spacing.S12};
`;

export const Checkbox = styled.span<{
  hasError: boolean;
  disabled: boolean;
}>`
  position: relative;
  width: ${({ theme }) => theme.toolkit.spacing.M20};
  height: ${({ theme }) => theme.toolkit.spacing.M20};
  display: inline-block;
  align-self: center;
  background: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
  border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.BASE};
  border-radius: ${({ theme }) => theme.toolkit.spacing.S2};
  box-shadow: none;
  transition: all 0.2s;
  &:after {
    position: absolute;
    top: 1px;
    left: 6px;
    width: ${({ theme }) => theme.toolkit.spacing.S8};
    height: 14px;
    border-color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
    border-style: solid;
    border-width: 0 ${({ theme }) => theme.toolkit.spacing.S2}
      ${({ theme }) => theme.toolkit.spacing.S2} 0;
    transform: rotate(45deg);
    transition: all 0.2s ease-out;
    opacity: 0;
    pointer-events: none;
    content: '';
  }
  ${({ hasError, theme }) =>
    hasError &&
    `border: 1px solid ${theme.toolkit.brand.error.DARK};
    `};
  ${({ disabled, theme }) =>
    disabled &&
    `border: 1px solid ${theme.toolkit.brand.grey?.LIGHTER};
     background: ${theme.toolkit.brand.grey?.LIGHTEST};
    `};
`;

export const CheckboxWrapper = styled.div<{ variant: string }>`
  position: relative;
  display: flex;
  input {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  input:focus:not(:focus-visible) ~ ${Checkbox} {
    outline: none;
  }

  input:focus:focus-visible ~ ${Checkbox} {
    border-color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
    box-shadow: ${({ theme }) =>
      `0 0 0 ${theme.toolkit.spacing.S4} ${theme.toolkit.brand.grey?.LIGHT}`};
    outline: none;
  }

  input:checked ~ ${Checkbox} {
    background: ${({ theme }) => theme.toolkit.brand.primary.BASE};
    border: none;
    box-shadow: none;
    &:after {
      opacity: 1;
    }
  }
`;

export const IconWrapper = styled.div`
  width: ${({ theme }) => theme.toolkit.spacing.M24};
  height: ${({ theme }) => theme.toolkit.spacing.M24};
  margin: auto 0 auto auto;
`;

export const LabelWrapper = styled.div`
  margin: auto 0;
`;

export const SubtextWrapper = styled.div<{ subtext?: string }>`
  ${({ subtext, theme }) => subtext && `padding-top: ${theme.toolkit.spacing.S4};`}
`;
