import { CardLayout } from '../../Card.types';
import { CardContent } from '.';
import * as Styled from './CardContent.styled';
import { CardContentRootProps } from './CardContent.types';

export function CardContentRoot({
  cardContentLayout,
  layout,
  children,
  dataTracking,
}: CardContentRootProps) {
  return (
    <Styled.CardContentWrapper
      cardContentPaddingY={cardContentLayout?.cardContentPaddingY}
      cardContentPaddingX={cardContentLayout?.cardContentPaddingX}
    >
      {layout === CardLayout.HorizontalDesktop && (
        <>
          <Styled.TopCardContent>{children}</Styled.TopCardContent>
          {cardContentLayout?.hasBottomSlot && (
            <Styled.BottomCardContent
              {...(dataTracking && {
                'data-tracking': dataTracking,
              })}
            >
              <CardContent.BottomSlot
                cardContentLayout={cardContentLayout}
                layout={CardLayout.HorizontalDesktop}
              />
            </Styled.BottomCardContent>
          )}
        </>
      )}
      {layout === CardLayout.VerticalDesktop && (
        <>
          {children}
          {cardContentLayout?.hasBottomSlot && (
            <CardContent.BottomSlot
              cardContentLayout={cardContentLayout}
              layout={CardLayout.VerticalDesktop}
            />
          )}
        </>
      )}
    </Styled.CardContentWrapper>
  );
}

export default CardContentRoot;
