import { forwardRef } from 'react';
import * as Types from './TabButton.types';
import * as Styled from './TabButton.styled';

export const TabButton = forwardRef<HTMLButtonElement, Types.TabButtonProps>(
  (props: Types.TabButtonProps, ref) => {
    const {
      TabButtonSize = Types.TabButtonSizes.MEDIUM,
      TabButtonStyle = Types.TabButtonStyle.DARK,
      children,
      testId,
      isActive,
      handleClick,
      isDisabled = false,
      layout,
    } = props;
    return (
      <Styled.TabButton
        ref={ref}
        layout={layout}
        tabIndex={0} /* tabindex is to allow focus state */
        TabButtonSize={TabButtonSize}
        TabButtonStyle={TabButtonStyle}
        data-testid={`${testId}`}
        isActive={isActive}
        onClick={handleClick}
        disabled={isDisabled}
      >
        {' '}
        {children}
      </Styled.TabButton>
    );
  },
);
