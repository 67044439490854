import type { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import { IconName } from '../../atoms';

export enum RoundButtonSizes {
  X_SMALL = 'X_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export enum RoundButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  OVERLAY = 'OVERLAY',
}

export type TRoundButton = {
  buttonType?: RoundButtonTypes;
  buttonSize?: RoundButtonSizes;
  iconType: IconName;
  isDisabled?: boolean | undefined;
  hasError?: boolean | undefined;
  onClick: MouseEventHandler<HTMLButtonElement>;
} & ButtonHTMLAttributes<HTMLButtonElement>;
