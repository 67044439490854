export const DateTimePickerIcons: {
  [key: string]: string;
} = {
  ARROW_LEFT:
    "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' fill='none' stroke='#333333' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'><polyline fill='none' points='65,10 25,50 65,90'/></svg>",
  ARROW_RIGHT:
    "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' fill='none' stroke='#333333' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'><polyline fill='none' points='35,10 75,50 35,90'/></svg>",
  ARROW_DOWN:
    "<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 100 100' fill='none' stroke='#717171' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'><polyline fill='none' points='10,35 50,75 90,35'/></svg>",
  CANCEL:
    "<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 100 100' fill='none' stroke='#717171' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'><line x1='15' y1='15' x2='85' y2='85' /> <line x1='85' y1='15' x2='15' y2='85' /></svg>",
};
