import styled from 'styled-components';
import { StyleProps } from './Text.types';

export const Wrapper = styled.span<StyleProps>`
  color: ${({ textColor }) => (textColor ? textColor : 'inherit')};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight
      ? theme.toolkit.typography.fontWeights[
          fontWeight as keyof typeof theme.toolkit.typography.fontWeights
        ]
      : 'inherit'};
  ${({ size, theme }) =>
    size &&
    theme.toolkit.typography.fontSizes[size as keyof typeof theme.toolkit.typography.fontSizes]}
`;
