import { useState, useEffect, useRef } from 'react';
import { TTabHook } from './Tabs.types';

export const useTabs = (props: TTabHook) => {
  const { currentTab = 0, setCurrentTab } = props;
  const changeTab = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setCurrentTab(index);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const activeTabRef = useRef<HTMLButtonElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    const container = containerRef.current;
    if (!container) return;
    setIsDragging(true);
    setStartX(e.pageX);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const container = containerRef.current;
    if (!container || !isDragging) return;
    const distanceX = e.pageX - startX;
    containerRef.current.scrollLeft = scrollLeft - distanceX;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseWheel = (e: React.WheelEvent) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = e.deltaX ? e.deltaX : e.deltaY;
      containerRef.current.scrollLeft += scrollAmount;
    }
  };

  useEffect(() => {
    if (containerRef.current && activeTabRef.current) {
      const container = containerRef.current;
      const activeTab = activeTabRef.current;

      const containerWidth = container.offsetWidth;
      const activeTabWidth = activeTab.offsetWidth;
      const activeTabOffset = activeTab.offsetLeft;
      const scrollTo = activeTabOffset - (containerWidth - activeTabWidth) / 2;

      animateScrollTo(container, scrollTo, 300);
    }
  }, [currentTab]);

  const animateScrollTo = (
    element: HTMLDivElement,
    to: number,
    duration: number
  ) => {
    const start = element.scrollLeft;
    const change = to - start;
    let currentTime = 0;
    const increment = 10;

    const easeOutCubic = (t: number) => 1 - Math.pow(1 - t, 3);

    const animateScroll = () => {
      currentTime += increment;
      const progress = currentTime / duration;
      const easedProgress = easeOutCubic(progress);
      const val = start + change * easedProgress;
      element.scrollLeft = val;

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  };
  return {
    changeTab,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleMouseWheel,
    setCurrentTab,
    containerRef,
    activeTabRef,
    currentTab,
  };
};
