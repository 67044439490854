import styled, { css } from 'styled-components';
import {
  CardContentLayoutProps,
  CardContentRowProps,
} from './CardContent.types';
import { CardLayout } from '../../Card.types';

export const CardContentWrapper = styled.div<CardContentLayoutProps>`
  display: grid;
  padding: ${(props) =>
    `${props.cardContentPaddingY || '0'} ${props.cardContentPaddingX || '0'}`};
  grid-template-columns: repeat(autofill, minmax(25px, 55px));
  min-height: 100%;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.toolkit.spacing.S8};
`;

export const TopCardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-content: start;
  grid-gap: ${({ theme }) => theme.toolkit.spacing.S8};
`;

export const BottomCardContent = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-content: end;
  height: 100%;
`;

export const CardContentRowWrapper = styled.div<CardContentRowProps>`
  grid-column: ${(props) => `${props.startColumn} / span ${props.spanColumns}`};
  padding-top: ${(props) =>
    props.extraPaddingTop ? `${props.extraPaddingTop}px` : '0'};
  align-self: ${(props) =>
    props.verticalAlignment ? `${props.verticalAlignment}` : 'start'};
  /* fallback styles for browsers that not support -webkit-box or -webkit-line-clamp */
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* styles for browsers that support -webkit-box or -webkit-line-clamp */
  ${(props) =>
    props.maxWrappedLines &&
    css`
      @supports (
        (display: -webkit-box) and
          (-webkit-line-clamp: ${props.maxWrappedLines})
      ) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${props.maxWrappedLines};
        white-space: normal;
      }
    `}
`;

export const CardContentTopSlot = styled.div`
  grid-column: 12 / span 1;
  grid-row: 1 / span 2;
  align-self: start;
  justify-self: end;
`;

export const CardContentBottomSlotWrapper = styled.div<{
  layout: CardLayout.VerticalDesktop | CardLayout.HorizontalDesktop;
}>`
  grid-column: 9 / span 4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: end;
  align-self: end;
  max-width: 104px;
  justify-self: end;
  color: ${({ theme }) => theme.toolkit.brand.grey?.BASE};
  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.SEMIBOLD};
  font-size: ${({ theme }) => theme.toolkit.typography.fontSizes.S10};

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    max-width: ${(props) =>
      props.layout === CardLayout.HorizontalDesktop && '100%'};
    grid-column: ${(props) =>
      props.layout === CardLayout.VerticalDesktop
        ? '11 / span 2'
        : '7 / span 6'};
  }
`;
