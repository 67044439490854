import styled, { css } from 'styled-components';
import { TLabelStyled } from './Label.types';

export const RequiredIndicator = styled.span`
  margin-left: 1px;
`;

export const SecondaryText = styled.span<TLabelStyled>`
  color: ${({ theme, secondaryColor }) =>
    secondaryColor ? secondaryColor : theme.toolkit.brand.grey?.DARK};
  margin-left: ${({ theme, size }) =>
    size === 'S12' ? theme.toolkit.spacing.S4 : theme.toolkit.spacing.S8};
  ${({ vertical, size, theme }) =>
    vertical &&
    css`
      display: block;
      margin-left: 0;
      ${size === 'S12' ? theme.toolkit.spacing.S4 : theme.toolkit.spacing.S8}
    `};
`;

export const LabelText = styled.span<TLabelStyled>`
  ${({ labelColor }) => labelColor && `color: ${labelColor}`};
  font-weight: ${({ weight, theme }) =>
    theme.toolkit.typography.fontWeights[weight || 'REGULAR']};
`;

export const Label = styled.label<TLabelStyled>`
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  ${({ theme }) => theme.toolkit.typography.fontSizes.M14};
  ${({ size, theme }) =>
    size &&
    theme.toolkit.typography.fontSizes[size as keyof typeof theme.toolkit.typography.fontSizes]}
`;
