import { useState, cloneElement, useRef, useEffect } from 'react';
import { TBoundary, TTooltipHook } from './Tooltip.types';

export const useTooltip = (props: TTooltipHook) => {
  const { delay, children } = props;
  const tooltipWidthWeb = 360;
  let timeout: NodeJS.Timeout;

  const targetRef = useRef<HTMLParagraphElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const clonedChildren = cloneElement(children, { ref: targetRef });

  const [active, setActive] = useState(false);

  const [boundary, setBoundary] = useState<TBoundary>({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 0,
    height: 0,
  });

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 300);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  const handleCloseClick = () => {
    hideTip();
  };

  const waitForRender = () => {
    return new Promise((resolve) => {
      window.requestAnimationFrame(resolve);
    });
  };

  const handleResize = () => {
    if (targetRef.current) {
      const { top, bottom, left, right, width, height } =
        targetRef.current.getBoundingClientRect();
      setBoundary({
        top: top,
        bottom: window.innerHeight - bottom,
        left: left + width / 2,
        right: window.innerWidth - right + width / 2,
        width: width,
        height: height,
      });
    }
  };

  useEffect(() => {
    const handleInitialResize = async () => {
      await waitForRender();
      handleResize();
    };

    window.addEventListener('resize', handleResize);

    handleInitialResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [targetRef]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        !targetRef.current?.contains(event.target as Node)
      ) {
        hideTip();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getTipPosition = (boundary: TBoundary) => {
    if (window.innerWidth < tooltipWidthWeb) {
      return 'bottom';
    } else {
      return boundary.left > tooltipWidthWeb / 2 &&
        boundary.right > tooltipWidthWeb / 2
        ? 'bottom-center'
        : boundary.left < tooltipWidthWeb / 2 &&
          window.innerWidth - boundary.left + boundary.width / 2 >
            tooltipWidthWeb
        ? 'bottom-start'
        : boundary.right < tooltipWidthWeb / 2 &&
          window.innerWidth - boundary.right + boundary.width / 2 >
            tooltipWidthWeb
        ? 'bottom-end'
        : 'bottom';
    }
  };

  return {
    active,
    boundary,
    clonedChildren,
    tooltipRef,
    showTip,
    hideTip,
    handleCloseClick,
    getTipPosition,
  };
};
