import Empty from '../../../../../assets/empty.svg';
import Image from 'next/image';

export const getImageElement = (
  shouldShowImage: boolean,
  image?: { src: string; alt: string },
  isLoadEagerly?: boolean,
): JSX.Element | null => {
  if (shouldShowImage) {
    if (image?.src) {
      return (
        <img
          src={image.src}
          alt={image.alt}
          loading={isLoadEagerly ? 'eager' : 'lazy'}
        />
      );
    } else {
      return (
        <Image
          src={Empty as unknown as string}
          alt="No Image"
          width={72}
          height={52}
          style={{
            width: '72px',
            height: '52px',
            objectFit: 'cover',
          }}
        />
      );
    }
  }

  return null;
};
