import styled, { css } from 'styled-components';
import * as Types from './Button.types';

const getIconOnlyWidth: {
  [key in Types.ButtonSizes]: string;
} = {
  X_SMALL: '2rem',
  SMALL: '2.5rem',
  MEDIUM: '3rem',
  LARGE: '4rem',
};

const getWidth = ({
  fullWidth,
  isIconOnly,
  buttonSize,
  isButtonGroup,
  isLinkButtonGroup,
  isMobile,
}: Types.ButtonStyleProps): string => {
  // Handle mobile specific full-width logic
  if (isMobile && fullWidth && !(isButtonGroup || isLinkButtonGroup)) {
    return '100%';
  }
  // Handle desktop specific full-width logic
  if (!isMobile && fullWidth) {
    return '100%';
  }
  // Handle icon-only button width
  if (isIconOnly && buttonSize) {
    return getIconOnlyWidth[buttonSize];
  }
  // Default width
  return 'fit-content';
};

const getStyle = (
  icon: string,
  type?: string,
  loader?: boolean,
  active?: boolean,
) => {
  const iconsToFill = ['HEART', 'MAPPIN', 'MINUSCIRCLE', 'PLUSCIRCLE'];
  const fillIcon = iconsToFill.includes(icon) ? true : false;
  const fillColor = fillIcon
    ? { iconColor: '#4170C4', strokeColor: '#4170C4' }
    : { iconColor: 'none', strokeColor: '#4170C4' };
  switch (type) {
    case 'PRIMARY':
      return css`
        background-color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
        color: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
        text-transform: uppercase;
        ${!loader &&
        css`
          &:hover {
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.DARK};
          }
          &:focus:not(:focus-visible) {
            outline: none;
          }
          &:focus:focus-visible {
            outline: 4px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.BASE};
          }
          &:active {
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.LIGHT};
          }
          &:disabled {
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.LIGHTER};
          }
        `}
      `;
    case 'SECONDARY':
      return css`
        border: 1px solid ${({ theme }) => theme.toolkit.brand.primary.BASE};
        background: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
        color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
        text-transform: uppercase;
        ${!loader &&
        css`
          &:hover {
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.LIGHTEST};
            color: ${({ theme }) => theme.toolkit.brand.primary.DARKER};
            border: 1px solid
              ${({ theme }) => theme.toolkit.brand.primary.DARKER};
          }
          &:focus:not(:focus-visible) {
            outline: none;
          }
          &:focus:focus-visible {
            outline: 4px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            border: 1px solid ${({ theme }) => theme.toolkit.brand.primary.BASE};
            color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
          }
          &:active {
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.LIGHTEST};
            color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
            border: 1px solid ${({ theme }) => theme.toolkit.brand.primary.BASE};
          }
          &:disabled {
            border: 1px solid
              ${({ theme }) => theme.toolkit.brand.primary.LIGHTER};
            color: ${({ theme }) => theme.toolkit.brand.primary.LIGHTER};
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
          }
        `}
      `;
    case 'TERTIARY':
      return css`
        color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
        border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
        ${active &&
        css`
          color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
          border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
          svg path {
            fill: ${fillColor.iconColor};
            stroke: ${fillColor.strokeColor};
          }
        `}
        background: ${({ theme }) => theme.toolkit.brand.primary.TEXTCONTRAST};
        font-weight: ${({ theme }) =>
          theme.toolkit.typography.fontWeights.SEMIBOLD};
        ${!loader &&
        css`
          &:hover {
            background-color: ${({ theme }) =>
              theme.toolkit.brand.grey?.LIGHTEST};
            color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
            border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
          }
          ${active &&
          css`
            &:hover {
              color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
              border: 1px solid
                ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
            }
            svg path {
              fill: ${fillColor.iconColor};
              stroke: ${fillColor.strokeColor};
            }
          `}
          &:focus:not(:focus-visible) {
            outline: none;
          }
          &:focus:focus-visible {
            outline: 4px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
          }
          &:active {
            border: 1px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
            background-color: ${({ theme }) =>
              theme.toolkit.brand.grey?.LIGHTEST};
          }
          &:disabled {
            border: 1px solid
              ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
            color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
            background-color: ${({ theme }) =>
              theme.toolkit.brand.primary.TEXTCONTRAST};
          }
        `}
      `;
    case 'GHOST':
      return css`
        border: none;
        background: transparent;
        color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
        ${active &&
        css`
          color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
          svg path {
            fill: ${fillColor.iconColor};
            stroke: ${fillColor.strokeColor};
          }
        `}
        font-weight: ${({ theme }) =>
          theme.toolkit.typography.fontWeights.REGULAR};
        &:hover {
          color: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
          background-color: ${({ theme }) =>
            theme.toolkit.brand.grey?.LIGHTEST};
        }
        ${active &&
        css`
          &:hover {
            svg path {
              fill: ${fillColor.iconColor};
              stroke: ${fillColor.strokeColor};
            }
          }
        `}
        &:focus:not(:focus-visible) {
          outline: none;
        }
        &:focus:focus-visible {
          outline: 4px solid ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
        }
        &:disabled {
          color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
        }
      `;
    default:
      return '';
  }
};

export const Button = styled.button<Types.ButtonStyleProps>`
  // Apply base and dynamic styles to the button based on props using the theme
  ${({
    theme,
    buttonSize,
    buttonType,
    Icon,
    loader,
    active,
    fullWidth,
    isIconOnly,
    hasChildren,
    isButtonGroup,
    isLinkButtonGroup,
  }) => css`
    ${theme.toolkit.components.button.base};
    ${buttonSize
      ? theme.toolkit.components.button.buttonSizes[buttonSize]
      : theme.toolkit.components.button.buttonSizes.MEDIUM};

    ${getStyle(Icon ?? '', buttonType, loader, active)};
    width: ${getWidth({
      fullWidth,
      isIconOnly,
      buttonSize,
      isButtonGroup,
      isLinkButtonGroup,
      isMobile: false,
    })};
    min-width: ${(hasChildren !== undefined || loader === true) && buttonType
      ? '120px'
      : 'none'};

    &:disabled {
      cursor: unset;
    }

    @media ${theme.toolkit.mediaQuery.smallOnly} {
      width: ${getWidth({
        fullWidth,
        isIconOnly,
        buttonSize,
        isButtonGroup,
        isLinkButtonGroup,
        isMobile: true,
      })};
    }
  `}
`;

export const Container = styled.div<Types.ButtonStyleProps>`
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  display: flex;
  position: relative;
  ${({ hasChildren }) =>
    hasChildren !== undefined
      ? css`
          gap: 8px;
        `
      : css`
          gap: 0;
        `}
  ${(props) =>
    props.position === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
`;

export const IconStyling = styled(Container)`
  padding-bottom: ${({ theme }) => theme.toolkit.spacing.S2};
`;
