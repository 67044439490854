import styled, { css } from 'styled-components';
import {
  GalleryAspectRatio,
  GalleryImageObjectFit,
  GalleryStyledProps,
  SlotPosition,
} from './Gallery.typed';

export const GalleryWrapper = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  height: 100%;
`;

export const Gallery = styled.div<GalleryStyledProps>`
  align-items: center;
  background: #ffffff;
  display: flex;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;

  ${({ visibleGridImagesCount = 0, forceMobileView }) => {
    if (visibleGridImagesCount > 0) {
      return css`
        display: grid;
        gap: 2px;
        grid-template-rows: 2fr auto;

        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          grid-template-columns: ${(() => {
            switch (visibleGridImagesCount) {
              case 2:
                return 'none';
              case 3:
                return forceMobileView ? 'none' : '3fr 1fr';
              default:
                return '3fr 1fr';
            }
          })()};
          grid-template-rows: ${(() => {
            switch (visibleGridImagesCount) {
              case 2:
                return '2fr auto';
              case 3:
              default:
                return 'none';
            }
          })()};
        }
      `;
    }
    return css``;
  }}
`;

export const GalleryImageIndicators = styled.div`
  bottom: ${({ theme }) => theme.toolkit.spacing.S12};
  left: calc(50% - 24px);
  position: absolute;
  max-width: ${({ theme }) => theme.toolkit.spacing.L48};
`;

export const Item = styled.div<{
  objectFit: GalleryImageObjectFit;
  translateXOffset: number;
  hasSlideAnimation: boolean;
}>`
  display: flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: flex-start;
  overflow: hidden;
  text-align: center;
  width: 100%;

  /* Ensure animation and styles would work for image or div, once children's type is ReactNode */
  > img,
  > div {
    flex-shrink: 0;
    object-fit: ${({ objectFit }) => objectFit ?? GalleryImageObjectFit.COVER};
    transform: ${({ translateXOffset }) => `translateX(${translateXOffset}%)`};
    transition: ${({ hasSlideAnimation }) =>
      hasSlideAnimation ? 'transform 0.15s ease-out' : 'none'};
    width: -moz-available;
    width: -webkit-fill-available;
    height: 100%;
    overflow: hidden;
  }
`;

export const UnloadedGalleryImage = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
`;

export const Controls = styled.div<{ displayOnHover?: boolean }>`
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${({ displayOnHover }) => displayOnHover && 'visibility: hidden;'};

    &:first-child {
      left: 0.625rem;
    }
    &:last-child {
      right: 0.625rem;
    }
  }
`;

export const GalleryImageContainer = styled.div<{
  aspectRatio?: string;
  aspectRatioMobile?: string;
}>`
  aspect-ratio: ${({ aspectRatio, aspectRatioMobile }) =>
    aspectRatioMobile || aspectRatio || GalleryAspectRatio.RATIO_3_2};
  height: 100%;
  max-height: 100%;
  position: relative;
  width: 100%;
  &:hover ${Controls} button {
    visibility: visible;
  }

  @media only screen and (${({ theme }) => theme.toolkit.mediaQuery.medium}) {
    aspect-ratio: ${({ aspectRatio }) =>
      aspectRatio || GalleryAspectRatio.RATIO_3_2};
  }
`;

// The top slots can take up to 50% of the image width minus 16px (12px margin to the side and 4px as there must be at least an 8px gap between the two slots)
// The bottom slots take into account the Indicators component too. It is 48px max-width and needs 8px gap each side of it
// The bottom slots therefore have a maximum width of 50% of the image width minus half of the indicator width (24px + 8px padding = 32px). We also need to then take the 12px margin into account (32px + 12px = 44px)
export const Slot = styled.div<{ slotPosition: SlotPosition }>`
  position: absolute;
  overflow: hidden;
  ${({ slotPosition }) => {
    switch (slotPosition) {
      case 'top-left':
        return 'max-width: calc(50% - 16px); top: 12px; left: 12px;';
      case 'top-right':
        return 'max-width: calc(50% - 16px); top: 12px; right: 12px;';
      case 'bottom-left':
        return 'max-width: calc(50% - 44px); bottom: 12px; left: 12px;';
      case 'bottom-right':
        return 'max-width: calc(50% - 44px); bottom: 12px; right: 12px;';
      default:
        return '';
    }
  }}
`;

export const GalleryLoadingSpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
