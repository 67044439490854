import { useState } from 'react';

export const useGallery = (
  setIndex: (index: number) => void,
  setPreviousIndex: (index: number) => void,
  children: React.ReactNode | React.ReactNode[],
  index: number,
  onChange: (index: number) => void,
) => {
  const SWIPE_THRESHOLD = 50;
  const items = Array.isArray(children) ? children : [children];
  const lastIndex = items.length - 1;
  const [loadedGalleryImages, setLoadedGalleryImages] = useState([index || 0]);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });

  if (!loadedGalleryImages.includes(index)) {
    loadedGalleryImages.push(index);
  }

  /*
   * We load and display the images in the gallery from the loadedGalleryImages array.
   * When navigating the gallery we preload one value ahead of the direction to smooth
   * the user experience and help avoid waiting for the image to load. as the click through
   */
  const updateLoadedGalleryImages = ({
    loadAheadValue,
    nextIndex,
  }: {
    loadAheadValue: number;
    nextIndex: number;
  }) => {
    const newLoadedImages = [...loadedGalleryImages];
    if (!newLoadedImages.includes(nextIndex)) {
      newLoadedImages.push(nextIndex);
    }

    let nextPreloadImageIndex = nextIndex + loadAheadValue;
    if (nextPreloadImageIndex === -1) {
      nextPreloadImageIndex = items.length - 1;
    }

    if (
      items[nextPreloadImageIndex] &&
      !newLoadedImages.includes(nextPreloadImageIndex)
    ) {
      newLoadedImages.push(nextPreloadImageIndex);
    }

    setLoadedGalleryImages(newLoadedImages);
  };

  const goToNext = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const nextIndex = index === lastIndex ? 0 : index + 1;
    setIndex(nextIndex);
    setPreviousIndex(index);
    onChange(nextIndex);
    updateLoadedGalleryImages({ loadAheadValue: +1, nextIndex });
  };

  const goToPrev = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const prevIndex = index === 0 ? lastIndex : index - 1;
    setIndex(prevIndex);
    setPreviousIndex(index);
    onChange(prevIndex);
    updateLoadedGalleryImages({ loadAheadValue: -1, nextIndex: prevIndex });
  };

  const handleSwipeEnd = (endX: number, endY: number) => {
    const diffX = startPos.x - endX;
    const diffY = startPos.y - endY;

    if (
      Math.abs(diffX) > Math.abs(diffY) &&
      Math.abs(diffX) > SWIPE_THRESHOLD
    ) {
      diffX > 0 ? goToNext() : goToPrev();
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartPos({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    handleSwipeEnd(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setStartPos({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    handleSwipeEnd(e.clientX, e.clientY);
  };

  return {
    handleMouseDown,
    handleMouseUp,
    handleSwipeEnd,
    handleTouchStart,
    handleTouchEnd,
    loadedGalleryImages,
    goToNext,
    goToPrev,
    items,
    updateLoadedGalleryImages,
  };
};
