/**
 * Calculates the contrast of a color in hexadecimal format and returns a text color (black or white)
 * which offers good contrast
 * @param hexcolor A background color in hexadecimal format
 * @returns A recommended text color ('#ffffff' for white or '#333333' for black)
 */
export const getContrastYIQ = (hexcolor: string): string => {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return yiq >= 128 ? '#333333' : '#ffffff';
};
