import styled, { css } from 'styled-components';
import { FormControlStyledProps } from './FormControl.types';
import { Textarea } from '../../atoms/textarea/Textarea.styled';

const Wrapper = styled.div(() => {
  return css`
    display: flex;
    flex-wrap: nowrap;
    gap: ${({ theme }) => theme.toolkit.spacing.S8};
  `;
});

const Control = styled.div<{ isInline: boolean }>(() => {
  return css`
    flex: 1 1 auto;
  `;
});

const FormLabel = styled.div(() => {
  return css`
    margin-bottom: ${({ theme }) => theme.toolkit.spacing.S8};
  `;
});

const getFormControlInline = (props: FormControlStyledProps) => {
  return css`
    display: flex;
    align-items: center;

    ${FormLabel} {
      padding-left: ${({ theme }) => theme.toolkit.spacing.S8};
      ${props.isInline && `margin-bottom: 0px`};
    }

    ${RequiredIndicator} {
      display: none;
    }
  `;
};

const RequiredIndicator = styled.span(() => {
  return css`
    margin-inline-start: 2px;
  `;
});

const FormControl = styled.div<FormControlStyledProps>((props) => {
  return css`
    font: inherit;
    position: relative;
    ${props.isInline && getFormControlInline};

    ${Textarea} {
      height: 100%;
    }
  `;
});

export { FormControl, FormLabel, RequiredIndicator, Control, Wrapper };
