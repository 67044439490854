import React, { useRef, useState, useEffect } from 'react';

export const useCarousel = (callback: (index: number) => void, disabled?: boolean) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [clickStartPos, setClickStartPos] = useState(0);
  const [clickEndPos, setClickEndPos] = useState(0);

  const [scrollStart, setScrollStart] = useState(0);

  const [bounceLeft, setBounceLeft] = useState(false);
  const [bounceRight, setBounceRight] = useState(false);

  const [blurLeft, setBlurLeft] = useState(false);
  const [blurRight, setBlurRight] = useState(false);

  useEffect(() => {
    handleBlur();
  }, []);

  const bounceBack = () => {
    const container = containerRef.current;
    if (!container) return;
    const { scrollLeft, scrollWidth, clientWidth } = container;

    const tolerance = 1;
    const isScrollAtStart = Math.abs(scrollLeft) <= tolerance;
    const isScrollAtEnd =
      Math.abs(scrollLeft - (scrollWidth - clientWidth)) <= tolerance;
    if (isScrollAtStart) {
      setBounceLeft(true);
      setBlurLeft(false);
      setTimeout(() => setBounceLeft(false), 500);
    }
    if (isScrollAtEnd) {
      setBounceRight(true);
      setBlurRight(false);
      setTimeout(() => setBounceRight(false), 500);
    }
  };

  const handleBlur = () => {
    const container = containerRef.current;
    if (!container) return;
    const { scrollLeft, scrollWidth, clientWidth } = container;

    const threshold = 88;
    const isScrollingAtFront = scrollLeft < threshold;
    const isScrollingInMiddle =
      scrollLeft > threshold &&
      scrollLeft < scrollWidth - clientWidth - threshold;
    const isScrollingAtEnd = scrollLeft > scrollWidth - clientWidth - threshold;

    setBlurLeft(isScrollingAtEnd || isScrollingInMiddle);
    setBlurRight(isScrollingAtFront || isScrollingInMiddle);
  };

  const handleScroll = () => {
    bounceBack();
    handleBlur();
  };

  const handleWheel = (e: React.WheelEvent) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = e.deltaX ? e.deltaX : e.deltaY;
      containerRef.current.scrollLeft += scrollAmount;
      Math.abs(scrollAmount) > 5 && bounceBack();
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    setScrollStart(e.clientX);
    setClickStartPos(e.pageX);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const container = containerRef.current;
    if (!isDragging || !container) return;
    e.preventDefault();
    const x = e.clientX - (containerRef.current?.offsetLeft ?? 0);
    const walk = scrollStart - x;
    setScrollStart(x);
    containerRef.current.scrollLeft += walk;
    Math.abs(walk) > 5 && bounceBack();
  };

  const handleImageClick = (e: React.MouseEvent, index: number) => {
    !disabled && callback(index);
    const clickInterval = clickEndPos - clickStartPos;
    if (!disabled && clickInterval === 0) {
      const clickedImage = e.currentTarget;
      const activeImage = containerRef.current?.querySelector('.active');
      if (activeImage) {
        activeImage.classList.remove('active');
      }
      clickedImage.classList.add('active');
      // if (containerRef.current) {
      //   const photoWidth = 88;
      //   const scrollLeft = containerRef.current.scrollLeft;
      //   const nearestIndex = findNearestIndex(scrollLeft, photoWidth);
      //   const x = e.clientX - containerRef.current.offsetLeft;
      //   const newScrollLeft =
      //     x < 88 ? (nearestIndex - 1) * photoWidth : nearestIndex * photoWidth;

      //   let start: number | null = null;
      //   const step = (timestamp: number) => {
      //     if (!start) start = timestamp;
      //     const progress = timestamp - start;
      //     const duration = 800;
      //     const easeOutCubic = (t: number) => 1 - Math.pow(1 - t, 3);
      //     const scrollTo =
      //       scrollLeft +
      //       easeOutCubic(progress / duration) * (newScrollLeft - scrollLeft);

      //     containerRef.current?.scrollTo({ left: scrollTo });

      //     if (progress < duration) {
      //       requestAnimationFrame(step);
      //     } else {
      //       containerRef.current?.scrollTo({ left: newScrollLeft });
      //     }
      //   };

      //   requestAnimationFrame(step);
      // }
    }
  };

  // const findNearestIndex = (scrollLeft: number, photoWidth: number) => {
  //   const nearestIndex = Math.floor(scrollLeft / photoWidth);
  //   const remainder = scrollLeft % photoWidth;
  //   return remainder >= photoWidth / 2 ? nearestIndex + 1 : nearestIndex;
  // };

  const handleMouseUp = (e: React.MouseEvent) => {
    setIsDragging(false);
    setScrollStart(0);
    setClickEndPos(e.pageX);
  };

  return {
    handleWheel,
    handleMouseDown,
    handleMouseMove,
    handleImageClick,
    handleMouseUp,
    handleScroll,
    containerRef,
    bounceLeft,
    bounceRight,
    blurLeft,
    blurRight,
  };
};
