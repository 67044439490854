// relative Imports
import Icon from '../../icon/Icon';
import { Button, ButtonSizes, ButtonTypes } from '../..';
import { IconContainer, ModalHeaderContainer } from '../Modal.styled';
import * as types from '../Modal.types';

/**
 * Represents the header section of a modal dialog, typically containing a title and an optional close button. This component is
 * flexible with its content, allowing any ReactNode elements passed as children to be included. It supports varying sizes for the
 * header, which can affect the layout or styling, and optionally includes a close button that can trigger a specified event handler
 * when clicked. The header's appearance and functionality are influenced by the provided props, ensuring it can adapt to different
 * modal designs.
 *
 * ## Example Usage
 *
 * ```jsx
 * <ModalHeader
 *   handleCloseClick={() => console.log("Close button clicked")}
 *   hasCloseButton={true}
 *   size="large"
 * >
 *   <h1>Title of the Modal</h1>
 * </ModalHeader>
 * ```
 *
 
 */

const ModalHeader = (props: types.IModalHeaderProps) => {
  const { children, handleCloseClick, hasCloseButton = false, size } = props;
  return (
    <ModalHeaderContainer hasCloseButton={hasCloseButton} size={size}>
      {children}
      {hasCloseButton && (
        <IconContainer size={size} onClick={handleCloseClick}>
          <Button
            Icon={<Icon icon="CLOSE" size={16} />}
            buttonSize={ButtonSizes.SMALL}
            buttonType={ButtonTypes.GHOST}
            element="button"
          />
        </IconContainer>
      )}
    </ModalHeaderContainer>
  );
};

export default ModalHeader;
