import { ButtonSizes, ButtonTypes, Size } from '../../atoms';
import type { AnchorHTMLAttributes } from 'react';
export enum Width {
  FILL = 'FILL',
  FIXED = 'FIXED',
}

export enum Layout {
  PACKED = 'PACKED',
  SPACE_BETWEEN = 'SPACE_BETWEEN',
}

export type TLinkButtonGroupStyle = {
  width?: Width;
  buttonSizes?: ButtonSizes;
  size?: Size;
  layout?: Layout;
  position?: 'left' | 'right';
};

export interface TLinkButtonGroup
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    TLinkButtonGroupStyle {
  buttonSizes?: ButtonSizes;
  size?: Size;
  buttonTypes: ButtonTypes;
  buttonText: string;
  linkText: string;
  buttOnClick?: () => void;
  linkOnClick?: (e: React.MouseEvent<HTMLElement>) => void;
}
