import styled, { css } from 'styled-components';
import { TSpaceStyled } from './Space.types';
import {
  BreakpointsOptional,
  forEachBreakpoint,
  TSpacingValue,
} from '@dist-property-frontend/configs';

const getSpacePadding = (props: TSpaceStyled) => {
  let styles = '';
  if (typeof props.space === 'string') {
    styles += `padding: calc(${
      props.theme.toolkit.spacing[props.space]
    } / 2) 0`;
  } else {
    forEachBreakpoint((breakpoint) => {
      const space = props.space as BreakpointsOptional<TSpacingValue>;

      const spaceBreakpoint = space[breakpoint] as TSpacingValue;
      styles += `
        @media only screen and (min-width: ${props.theme.toolkit.breakpoints[breakpoint]}px) {
          padding: calc(${props.theme.toolkit.spacing[spaceBreakpoint]} / 2) 0;
        }
      `;
    });
  }

  return css`
    ${styles}
  `;
};

export const Space = styled.span<TSpaceStyled>(() => {
  return css`
    height: 0px;
    display: block;
    ${getSpacePadding}
  `;
});
