import styled, { css } from 'styled-components';
import * as Types from './MetaLabel.types';

export const getMetaLabelSizes = (size: string | undefined) => {
  switch (size) {
    case 'SMALL':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.S12};
      `;
    case 'MEDIUM':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
      `;
    case 'LARGE':
      return css`
        ${({ theme }) => theme.toolkit.typography.fontSizes.M16};
        @media ${({ theme }) => theme.toolkit.mediaQuery.smallOnly} {
          ${({ theme }) => theme.toolkit.typography.fontSizes.S14};
        }
      `;
    default:
      return '';
  }
};

export const Wrapper = styled.div<Types.MetaLabelStyle>`
  display: flex;
  align-items: center;
  flex-wrap: wrap; 
  overflow: hidden;
  max-height ${({ MetaLabelSizes, toggleText }) =>
    MetaLabelSizes === 'SMALL' && toggleText === true
      ? '1.5em'
      : (MetaLabelSizes === 'MEDIUM' || MetaLabelSizes === 'LARGE') &&
        toggleText === true
      ? '2.5em'
      : 'none'}; 
}`;

export const InnerWrapper = styled.div<Types.MetaLabelStyle>`
  ${({ MetaLabelSizes }) => getMetaLabelSizes(MetaLabelSizes)};
  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.REGULAR};
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span:not(:last-of-type) {
    display: flex;
    position: relative;
    align-items: center;
    :after {
      font-size: 23px;
      content: ${({ showContent }) => (showContent === true ? "'·'" : "''")};
      padding: ${({ showContent }) =>
        showContent === true ? '0 4px' : '0 6px'};
      color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
      display: inline-block;
    }
  }
`;
