import styled from "styled-components";
import { TInfoItemStyle } from "./InfoItem.types";
import * as Types from './InfoItem.types'


const getSpacing: { [key in Types.Spacing]: string } = {
    [Types.Spacing.HUG]: `width: fit-content`,
    [Types.Spacing.SPACED]: `width: 100%`,
  };


export const InfoItemStyle = styled.li<TInfoItemStyle>`
  
    display: flex;
    align-items: center;
    gap: 8px;
    ${({ spacing }) => (spacing ? getSpacing[spacing] : getSpacing['HUG'])};
    justify-content : space-between;
    min-width: fit-content;
    

`