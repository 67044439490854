import styled from 'styled-components';

export const BurgerWrapper = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  cursor: pointer;
  z-index: 2;
  width: 2.19rem;
  height: 2.19rem;
`;

export const Burger = styled.span`
  width: ${({ theme }) => theme.toolkit.spacing.M24};
  height: 1.125rem;
  position: relative;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  z-index: 2;

  span {
    display: block;
    position: absolute;
    height: ${({ theme }) => theme.toolkit.spacing.S2};
    width: 100%;
    background: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    transform-origin: left center;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 45%;
    }
    &:nth-child(3) {
      bottom: 0;
    }
  }
  &.active {
    span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 0;
      }
      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
`;
