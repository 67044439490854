import { ButtonSizes, ButtonProps } from '../../atoms';

export enum Width {
  FILL = 'FILL',
  FIXED = 'FIXED',
}

export enum Layout {
  PACKED = 'PACKED',
  SPACE_BETWEEN = 'SPACE_BETWEEN',
}

export type TButtonGroupStyle = {
  width?: Width;
  buttonSizes?: ButtonSizes;
  includesGhostButton?: boolean;
  layout?: Layout;
};

export interface TButtonGroup extends TButtonGroupStyle {
  buttons: ButtonProps[];
}
