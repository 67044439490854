
import { TInfoItem } from './InfoItem.types'
import { InfoItemStyle } from './InfoItem.styled'


export const InfoItem = ({children, spacing} : TInfoItem) => {
    return (
        <InfoItemStyle spacing={spacing} >
            {children}
        </InfoItemStyle>
    )
}