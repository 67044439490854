import { forwardRef } from 'react';
import * as Styled from './Textarea.styled';
import { TextareaProps } from './Textarea.types';

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props: TextareaProps, ref) => {
    const {
      hasError = false,
      rows = 6,
      cols = 4,
      resize = 'none',
      disabled = false,
      ...rest
    } = props;
    return (
      <Styled.Textarea
        hasError={hasError}
        rows={rows}
        cols={cols}
        resize={resize}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default Textarea;
