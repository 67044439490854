import styled from 'styled-components';

export const MultiSelectTagWrapper = styled.div<{
  layout?: string;
}>`
  border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  }
`;

export const MultiSelectTagLabel = styled.label<{
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}>`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  touch-action: manipulation;
  height: 100%;

  ${({ theme, checked, disabled, hasError }) => `
      background-color: ${
        disabled ? theme.toolkit.brand.grey?.LIGHTEST : theme.toolkit.brand.grey?.TEXTCONTRAST
      };
      width: 100%;
      padding: ${theme.toolkit.spacing.S8} ${theme.toolkit.spacing.S12};
      outline: ${
        disabled
          ? `1px solid ${theme.toolkit.brand.grey?.LIGHT}`
          : hasError
          ? `1px solid ${theme.toolkit.brand.error.DARK}`
          : checked && !disabled
          ? `2px solid ${theme.toolkit.brand.grey?.DARKER}`
          : `1px solid ${theme.toolkit.brand.grey?.LIGHT}`
      };
      border-radius: ${theme.toolkit.spacing.S4};
    `}
`;

export const MultiSelectTag = styled.input`
  -webkit-appearance: none;
  appearance: none;

  &:disabled {
    pointer-events: none;
  }
`;

export const MultiSelectTagTextWrapper = styled.div`
  content: '';
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 0;
`;

export const MultiSelectTagText = styled.div`
  display: flex;
`;
