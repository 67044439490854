import { AddressObject } from './AddressSearch.types';
import { useEffect, useRef, useState, ReactNode } from 'react';

export const useAddressSearch = (
  callback: (
    query: string
  ) => Promise<{ id: number | undefined; title: string | undefined }[]>,
  onChangeFormValue: (value: AddressObject | null) => void,
  selectedValue: string,
  timeoutLength?: number
) => {
  // boolean to handle showing / hiding the dropdown
  const [showListBox, setListBox] = useState<boolean>(false);
  // sets the query string to be passed to the API
  const [query, setQuery] = useState<string>('');
  // stores the data to be rendering in the dropdown
  const [listBoxData, setListBoxData] = useState<
    { title?: string; showTitle?: ReactNode; data?: AddressObject }[]
  >([]);
  // the query used for the debounce to avoid too many API calls
  const [debouncedTerm, setDebouncedTerm] = useState<string>(query);
  // boolean to handle no results error
  const [noResults, setNoResults] = useState<boolean>(false);
  // holds the selected value from the dropdown
  const [selectedItem, setSelectedItem] = useState<string>(selectedValue);
  // currently selected element from listbox
  const [focusedIndex, setFocusedIndex] = useState(0);
  // loading state when API call is made
  const [isLoading, setIsLoading] = useState(false);
  // ref for the main wrapper
  const wrapperRef = useRef<HTMLDivElement>(null);
  // ref for the input field
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);

  // func to handle selecting an item from the dropdown
  const handleSelectedItem = (item: string) => {
    setSelectedItem(item);
    setListBox(false);
  };

  // function to handle clicking outside the element
  const handleClickOutside = (event: MouseEvent) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target as Node)) {
      setListBox(false);
    }
  };

  // function to handle focus of the div and set the input focus to true and the listbox to true
  const handleFocus = () => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
    setListBox(true);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { key } = e;
    let nextIndexCount = 0;
    if (key === 'Enter') {
      e.preventDefault();
      if (listBoxData[focusedIndex] !== undefined) {
        handleSelectedItem(listBoxData[focusedIndex].title || '');
        onChangeFormValue(listBoxData[focusedIndex].data || null);
      }
    }
    // move down
    if (key === 'ArrowDown') {
      nextIndexCount = (focusedIndex + 1) % listBoxData.length;
      const nextItem = listBoxRef.current?.childNodes[
        nextIndexCount
      ] as HTMLElement;
      nextItem?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    // move up
    if (key === 'ArrowUp') {
      nextIndexCount =
        (focusedIndex + listBoxData.length - 1) % listBoxData.length;
      const nextItem = listBoxRef.current?.childNodes[
        nextIndexCount
      ] as HTMLElement;
      nextItem?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    // hide search results
    if (key === 'Escape') {
      setListBox(false);
    }
    setFocusedIndex(nextIndexCount);
  };

  // watch for mouse event outside of the wraapper
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  });

  // debounce the API req by 1s
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true);
      setQuery(debouncedTerm);
      setListBoxData([]);
    }, timeoutLength || 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm, timeoutLength]);

  // when the input changes make an API call to get data related to the query string
  useEffect(() => {
    const onSearchSubmit = async (query: string) => {
      const data = await callback(query);
      if (data) {
        setIsLoading(false);
        setListBoxData(data);
        if (data.length === 0) {
          setNoResults(true);
          setIsLoading(false);
        } else {
          setNoResults(false);
          setIsLoading(false);
        }
      }
    };
    if (query !== '') {
      onSearchSubmit(query);
    } else {
      setIsLoading(false);
      setNoResults(false);
      setListBoxData([]);
    }
  }, [query]);

  return {
    handleClickOutside,
    handleFocus,
    handleSelectedItem,
    handleKeyDown,
    showListBox,
    listBoxData,
    focusedIndex,
    debouncedTerm,
    isLoading,
    setDebouncedTerm,
    setFocusedIndex,
    setListBox,
    setSelectedItem,
    setQuery,
    wrapperRef,
    inputRef,
    listBoxRef,
    query,
    noResults,
    selectedItem,
  };
};
