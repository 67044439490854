import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Error,
  Input,
  Label,
  Space,
  Helper,
} from '../../atoms';
import Thumbnail from '../../molecules/thumbnail/Thumbnail';
import { useVideoUploader } from './VideoUploader.hook';
import * as Styled from './VideoUploader.styled';
import { TVideoUploader } from './VideoUploader.types';

export const VideoUploader = ({
  onChangeFormValue,
  value,
  helperMessage,
  placeholder,
  addButton,
  initialValue,
  type = 'video',
}: TVideoUploader) => {
  const {
    openInNewTab,
    onClickRemove,
    handleFind,
    img,
    error,
    loading,
    id,
    setInputValue,
    stringValue,
  } = useVideoUploader(type, onChangeFormValue, initialValue);

  return (
    <Styled.Wrapper>
      <Label>URL link</Label>
      <Input
        type="text"
        placeholder={placeholder}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        value={stringValue}
        hasError={error}
      />
      {error && (
        <>
          <Space space={'S8'} />
          <Error id="">
            {`Can’t find the ${
              type === 'virtualTour' ? 'virtual tour' : 'video'
            }. Please enter a valid URL link`}
          </Error>
        </>
      )}
      {helperMessage && (
        <>
          <Space space={'S8'} />
          <Helper>{helperMessage}</Helper>
        </>
      )}

      <Space space={'S12'} />
      <Button
        buttonType={ButtonTypes.SECONDARY}
        onClick={handleFind}
        loader={loading}
        buttonSize={ButtonSizes.X_SMALL}
        disabled={Boolean(id)}
      >
        {addButton}
      </Button>
      {id && (
        <Styled.ThumbWrapper>
          <Thumbnail
            overlay={true}
            handleDelete={onClickRemove}
            img={img}
            altText={id}
            handleOverlay={() => openInNewTab(value.original || '')}
          />
        </Styled.ThumbWrapper>
      )}
    </Styled.Wrapper>
  );
};
