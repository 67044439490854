import styled from 'styled-components';

export const EmptyWrapper = styled.div`
  border: 1px dashed #bbbbbb;
  border-radius: 4px;
  background: #ffffff;
  height: 6.75rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin-top: ${({ theme }) => theme.toolkit.spacing.S4};
  }
`;
