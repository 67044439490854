import styled, { css } from 'styled-components';
import {
  CardContainerBorder,
  CardContainerCornerRadius,
  CardContainerProps,
} from './CardContainer.types';

const getCornerRadius = (
  cornerRadius: CardContainerCornerRadius | undefined,
): string => {
  switch (cornerRadius) {
    case CardContainerCornerRadius.ON:
      return 'border-radius: 4px';
    case CardContainerCornerRadius.TOP:
      return 'border-radius: 4px 4px 0 0';
    case CardContainerCornerRadius.BOTTOM:
      return 'border-radius: 0 0 4px 4px';
    case CardContainerCornerRadius.OFF:
      return 'border-radius: 0';
    default:
      return 'border-radius: 0';
  }
};

const getBorder = (border: CardContainerBorder | undefined): string => {
  switch (border) {
    case CardContainerBorder.ON:
      return 'border: 1px solid #dddddd';
    case CardContainerBorder.TOP:
      return `
      border-color: #dddddd;
      border-style: solid;
      border-width: 1px 0 0 0
      `;
    case CardContainerBorder.BOTTOM:
      return `
      border-color: #dddddd;
      border-style: solid;
      border-width: 0 0 1px 0
      `;
    case CardContainerBorder.OFF:
      return 'border: none';
    default:
      return 'border: none';
  }
};

export const CardContainer = styled.div<CardContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#FFFFFF'};
  ${({ cornerRadius, border }) => css`
    ${getCornerRadius(cornerRadius)};
    ${getBorder(border)};
  `}
  overflow: hidden;
  display: block;
  padding: ${({ padding }) => (padding ? '12px 16px' : '0')};
  width: 100%;

  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.smallOnly}) {
    ${({ cornerRadiusMobile, borderMobile, mobileFullWidth }) => {
      return css`
        ${cornerRadiusMobile
          ? getCornerRadius(cornerRadiusMobile)
          : mobileFullWidth && getCornerRadius(CardContainerCornerRadius.OFF)};
        ${borderMobile
          ? getBorder(borderMobile)
          : mobileFullWidth && getBorder(CardContainerBorder.BOTTOM)};
      `;
    }}
  }

  ${({ hoverBackgroundColor }) =>
    hoverBackgroundColor &&
    css`
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: ${hoverBackgroundColor};
      }
    `}
`;

export default CardContainer;
