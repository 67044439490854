import styled from 'styled-components';

type GalleryIndicatorStyledProps = {
  active?: boolean;
  translateXOffset?: number;
};

export const Indicator = styled.div<GalleryIndicatorStyledProps>`
  background-color: ${({ theme }) => theme.color.WHITE};
  border-radius: 50%;
  flex-shrink: 0;
  height: ${({ active, theme }) => (active ? theme.toolkit.spacing.S8 : '6px')};
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  transform: ${({ translateXOffset }) => `translateX(${translateXOffset}px)`};
  transition: transform 0.15s ease-out;
  width: ${({ active, theme }) => (active ? theme.toolkit.spacing.S8 : '6px')};
`;

export const IndicatorContainer = styled.div<{ items: number }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.toolkit.spacing.S8};
  height: ${({ theme }) => theme.toolkit.spacing.S8};
  justify-content: ${({ items }) => (items <= 4 ? 'center' : 'flex-start')};
  overflow: hidden;
  width: ${({ theme }) => theme.toolkit.spacing.L56};
`;
