import { PanelWrapper } from './StandardPanel.styled';
import { TPanel } from './StandardPanel.types';

export const StandardPanel = ({
  size = 'lg',
  children,
  bgcolor = 'white',
  border,
  heightAuto,
  testId,
}: TPanel) => {
  return (
    <PanelWrapper
      bgcolor={bgcolor}
      size={size}
      border={border}
      data-testid={testId}
      heightAuto={heightAuto}
    >
      {children}
    </PanelWrapper>
  );
};

export default StandardPanel;
