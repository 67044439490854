import { forwardRef, useState } from 'react';
import { useTheme } from 'styled-components';
import * as Atom from '../../atoms';
import * as Styled from './MultiSelect.styled';
import * as Types from './MultiSelect.types';

export const MultiSelectTag = forwardRef<
  HTMLInputElement,
  Types.TMultiSelectTag
>(
  (
    {
      value = '',
      inputLabel,
      hasError = false,
      disabled = false,
      layout = Types.EMultiSelectLayout.STACKED,
      checked = false,
      ...rest
    }: Types.TMultiSelectTag,
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState(checked);
    const theme = useTheme();
    const greyTheme = theme.toolkit.brand.grey;

    const onClick = () => {
      !isChecked ? setIsChecked(true) : setIsChecked(false);
    };

    return (
      <Styled.MultiSelectTagWrapper
        tabIndex={0}
        role={'checkbox'}
        layout={layout}
        aria-checked={isChecked}
      >
        <Styled.MultiSelectTagLabel
          data-testid={'multiselect-label'}
          checked={isChecked}
          disabled={disabled}
          hasError={hasError}
          tabIndex={-1} // remove focus from label
        >
          <Styled.MultiSelectTag
            type={'checkbox'}
            data-testid={'multiselect-input'}
            tabIndex={-1} // remove focus from hidden input
            disabled={disabled}
            checked={isChecked}
            onClick={onClick}
            value={value}
            ref={ref}
            {...rest}
          />
          <Styled.MultiSelectTagTextWrapper>
            <Styled.MultiSelectTagText>
              <Atom.Text
                variant={'span'}
                size={Atom.TextSize.S14}
                fontWeight={
                  isChecked
                    ? Atom.TextFontWeight.SEMIBOLD
                    : Atom.TextFontWeight.REGULAR
                }
                textColor={disabled ? greyTheme?.BASE : greyTheme?.DARKER}
              >
                {inputLabel}
              </Atom.Text>
            </Styled.MultiSelectTagText>
          </Styled.MultiSelectTagTextWrapper>
        </Styled.MultiSelectTagLabel>
      </Styled.MultiSelectTagWrapper>
    );
  },
);

export default MultiSelectTag;
