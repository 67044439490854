import { ReactNode } from 'react';
import { CardContentRootProps } from './cardComponents/cardContent/CardContent.types';

export enum CardLayout {
  VerticalDesktop = 'VERTICAL_DESKTOP',
  HorizontalDesktop = 'HORIZONTAL_DESKTOP',
}

export type CardProps = {
  index?: number;
  layout: CardLayout;
  cardContent?: ReactNode;
  cardGallery?: ReactNode;
  cardBanner?: ReactNode;
  hasSubunits?: boolean;
  subunitsNum?: number;
} & CardContentRootProps;
