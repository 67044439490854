import * as Styled from './AddressSearch.styled';
import { Input, Spinner, Link, Weight } from '../../atoms';
import { useTheme } from 'styled-components';
import { useAddressSearch } from './AddressSearch.hooks';
import { AddressObject, TAddressSearch } from './AddressSearch.types';
import * as LinkTypes from '../../atoms/link/Link.types';

export const AddressSearch = ({
  callback,
  name,
  onChangeFormValue,
  placeholder,
  selectedValue,
  timeoutLength,
  ...rest
}: TAddressSearch) => {
  const theme = useTheme();
  const {
    debouncedTerm,
    focusedIndex,
    handleFocus,
    handleKeyDown,
    handleSelectedItem,
    inputRef,
    isLoading,
    listBoxData,
    listBoxRef,
    noResults,
    query,
    selectedItem,
    setDebouncedTerm,
    setFocusedIndex,
    setListBox,
    setSelectedItem,
    showListBox,
    wrapperRef,
  } = useAddressSearch(
    callback,
    onChangeFormValue,
    selectedValue,
    timeoutLength
  );

  const selectListBoxItem = ({
    data,
    title,
  }: {
    data: AddressObject | null;
    title: string;
  }) => {
    handleSelectedItem(title);
    onChangeFormValue(data);
  };

  // render the list of items in the dropdown
  const renderedAddresses =
    listBoxData &&
    listBoxData?.map((item, index) => {
      return (
        <Styled.ListBoxItem
          onClick={() => {
            selectListBoxItem({
              data: item.data || null,
              title: item.title || '',
            });
          }}
          onMouseEnter={() => setFocusedIndex(index)}
          key={index}
          style={{
            backgroundColor:
              index === focusedIndex
                ? `${theme.toolkit.brand.grey?.LIGHTEST}`
                : '',
          }}
        >
          <button>{item.showTitle}</button>
        </Styled.ListBoxItem>
      );
    });

  return (
    <Styled.Wrapper
      ref={wrapperRef}
      tabIndex={1}
      onFocus={() => handleFocus()}
      onKeyDown={handleKeyDown}
    >
      {!selectedItem && (
        <Input
          name={name}
          id="address-search"
          role="combobox"
          placeholder={placeholder}
          autoComplete="off"
          type="search"
          value={debouncedTerm}
          aria-labelledby=""
          aria-invalid="false"
          aria-activedescendant=""
          aria-autocomplete="list"
          aria-expanded={false}
          aria-haspopup={true}
          aria-controls="address-search-listbox"
          aria-owns="address-search-listbox"
          onChange={(e) => {
            setDebouncedTerm(e.target.value);
            setListBox(true);
          }}
          ref={inputRef}
          {...rest}
        />
      )}
      {selectedItem && (
        <Link
          onClick={() => {
            setListBox(false);
            setSelectedItem('');
            setDebouncedTerm('');
            onChangeFormValue(null);
          }}
          size={LinkTypes.Size.LARGE}
          underlined={false}
          weight={Weight.SEMIBOLD}
        >
          Enter new address
        </Link>
      )}

      {!selectedItem && showListBox && (
        <Styled.ListBox
          aria-labelledby=""
          role="listbox"
          id="address-search-listbox"
          tabIndex={0}
          ref={listBoxRef}
        >
          {query.length === 0 && <li>Start typing an eircode or address</li>}
          {query.length !== 0 && isLoading && (
            <Styled.Loader>
              <Spinner colour="black" />
            </Styled.Loader>
          )}
          {noResults && query !== '' && <li>No results found</li>}
          {renderedAddresses}
        </Styled.ListBox>
      )}
    </Styled.Wrapper>
  );
};
