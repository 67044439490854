import { TabButton } from '../../atoms';
import { TabButtonStyle } from '../../atoms/tabButton/TabButton.types';
import {
  TabsContainer,
  TabsContent,
  TabsFullPageWrapper,
  TabsWrapper,
} from './Tabs.styled';
import { useTabs } from './Tabs.hook';
import * as Types from './Tabs.types';

export const Tabs = (props: Types.ITab) => {
  /* TabsContainer is container for the TabButtons
   *  TabsContent is a separate container that holds the content / component to be displayed
   */
  const {
    tabs, // pass in an array of tab objects that match props of TabButton component
    underlined,
    linkSize,
    tabStyle = TabButtonStyle.DARK,
    center,
    fullPage,
    progress = tabs.length, // Can be used to update disabled tabs based on user progress in a form
    layout,
    overflow,
  } = props;

  const {
    currentTab,
    changeTab,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleMouseWheel,
    containerRef,
    activeTabRef,
  } = useTabs(props);

  return (
    <TabsFullPageWrapper fullPage={fullPage}>
      <TabsWrapper
        center={center}
        fullPage={fullPage}
        underlined={underlined}
        layout={layout}
        tabStyle={tabStyle}
      >
        <TabsContainer
          tabStyle={tabStyle}
          underlined={underlined}
          center={center}
          fullPage={fullPage}
          layout={layout}
          ref={containerRef}
          onWheel={handleMouseWheel}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {tabs &&
            tabs.map((tab, index) => (
              <TabButton
                ref={index === currentTab ? activeTabRef : null}
                testId={tab.testId}
                TabButtonStyle={tabStyle}
                layout={layout}
                handleClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => changeTab(index, e)} //Sets this TabButton to the active tab when user clicks
                isActive={index === currentTab}
                key={tab.testId}
                TabButtonSize={linkSize}
                isDisabled={
                  tab.isDisabled
                    ? tab.isDisabled
                    : index >= progress
                      ? true
                      : false
                }
              >
                {tab.children}
              </TabButton>
            ))}
        </TabsContainer>
      </TabsWrapper>
      <TabsContent overflow={overflow}>
        {tabs?.[currentTab]?.content}
      </TabsContent>
    </TabsFullPageWrapper>
  );
};
