export enum MetaLabelSizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type MetaLabelStyle = {
  MetaLabelSizes?: MetaLabelSizes;
  showContent?: boolean;
  toggleText?: boolean;
};

export interface MetaLabelProps extends MetaLabelStyle {
  metaProps: string[];
}
