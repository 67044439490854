import { Button, Link } from '../../atoms';
import * as Styled from './LinkButtonGroup.styled';
import * as types from './LinkButtonGroup.types';

export const LinkButtonGroup = ({
  width,
  buttonSizes,
  buttonTypes,
  size,
  layout,
  position,
  buttonText,
  linkText,
  buttOnClick,
  linkOnClick,
}: types.TLinkButtonGroup) => {
  return (
    <Styled.Wrapper
      width={width}
      layout={layout}
      position={position}
      buttonSizes={buttonSizes}
      size={size}
    >
      <Button
        fullWidth={width === types.Width.FILL}
        buttonSize={buttonSizes}
        buttonType={buttonTypes}
        isLinkButtonGroup
        onClick={buttOnClick}
      >
        {buttonText}
      </Button>
      <Link href="./" size={size} onClick={linkOnClick}>
        {linkText}
      </Link>
    </Styled.Wrapper>
  );
};
