import { THeaderData } from '../Header.types';
import { NavButton } from '../../../atoms/navButton/NavButton';
import * as Styled from './Navigation.styled';

export function Navigation({ children, mainNav, utilityNav }: THeaderData) {
  return (
    <Styled.Wrapper>
      {mainNav?.map((item, index) => (
        <NavButton item={item} key={`mainnav-${index}`} index={index} />
      ))}
      <>
        {utilityNav?.map((item, index) => (
          <NavButton item={item} key={`utility-${index}`} index={index} />
        ))}
        {children}
      </>
    </Styled.Wrapper>
  );
}
