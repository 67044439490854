import * as Styled from './Tooltip.styled';
import { Text, TextSize, TextFontWeight, Icon } from '../../atoms';
import { TTooltip } from './Tooltip.types';
import { useTooltip } from './Tooltip.hook';

export const Tooltip = (props: TTooltip) => {
  const {
    size = 'medium',
    image,
    header,
    content,
    closeIcon,
    toolTipWidth = 360,
  } = props;
  const {
    active,
    boundary,
    clonedChildren,
    tooltipRef,
    showTip,
    hideTip,
    handleCloseClick,
    getTipPosition,
  } = useTooltip(props);

  return (
    <Styled.TooltipWrapper
      onMouseEnter={showTip}
      onMouseLeave={
        closeIcon
          ? () => {
              return;
            }
          : hideTip
      }
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {active && (
        <Styled.Tip
          data-testid={'tooltip-tip'}
          ref={tooltipRef}
          className={`Tip ${getTipPosition(boundary)}`}
          spanWidth={boundary.width}
          spanHeight={boundary.height}
          spanLeft={boundary.left}
          width={window.innerWidth}
          toolTipWidth={toolTipWidth}
          size={size}
        >
          <Styled.TextIconWrapper>
            {image && (
              <Styled.Image data-testid={'tooltip-image'}>{image}</Styled.Image>
            )}
            <Styled.TextWrapper closeIcon={closeIcon} image={image} size={size}>
              {header && (
                <Styled.HeaderWrapper>
                  <Text
                    data-testid={'tooltip-header'}
                    as="h3"
                    size={size === 'medium' ? TextSize.S14 : TextSize.S12}
                    fontWeight={TextFontWeight.SEMIBOLD}
                  >
                    {header}
                  </Text>
                </Styled.HeaderWrapper>
              )}
              {content && (
                <Text
                  data-testid={'tooltip-content'}
                  as="div"
                  size={size === 'medium' ? TextSize.S14 : TextSize.S12}
                >
                  {content}
                </Text>
              )}
            </Styled.TextWrapper>
            {closeIcon && (
              <Styled.ClosedIcon
                data-testid={'tooltip-close'}
                onClick={handleCloseClick}
              >
                <Icon icon="CLOSE" size={16}></Icon>
              </Styled.ClosedIcon>
            )}
          </Styled.TextIconWrapper>
        </Styled.Tip>
      )}
      {clonedChildren}
    </Styled.TooltipWrapper>
  );
};
