import { useFieldArray, useFormContext } from 'react-hook-form';
import * as Atom from '../../atoms';
import { InputContainer } from './DynamicTextInput.styled';
import * as Types from './DynamicTextInput.types';

export const DynamicTextInput = (props: Types.TDynamicTextInput) => {
  const {
    fieldName = 'fieldArray',
    maxLength = 5,
    hasError = false,
    disabled = false,
    placeholder = '',
  } = props;
  const { register, control, watch } = useFormContext();
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: fieldName,
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = event.target.value;
    const watchArray = watch(`${fieldName}`);
    const prevValue = watch(`${fieldName}.${index}.value`);
    const hasNonEmptyInput =
      watchArray
        .slice(index + 1)
        .filter((item: Types.TArrayValues) => item?.value === '').length !==
      watchArray.length - index - 1;
    const cleanEmptyInputs = () => {
      const currentLength = watchArray.length;
      const lastNonEmptyIndex = watchArray
        .slice(0, index)
        .map((item: Types.TArrayValues) => item?.value !== '')
        .lastIndexOf(true);
      for (let i = currentLength - 2; i > lastNonEmptyIndex; i--) {
        remove(i);
      }
    };
    if (
      index < maxLength - 1 &&
      index === watchArray.length - 1 &&
      (prevValue === undefined || prevValue === '') &&
      value
    ) {
      append({ value: '' }, { shouldFocus: false });
    }
    if (value === '' && index !== watchArray.length - 1) {
      if (hasNonEmptyInput) {
        remove(index);
        insert(index, { value: '' }, { shouldFocus: false });
      } else {
        cleanEmptyInputs();
      }
    }
    if (value === '' && index === watchArray.length - 1) {
      cleanEmptyInputs();
    }
  };

  return (
    <>
      {fields.map((field, index) => (
        <InputContainer key={field.id}>
          <Atom.Input
            data-testid={`dynamic-input-${index}`}
            leading={`${index + 1}.`}
            hasError={hasError}
            disabled={disabled}
            placeholder={index === 0 ? placeholder : ''}
            {...register(`${fieldName}.${index}.value`)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange(event, index);
            }}
          />
        </InputContainer>
      ))}
    </>
  );
};

export default DynamicTextInput;
