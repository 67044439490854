import { PanelWrapper } from './StickyPanel.styled';
import { TStickyPanel } from './StickeyPanel.types';

export const StickyPanel = ({
  children,
  bgcolor = 'white',
  shadow = true,
  stickyPosition = 'bottom',
}: TStickyPanel) => {
  return (
    <PanelWrapper
      bgcolor={bgcolor}
      shadow={shadow}
      stickyPosition={stickyPosition}
    >
      {children}
    </PanelWrapper>
  );
};

export default StickyPanel;
