import { forwardRef } from 'react';
import * as Types from './RoundButton.types';
import * as Styled from './RoundButton.styled';

export const RoundButton = forwardRef<HTMLButtonElement, Types.TRoundButton>(
  (props: Types.TRoundButton, ref) => {
    const {
      buttonType = Types.RoundButtonTypes.PRIMARY,
      buttonSize = Types.RoundButtonSizes.SMALL,
      iconType,
      hasError = false,
      isDisabled = false,
      onClick,
      ...rest
    } = props;
    return (
      <Styled.RoundButton
        buttonType={buttonType}
        buttonSize={buttonSize}
        isDisabled={isDisabled}
        hasError={hasError}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <Styled.IconContainer icon={iconType} size={16} />
      </Styled.RoundButton>
    );
  }
);

export default RoundButton;
