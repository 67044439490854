import { Link, Logo, Style, Weight } from '../../atoms';
import * as Styled from './Footer.styled';
import { FooterProps } from './Footer.types';
import { Size } from '../../atoms';

export function Footer({ data }: FooterProps) {
  return (
    <Styled.Footer>
      <Styled.InnerWrapper>
        <section>
          <nav>
            <Styled.ListWrapper>
              {data?.map((item, index) => (
                <li key={index}>
                  <Link
                    size={Size.SMALL}
                    href={item.url}
                    style={Style.SECONDARY}
                    weight={Weight.SEMIBOLD}
                    onClick={item.onClick}
                  >
                    {item.linkText}
                  </Link>
                </li>
              ))}
            </Styled.ListWrapper>
          </nav>
        </section>
        <Styled.FooterBottom>
          <Logo width={1.5} height={1.5} name="ICON_GREY" />
          <Styled.Copyright>
            &copy; Daft Media Limited 1997-{new Date().getFullYear()}.
            Registered in Ireland under Company Number 347856
          </Styled.Copyright>
        </Styled.FooterBottom>
      </Styled.InnerWrapper>
    </Styled.Footer>
  );
}
