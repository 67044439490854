import styled from 'styled-components';
import { PromoPanelStyleProps } from './PromoPanel.types';

export const Wrapper = styled.section<PromoPanelStyleProps>`
  ${({ theme }) => theme.toolkit.components.promopanel.base};
  ${({ theme, variant }) =>
    theme.toolkit.components.promopanel.variants[
      variant as keyof typeof theme.toolkit.components.promopanel.variants
    ]};
  button {
    width: 100%;
  }
`;
