import styled, { DefaultTheme } from 'styled-components';
import * as types from './ButtonGroup.types';
import * as ButtonTypes from '../../atoms/button/Button.types';

const getButtonGap = (
  theme: DefaultTheme,
  isMobile: boolean,
  ghost?: boolean,
  size?: ButtonTypes.ButtonSizes
) => {
  if (ghost) {
    return theme.toolkit.spacing.M24;
  }

  if (isMobile) {
    if (size === ButtonTypes.ButtonSizes.LARGE) {
      return theme.toolkit.spacing.M16;
    } else {
      return theme.toolkit.spacing.S8; // Covers ButtonSizes.SMALL, ButtonSizes.X_SMALL, and ButtonSizes.MEDIUM
    }
  } else {
    if (
      size === ButtonTypes.ButtonSizes.LARGE ||
      size === ButtonTypes.ButtonSizes.MEDIUM
    ) {
      return theme.toolkit.spacing.M16;
    } else if (
      size === ButtonTypes.ButtonSizes.SMALL ||
      size === ButtonTypes.ButtonSizes.X_SMALL
    ) {
      return theme.toolkit.spacing.S8;
    } else {
      return theme.toolkit.spacing.M16; // Button atom is med by default
    }
  }
};

export const ButtonGroupContainer = styled.div<types.TButtonGroupStyle>`
  width: 100%;
  display: flex;
  justify-content: ${({ layout }) =>
    layout === types.Layout.SPACE_BETWEEN ? 'space-between' : 'start'};
  gap: ${({ buttonSizes, theme, includesGhostButton }) =>
    getButtonGap(theme, true, includesGhostButton, buttonSizes)};
  @media only screen and (min-width: 640px) {
    gap: ${({ buttonSizes, theme, includesGhostButton }) =>
      getButtonGap(theme, false, includesGhostButton, buttonSizes)};
  }
`;
