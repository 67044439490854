import { IllustrationWrapper } from './Illustration.styled';
import { IllustrationProps } from './Illustration.types';
import { ILLUSTRATIONS } from './Illustrations';

export function Illustration({ illustration, height }: IllustrationProps) {
  return (
    <IllustrationWrapper height={height}>
      {ILLUSTRATIONS[illustration]}
    </IllustrationWrapper>
  );
}

export default Illustration;
