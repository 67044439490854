import type { TSpace, TSpaceValue } from './Space.types';
import * as Styled from './Space.styled';
import { TSpacingValue } from '@dist-property-frontend/configs';

const getSpaceBreakpoints = (
  xs?: TSpacingValue,
  sm?: TSpacingValue,
  md?: TSpacingValue,
  lg: TSpacingValue = 'S2'
) => ({
  xs: xs || sm || md || lg,
  sm: sm || md || lg,
  md: md || lg,
  lg: lg,
});

export const Space = (props: TSpace) => {
  const { space, ...rest } = props;

  const spaceProp: TSpaceValue =
    typeof space === 'string'
      ? space
      : {
          ...getSpaceBreakpoints(space?.xs, space?.sm, space?.md, space?.lg),
        };

  return <Styled.Space space={spaceProp} {...rest} />;
};

export default Space;
