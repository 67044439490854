import styled, { css } from 'styled-components';
import { BadgeStyleProps } from './Badge.types';

export const Wrapper = styled.span<BadgeStyleProps>`
  ${({ theme, badgeStyle, badgeColor, badgeSize, iconAlignment }) => {    
    return css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-direction: ${iconAlignment === 'right' ? 'row-reverse' : 'row'};
      ${theme.toolkit.components.badge.base};
      ${theme.toolkit.components.badge.size[badgeSize || 'MEDIUM']};
      ${badgeColor && badgeStyle ? theme.toolkit.components.badge.color[badgeColor][badgeStyle] : ''};
      ${badgeStyle ? theme.toolkit.components.badge.style[badgeStyle] : ''};
      svg {
        min-width: ${badgeSize === 'SMALL' ? '12px' : '16px'};
      }
    `;
  }}
`;

export const TextWrapper = styled.span`
  display: block;
  max-width: 100%; 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
