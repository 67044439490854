import styled from 'styled-components';
import * as Types from './TabButton.types';
import * as TabStyles from '../../molecules/tabs/Tabs.types';

//Variant styles found in theme.default.ts
const getTabButtonVariant = (
  style: string | undefined,
  isActive: boolean | undefined,
) => {
  if (isActive) {
    return style === 'DARK' ? 'DARKACTIVE' : 'LIGHTACTIVE';
  } else {
    return 'LIGHT';
  }
};

export const TabButton = styled.button<Types.TabButtonStyleProps>`
  position: relative;
  background-color: transparent;
  text-decoration-color: transparent;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  ${({ layout, theme }) => {
    if (layout === TabStyles.ETabLayout.FILL) {
      return `width: 100%;`;
    } else if (
      layout === TabStyles.ETabLayout.FIXED ||
      layout === TabStyles.ETabLayout.SPACE_BETWEEN
    ) {
      return `padding: ${theme.toolkit.spacing.S4} ${theme.toolkit.spacing.S8};`;
    } else {
      return null;
    }
  }};

  ${({ TabButtonStyle, isActive, theme }) =>
    !isActive && TabButtonStyle === 'DARK'
      ? theme.toolkit.components.TabButton.base
      : theme.toolkit.components.TabButton.variants[
          getTabButtonVariant(TabButtonStyle, isActive)
        ]}

  ${({ theme, TabButtonSize }) =>
    TabButtonSize === 'SMALL'
      ? theme.toolkit.typography.fontSizes.M14
      : theme.toolkit.typography.fontSizes.M16};

  font-weight: ${({ theme }) => theme.toolkit.typography.fontWeights.SEMIBOLD};

  ${({ layout, isActive, theme }) =>
    isActive &&
    layout === TabStyles.ETabLayout.FILL &&
    `
    &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: ${theme.toolkit.brand.primary.BASE};`}

  border-radius: ${({ theme }) => theme.toolkit.spacing.S4};
  outline-offset: ${({ theme }) => `-${theme.toolkit.spacing.S4}`};

  &:disabled {
    cursor: unset;
    color: ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
    text-decoration: none;
    background-color: transparent;
  }
`;
