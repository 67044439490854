import styled from 'styled-components';

export type ThumbnailStyledProps = {
  overlay?: boolean;
  size?: 'xsmall' | 'small' | 'default';
  mobileSize?: 'xsmall' | 'small' | 'default';
};

const sizeMap = {
  xsmall: {
    width: '48px',
    height: '48px',
  },
  small: {
    width: '56px',
    height: '40px',
  },
  default: {
    width: '100%',
    height: '6.75rem',
  },
};

export const Wrapper = styled.div<ThumbnailStyledProps>`
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  overflow: hidden;
  position: relative;
  width: ${({ size }) => sizeMap[size || 'default'].width};
  height: ${({ size }) => sizeMap[size || 'default'].height};
  background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTEST};
  &.active {
    box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.24);
  }
  &.selected {
    outline: 4px solid ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
  }
  img {
    height: 100%;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
    object-fit: cover;
  }

  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.smallOnly}) {
    width: ${({ mobileSize }) => sizeMap[mobileSize || 'default'].width};
    height: ${({ mobileSize }) => sizeMap[mobileSize || 'default'].height};
  }
`;

export const Overlay = styled.div`
  background: rgba(51, 51, 5, 0.48);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    color: white;
  }
`;

export const Error = styled.div`
  background: rgba(255, 255, 255, 0.8);
  color: ${({ theme }) => theme.toolkit.brand.error.DARK};
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  padding: 1.875rem;
  svg {
    color: ${({ theme }) => theme.toolkit.brand.error.DARK};
  }
  p {
    margin-top: ${({ theme }) => theme.toolkit.spacing.S4};
  }
`;

export const Loading = styled.div`
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
  p {
    margin-top: ${({ theme }) => theme.toolkit.spacing.S4};
  }
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.toolkit.spacing.S4};
  left: ${({ theme }) => theme.toolkit.spacing.S4};
`;

export const BottomIconWrapper = styled.div`
  position: absolute;
  bottom: -8px;
  right: -8px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.toolkit.spacing.S4};
  right: ${({ theme }) => theme.toolkit.spacing.S4};
  display: flex;
  button {
    margin-left: ${({ theme }) => theme.toolkit.spacing.S4};
  }
`;
