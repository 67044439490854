import { useState } from "react";

export const useBurger = (callback:() => void, initialState?:boolean) => {
  const [open, setOpen] = useState(initialState);
  const burgerToggle = () => {
    callback();
    setOpen(!open);
  }
  return{
    setOpen,
    open,
    burgerToggle
  }
}
