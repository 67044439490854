import styled from 'styled-components';

export const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.toolkit.spacing.L48};
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.smallOnly}) {
    display: block;
  }
`;

export const ContentContainer = styled.div`
  max-width: 552px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (${({ theme }) =>
      theme.toolkit.mediaQuery.smallOnly}) {
    margin-top: ${({ theme }) => theme.toolkit.spacing.M24};
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 168px;
`;
