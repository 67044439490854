import styled from 'styled-components';

export const Wrapper = styled.div`
  label {
    display: block;
    margin-bottom: ${({ theme }) => theme.toolkit.spacing.S8};
  }
`;

export const ThumbWrapper = styled.div`
  width: 10.5rem;
  margin-top: ${({ theme }) => theme.toolkit.spacing.M16};
`;
