import * as Styled from './CardContainer.styled';
import { CardContainerProps } from './CardContainer.types';

export const CardContainer = ({
  border,
  borderMobile,
  children,
  backgroundColor,
  cornerRadius,
  cornerRadiusMobile,
  hoverBackgroundColor,
  mobileFullWidth,
  onClick,
  padding,
}: CardContainerProps) => {
  return (
    <Styled.CardContainer
      border={border}
      borderMobile={borderMobile}
      backgroundColor={backgroundColor}
      cornerRadius={cornerRadius}
      cornerRadiusMobile={cornerRadiusMobile}
      data-testid="card-container"
      hoverBackgroundColor={hoverBackgroundColor}
      mobileFullWidth={mobileFullWidth}
      onClick={onClick}
      padding={padding}
    >
      {children}
    </Styled.CardContainer>
  );
};

export default CardContainer;
