import { StyledError } from './Error.styled';
import { TError } from './Error.types';

export function Error({ children, id }: TError) {
  return (
    <StyledError id={id} role="alert">
      {children}
    </StyledError>
  );
}

export default Error;
