// third-party library imports
import styled, { css } from 'styled-components';

// relative imports
import * as types from './Modal.types';

/**
 * Generates CSS styles for content based on the given size.
 *
 * The function accepts a size parameter and returns tailored CSS
 * styles for that size. It supports three sizes: LARGE, MEDIUM, and SMALL.
 * - LARGE: Sets the height to 100%, the maximum width to 90rem, and adjusts max-height
 *   in response to medium screen media queries.
 * - MEDIUM: Sets the width to 100% and adjusts to 40rem for medium screen media queries.
 * - SMALL: Sets a fixed width of 26rem.
 * If no size matches, it returns an empty string.
 *
 * @param {string} size - The size descriptor ('LARGE', 'MEDIUM', 'SMALL').
 * @returns {string} The CSS rules as a string, based on the input size.
 */
const getContentStyle = (size: string) => {
  switch (size) {
    case 'LARGE':
      return css`
        height: 100%;
        max-height: 100%;
      `;
    case 'MEDIUM':
      return css`
        height: 100%;
        width: 100%;
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          height: auto;
          width: 40rem;
          max-height: calc(100vh - 5rem);
        }
      `;
    case 'SMALL':
      return css`
        width: 26rem;
        max-height: calc(100vh - 5rem);
      `;
    default:
      return '';
  }
};

const getModalHeaderPadding = (size: string) => {
  switch (size) {
    case 'SMALL':
      return css`
        padding-top: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) => theme.toolkit.spacing.S8};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.small}) {
          padding-top: ${({ theme }) => theme.toolkit.spacing.M32};
          padding-right: ${({ theme }) => theme.toolkit.spacing.M32};
          padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-left: ${({ theme }) => theme.toolkit.spacing.M32};
        }
      `;
    case 'MEDIUM':
      return css`
        padding-top: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) => theme.toolkit.spacing.S8};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          padding-top: ${({ theme }) => theme.toolkit.spacing.L40};
          padding-right: ${({ theme }) => theme.toolkit.spacing.L40};
          padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-left: ${({ theme }) => theme.toolkit.spacing.L40};
        }
      `;
    case 'LARGE':
      return css`
        padding-top: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) => theme.toolkit.spacing.S8};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          padding-top: 0;
          padding-right: ${({ theme }) => theme.toolkit.spacing.L40};
          padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-left: ${({ theme }) => theme.toolkit.spacing.L40};
        }
      `;
    default:
      return '';
  }
};

const getModalFooterPadding = (size: string) => {
  switch (size) {
    case 'SMALL':
      return css`
        padding-top: ${({ theme }) => theme.toolkit.spacing.M16};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.small}) {
          padding-top: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-right: ${({ theme }) => theme.toolkit.spacing.M32};
          padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-left: ${({ theme }) => theme.toolkit.spacing.M32};
        }
      `;
    case 'MEDIUM':
      return css`
        padding-top: ${({ theme }) => theme.toolkit.spacing.M16};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          padding-top: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-right: ${({ theme }) => theme.toolkit.spacing.L40};
          padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-left: ${({ theme }) => theme.toolkit.spacing.L40};
        }
      `;
    case 'LARGE':
      return css`
        padding-top: ${({ theme }) => theme.toolkit.spacing.M16};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          padding-top: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-right: ${({ theme }) => theme.toolkit.spacing.L40};
          padding-bottom: ${({ theme }) => theme.toolkit.spacing.M16};
          padding-left: ${({ theme }) => theme.toolkit.spacing.L40};
        }
      `;
    default:
      return '';
  }
};

const getModalBodyPadding = (
  hasHeader: boolean,
  hasFooter: boolean,
  size: string,
) => {
  switch (size) {
    case 'SMALL':
      return css`
        padding-top: ${({ theme }) =>
          hasHeader ? 0 : theme.toolkit.spacing.M24};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) =>
          hasFooter ? 0 : theme.toolkit.spacing.M24};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.small}) {
          padding-top: ${({ theme }) =>
            hasHeader ? 0 : theme.toolkit.spacing.M32};
          padding-right: ${({ theme }) => theme.toolkit.spacing.M32};
          padding-bottom: ${({ theme }) =>
            hasFooter ? 0 : theme.toolkit.spacing.M32};
          padding-left: ${({ theme }) => theme.toolkit.spacing.M32};
        }
      `;
    case 'MEDIUM':
      return css`
        padding-top: ${({ theme }) =>
          hasHeader ? 0 : theme.toolkit.spacing.M24};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) =>
          hasFooter ? 0 : theme.toolkit.spacing.M24};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          padding-top: ${({ theme }) =>
            hasHeader ? 0 : theme.toolkit.spacing.L40};
          padding-right: ${({ theme }) => theme.toolkit.spacing.L40};
          padding-bottom: ${({ theme }) =>
            hasFooter ? 0 : theme.toolkit.spacing.L40};
          padding-left: ${({ theme }) => theme.toolkit.spacing.L40};
        }
      `;
    case 'LARGE':
      return css`
        padding-top: ${({ theme }) =>
          hasHeader ? 0 : theme.toolkit.spacing.M24};
        padding-right: ${({ theme }) => theme.toolkit.spacing.M24};
        padding-bottom: ${({ theme }) =>
          hasFooter ? 0 : theme.toolkit.spacing.M24};
        padding-left: ${({ theme }) => theme.toolkit.spacing.M24};
        @media only screen and (${({ theme }) =>
            theme.toolkit.mediaQuery.medium}) {
          padding-top: 0;
          padding-right: ${({ theme }) => theme.toolkit.spacing.L40};
          padding-bottom: 0;
          padding-left: ${({ theme }) => theme.toolkit.spacing.L40};
        }
      `;
    default:
      return '';
  }
};

export const ModalContent = styled.div<types.TModalStyle>`
  align-items: center;
  background-color: #fff;
  border-radius: ${({ theme }) => theme.border.radius.STANDARD};
  ${({ size }) =>
    size !== types.Size.LARGE &&
    css`
      box-shadow: 0 0.125rem 0.5rem 0 hsl(0, 0%, 20%, 0.24);
    `};
  ${({ size }) =>
    size === types.Size.MEDIUM &&
    css`
      border-radius: 0;
      @media only screen and (${({ theme }) =>
          theme.toolkit.mediaQuery.medium}) {
        border-radius: ${({ theme }) => theme.border.radius.STANDARD};
      }
    `};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  ${({ size }) =>
    // get styles for content based on modal size
    getContentStyle(size)};
`;

export const ModalHeaderContainer = styled.header<types.TModalHeaderStyleProps>`
  ${({ size }) =>
    // get styles for content based on modal size
    getModalHeaderPadding(size)};
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ModalFooterContainer = styled.footer<types.TModalStyle>`
  ${({ size }) =>
    // get styles for content based on modal size
    getModalFooterPadding(size)};
  position: sticky;
  width: 100%;
`;

export const ModalBodyContainer = styled.div<types.TModalBodyStyleProps>`
  background-color: #fff;
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  height: 100%;
  max-height: 100%;
  ${({ overFlowOff }) =>
    !overFlowOff &&
    css`
      overflow-y: auto;
      overflow-x: hidden;
    `}
  ${({ hasHeader, hasFooter, size }) =>
    getModalBodyPadding(hasHeader, hasFooter, size)};
  position: relative;
  width: 100%;
  scrollbar-width: thin;
`;

export const ModalWrapper = styled.div<types.TModalStyle>`
  align-items: center;
  display: flex;
  height: 100vh;
  inset: 0;
  justify-content: center;
  left: 0;
  min-height: -webkit-fill-available;
  overflow-y: auto;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  ::-webkit-scrollbar {
    position: absolute;
    left: 0;
  }
  ${({ overlay, size }) =>
    size !== types.Size.LARGE &&
    overlay &&
    /* if it's size large remove box shadow and bg color */
    css`
      box-shadow: 0 0.125rem 0.5rem 0 hsl(0, 0%, 20%, 0.24);
      background-color: rgba(51, 51, 51, 0.64);
    `};

  ${({ size }) =>
    size === types.Size.SMALL &&
    /* if it's size is small add 8px padding left and right */
    css`
      padding: 0 ${({ theme }) => theme.toolkit.spacing.S8};
    `};

  ${({ size }) =>
    size === types.Size.LARGE &&
    /* if it's size is large fill entire screen */
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      background: #fff;
      @media only screen and (${({ theme }) =>
          theme.toolkit.mediaQuery.medium}) {
        padding: ${({ theme }) => theme.toolkit.spacing.L40} 0;
      }
    `};
  ${({ size, isLargeModalWithFooter }) =>
    size === types.Size.LARGE &&
    isLargeModalWithFooter &&
    /* if it's size is large and has a footer */
    css`
      @media only screen and (${({ theme }) =>
          theme.toolkit.mediaQuery.medium}) {
        padding-bottom: 0;
      }
    `};
`;

export const IconContainer = styled.div<types.TModalStyle>`
  margin-left: auto;
  padding-left: ${({ theme }) => theme.toolkit.spacing.M16};
  svg {
    color: ${({ theme }) => theme.toolkit.brand.grey?.DARK};
  }
`;
