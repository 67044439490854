import { InputHTMLAttributes } from 'react';

export enum EMultiSelectLayout {
  STACKED = 'STACKED',
  GRID = 'GRID',
}

export type TMultiSelectTag = {
  value: string;
  inputLabel: string;
  hasError: boolean;
  disabled: boolean;
  layout?: EMultiSelectLayout;
} & InputHTMLAttributes<HTMLInputElement>;


export type TMultiSelectTagGroup = {
  data: TMultiSelectData[];
  layout: EMultiSelectLayout;
} & InputHTMLAttributes<HTMLInputElement>;


export type TMultiSelectData = {
  value: string;
  inputLabel: string;
  hasError: boolean;
  disabled: boolean;
};