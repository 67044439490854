import { GalleryAspectRatio } from '../../molecules';

export type ImageGridImagesProps = React.ImgHTMLAttributes<HTMLImageElement>;
export enum ImageGridAspectRatio {
  RATIO_2_1 = '2/1',
  RATIO_3_2 = '3/2',
  RATIO_4_3 = '4/3',
}
export type ImageGridProps = {
  firstVisibleThumbnailIndex?: number;
  aspectRatioHorizontal?: ImageGridAspectRatio;
  aspectRatioVertical?: GalleryAspectRatio;
  imageGridImages: ImageGridImagesProps[] | [];
  skipImageIndex?: number;
  visibleGridImagesCount: VisibleThumbnailsCount;
  imageLoading?: 'eager' | 'lazy';
  dataTracking?: string;
  forceMobileView?: boolean;
};

export type VisibleThumbnailsCount = 0 | 2 | 3;
