import {
  ImageGridAspectRatio,
  VisibleThumbnailsCount,
} from '../../../atoms/imageGrid/ImageGrid.types';
import { GalleryAspectRatio, GalleryImageProps } from '../../../molecules';

export interface GalleryConfig {
  aspectRatio: GalleryAspectRatio;
  aspectRatioMobile?: GalleryAspectRatio;
  imageGridAspectRatioHorizontal?: ImageGridAspectRatio;
  visibleGridImagesCount: VisibleThumbnailsCount;
}

export enum GalleryType {
  LargeWithGrid = 'LargeWithGrid',
  LargeNoGrid = 'LargeNoGrid',
  MediumWithGrid = 'MediumWithGrid',
  MediumNoGrid = 'MediumNoGrid',
  SmallNoGrid = 'SmallNoGrid',
}

export const galleryTypeConfig: Record<GalleryType, GalleryConfig> = {
  [GalleryType.LargeWithGrid]: {
    aspectRatio: GalleryAspectRatio.RATIO_3_2,
    aspectRatioMobile: GalleryAspectRatio.RATIO_3_2,
    imageGridAspectRatioHorizontal: ImageGridAspectRatio.RATIO_4_3,
    visibleGridImagesCount: 3,
  },
  [GalleryType.LargeNoGrid]: {
    aspectRatio: GalleryAspectRatio.RATIO_16_9,
    aspectRatioMobile: GalleryAspectRatio.RATIO_3_2,
    visibleGridImagesCount: 0,
  },
  [GalleryType.MediumWithGrid]: {
    aspectRatio: GalleryAspectRatio.RATIO_3_2,
    aspectRatioMobile: GalleryAspectRatio.RATIO_16_9,
    imageGridAspectRatioHorizontal: ImageGridAspectRatio.RATIO_2_1,
    visibleGridImagesCount: 2,
  },
  [GalleryType.MediumNoGrid]: {
    aspectRatio: GalleryAspectRatio.RATIO_3_2,
    aspectRatioMobile: GalleryAspectRatio.RATIO_16_9,
    visibleGridImagesCount: 0,
  },
  [GalleryType.SmallNoGrid]: {
    aspectRatio: GalleryAspectRatio.RATIO_17_10,
    aspectRatioMobile: GalleryAspectRatio.RATIO_2_1,
    visibleGridImagesCount: 0,
  },
};

export const galleryImagesCollection: GalleryImageProps[] = [
  {
    alt: 'test',
    src: 'https://media.daft.build/eyJidWNrZXQiOiJzdGFnaW5nLW1lZGlhbWFzdGVyLXMzZXUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwid2lkdGgiOjEyMDAsImhlaWdodCI6MTIwMH19LCJvdXRwdXRGb3JtYXQiOiJqcGVnIiwia2V5IjoiMS85LzE5YzY3NzJkN2VhMGQ4ZDgxYzk1YTM2OGZmMDU4ZDQyLmpwZyJ9?signature=552682ff2e864da22dcdefc6c435f59cce84bc8ced4e97f0c40339c041f15804',
  },
  {
    alt: 'test',
    src: 'https://media.daft.build/eyJidWNrZXQiOiJzdGFnaW5nLW1lZGlhbWFzdGVyLXMzZXUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwid2lkdGgiOjEyMDAsImhlaWdodCI6MTIwMH19LCJvdXRwdXRGb3JtYXQiOiJqcGVnIiwia2V5IjoiOS83Lzk3ZWE2ZTQ5ZDMzM2E5NDAxNDhkYTIyMTJmNWU1ODVkLmpwZyJ9?signature=e976cc814f924d3a775c9b33e463aa76dc8f41e50f6385565b88c8bdd1204dec',
  },
  {
    alt: 'test',
    src: 'https://media.daft.build/eyJidWNrZXQiOiJzdGFnaW5nLW1lZGlhbWFzdGVyLXMzZXUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwid2lkdGgiOjEyMDAsImhlaWdodCI6MTIwMH19LCJvdXRwdXRGb3JtYXQiOiJqcGVnIiwia2V5IjoiOS82Lzk2NjE3ZWQyNDRjNmI0ZjcwYjI3MDVmM2Q5OTZkNjEwLmpwZyJ9?signature=1747c10e12d228e8f246dafaf17dc5cecce3a85f0427ed0b8b6117fa83633936',
  },
  {
    alt: 'test',
    src: 'https://media.daft.build/eyJidWNrZXQiOiJzdGFnaW5nLW1lZGlhbWFzdGVyLXMzZXUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiaW5zaWRlIiwid2lkdGgiOjEyMDAsImhlaWdodCI6MTIwMH19LCJvdXRwdXRGb3JtYXQiOiJqcGVnIiwia2V5IjoiYi8yL2IyZDZjNzE5MDAyOGFjZDFkZjU3NTc1MzRlNDhkNWYyLmpwZyJ9?signature=85de2492ada3d87a434d167437e61c70ae72cc8246bac71d2e1bfc62fdb27ee8',
  },
  {
    alt: 'test',
    src: 'https://media.daft.build/eyJidWNrZXQiOiJzdGFnaW5nLW1lZGlhbWFzdGVyLXMzZXUiLCJlZGl0cyI6eyJyZXNpemUiOnsiZml0IjoiY292ZXIiLCJ3aWR0aCI6NzIwLCJoZWlnaHQiOjQ4MH19LCJvdXRwdXRGb3JtYXQiOiJqcGVnIiwia2V5IjoiYy80L2M0ZGJlM2VmYzdlZjU3MWY0YjQ4MThhN2E1MmFhZmRkLnBuZyJ9?signature=e6d76acf2d9f3704904aa1f701ed6c5277c13ee6b7a44464445fc83906b3697a',
  },
];

export const getGalleryConfig = (type: GalleryType): GalleryConfig => {
  return galleryTypeConfig[type];
};
