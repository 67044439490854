import { Icon, Text, TextFontWeight, TextSize } from '../../../atoms';
import { EmptyWrapper } from './Empty.styled';
import { TEmpty } from './Empty.types';

export const Empty = ({ handleClick, onFileDrop }: TEmpty) => {
  const handleDragOver = (event: React.MouseEvent) => {
    event.preventDefault();
  };
  return (
    <EmptyWrapper
      onClick={handleClick}
      onDrop={onFileDrop}
      onDragOver={handleDragOver}
      data-testid="empty-thumbnail"
    >
      <Icon icon="PLUSCIRCLE" size={16} />
      <Text size={TextSize.S14} fontWeight={TextFontWeight.SEMIBOLD} as="p">
        Add photos
      </Text>
    </EmptyWrapper>
  );
};

export default Empty;
