import styled, { css } from 'styled-components';
import * as Types from './LinkButtonGroup.types';

export const Wrapper = styled.div<Types.TLinkButtonGroupStyle>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  justify-content: ${({ layout }) =>
    layout === Types.Layout.SPACE_BETWEEN ? 'space-between' : 'start'};
  ${(props) =>
    props.position === 'right' &&
    css`
      flex-direction: row-reverse;
    `};
  gap: ${({ buttonSizes, theme }) =>
    buttonSizes === 'X_SMALL' || buttonSizes === 'SMALL'
      ? theme.toolkit.spacing.M16
      : theme.toolkit.spacing.M24};
  @media ${({ theme }) => theme.toolkit.mediaQuery.smallOnly} {
    gap: ${({ buttonSizes, theme }) =>
      buttonSizes === 'LARGE'
        ? theme.toolkit.spacing.M24
        : buttonSizes === 'SMALL' || buttonSizes === 'MEDIUM'
        ? theme.toolkit.spacing.M16
        : theme.toolkit.spacing.M16};
  }
`;
