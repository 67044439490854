import { ReactNode } from 'react';
import { CardContentTopSlot } from './CardContent.styled';

export function CardContentTopRightSlot({
  children,
}: {
  children?: ReactNode;
}) {
  return <CardContentTopSlot>{children}</CardContentTopSlot>;
}
