import styled from 'styled-components';
import { TInfoListStyle } from './InfoList.types';

export const InfoListStyle = styled.ul<TInfoListStyle>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.toolkit.spacing.S8};
  // remove extra padding
  width: ${({ listStyle }) => (listStyle === 'FIXED' ? 'fit-content' : '100%')};
`;
