import styled from 'styled-components';

export const Wrapper = styled.ul`
  @media ${({ theme }) => theme.toolkit.mediaQuery.smallOnly} {
    overflow-y: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      width: ${({ theme }) => theme.toolkit.spacing.S2};
    }
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.toolkit.brand.grey?.LIGHTER};
      border-radius: 0.625rem;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${({ theme }) => theme.toolkit.spacing.M16};
      background: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    }

    ::-webkit-scrollbar-thumb:hover {
      border-radius: ${({ theme }) => theme.toolkit.spacing.M16};
      background: ${({ theme }) => theme.toolkit.brand.grey?.DARKER};
    }
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    display: flex;
    align-items: center;
    margin-left: ${({ theme }) => theme.toolkit.spacing.M24};
  }
`;

export const MainNav = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.toolkit.mediaQuery.large} {
    flex-direction: row;
  }
`;

export const UtilityNav = styled.div`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    flex-direction: row;
  }
`;
