import * as Styled from './RadioButtonCard.styled';
import * as Atom from '../../atoms';
import { useTheme } from 'styled-components';

const RadioBulletListText = (
  key: string,
  value: {
    tooltip?: any;
    illustration?: string;
    subtitle?: string;
  } | null,
) => {
  const theme = useTheme();
  return (
    <div
      style={{
        textAlign: 'left',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Styled.BulletPoint />
        <Atom.Text
          as="p"
          size={Atom.TextSize.S14}
          fontWeight={Atom.TextFontWeight.REGULAR}
        >
          {key}
        </Atom.Text>
      </div>
      <div style={{ paddingLeft: '12px' }}>
        <Atom.Text
          as="p"
          size={Atom.TextSize.S12}
          fontWeight={Atom.TextFontWeight.REGULAR}
          textColor={theme.toolkit.brand.grey?.DARK}
        >
          {value && value['subtitle']}
        </Atom.Text>
      </div>
    </div>
  );
};

export default RadioBulletListText;
