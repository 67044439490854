import * as Styled from './RadioButtonCard.styled';
import * as Molecule from '..';
import * as Atom from '../../atoms';

const RadioBulletListTooltip = (
  key: string,
  value: {
    tooltip?: any;
    illustration?: string;
  } | null,
) => (
  <>
    <Styled.BulletPoint />
    <Molecule.Tooltip
      content={value?.['tooltip'] as JSX.Element}
      delay={0}
      image={
        <Atom.Illustration
          height={key === 'e-Target' ? 64 : 112}
          illustration={value?.['illustration'] as Atom.IllustrationName}
        />
      }
      toolTipWidth={320}
      closeIcon={false}
    >
      <Styled.DashedSpan>{key}</Styled.DashedSpan>
    </Molecule.Tooltip>
  </>
);

export default RadioBulletListTooltip;
