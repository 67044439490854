import { Illustration } from '../../atoms/illustrations/Illustration';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 45px 20px;
  text-align: center;
  border: 1px dashed ${({ theme }) => theme.toolkit.brand.grey?.LIGHT};
  border-radius: ${({ theme }) => theme.toolkit.borderRadius.M};
  background: #ffffff;
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.toolkit.breakpoints.md}px) {
    padding: 56px 60px;
  }
`;

export const Container = styled.div`
  max-width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    margin: 0 auto;
  }
`;
export const BaseIcon = styled(Illustration)`
  path {
    stroke-width: 1;
  }
`;

export const DummyLink = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.toolkit.brand.primary.BASE};
`;
